import axios from 'axios'
import URL from '../config.json'

const restURL = URL.homeURL + URL.restURL
const API_URL = restURL + `${URL.productsURL}`

// get products
const getProducts = async () => {
	const response = await axios.get(API_URL)
	return response.data
}

// get products
const saveProduct = async (product) => {
	const response = await axios.post(API_URL, product)
	console.log(response.data)
	return response.data
}

// get products
const deleteProduct = async (PInfo) => {
	console.log(PInfo)
	const response = await axios.delete(API_URL, { data: PInfo })
	console.log(response.data)
	return response.data
}

const productsService = {
	getProducts,
	saveProduct,
	deleteProduct
}

export default productsService

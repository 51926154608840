import axios from 'axios'
import URL from '../config.json'

const restURL = URL.homeURL + URL.restURL
const API_URL = restURL + `${URL.customersURL}`

const getCustomers = async () => {
	const response = await axios.get(API_URL)
	return response.data
}

const saveCustomer = async (object) => {
	const response = await axios.post(API_URL, object)
	return response.data
}

const deleteCustomer = async (customer_id) => {
	const response = await axios.delete(API_URL, {
		data: { customer_id: customer_id }
	})
	return response.data
}

const customersService = { getCustomers, saveCustomer, deleteCustomer }

export default customersService

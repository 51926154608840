import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {
	notifications: {
		sendWithForm: {
			displayAgain: true,
			displayed: false
		}
	}
}

export const internalNotificationsSlice = createSlice({
	name: 'internalNotifications',
	initialState,
	reducers: {
		setDisplyed: (state, action) => {
			console.log(action.payload)
		},
		setDisplayAgain: (state, action) => {
			console.log(action.payload)
		}
	}
})

export const { setDisplyed, setDisplayAgain } = internalNotificationsSlice.actions

export default internalNotificationsSlice.reducer

import axios from 'axios'
import URL from '../config.json'

const restURL = URL.homeURL + URL.restURL
const API_URL = restURL + `${URL.settingsURL}`

// get settings
const getSetting = async () => {
	const response = await axios.get(API_URL)
	return response.data
}

// get settings
const saveSetting = async (setting) => {
	const response = await axios.post(API_URL, setting)
	console.log(response.data)
	return response.data
}

const settingsService = {
	getSetting,
	saveSetting
}

export default settingsService

import React, { useEffect, useState } from 'react';
import '../../styles/header.scss';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as Logo } from '../../images/logo1.svg';
import {
	LockFilled,
	UnlockFilled,
	SendOutlined,
	DatabaseOutlined,
	LogoutOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Space, Menu } from 'antd';
import Address from '../../app/services/config.json';
import { useSelector, useDispatch } from 'react-redux';
import storage from 'redux-persist/lib/storage';
import { timeDifference } from '../Globals/Globals';
import { LogoutUser } from '../../app/services/auth/Slice';
import { allCompanies, getCompanies } from '../../app/services/companies/Slice';
import { allCustomers, getCustomers } from '../../app/services/customers/Slice';
import { allProducts, getProducts } from '../../app/services/products/Slice';
import { allSettings, getSetting } from '../../app/services/settings/Slice';
import { allBills } from '../../app/services/bills/Slice';
import { getBill, getBills } from '../../app/services/bills/Slice';
import {
	getAllData,
	setLoadingTrue,
	setLoadingFalse,
} from '../../app/services/general/Slice';
import axios from 'axios';
import MainURL from '../../app/services/config.json';
import { useRefCallback } from '@ant-design/pro-components';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

function useQuery() {
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Header() {
	let query = useQuery();
	const singleId = query.get('singleId');
	const sandbox = query.get('sandbox');
	console.log(sandbox);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { user, token, validTime } = useSelector((state) => state.user);
	const { companies } = useSelector((state) => state.companies);
	const { customers } = useSelector((state) => state.customers);
	const { products } = useSelector((state) => state.products);
	const { settings } = useSelector((state) => state.settings);
	const [allData, setAllData] = useState(null);

	const get_all_things = async () => {
		if (
			token &&
			Object.keys(companies).length <= 0 &&
			Object.keys(customers).length <= 0 &&
			products.length <= 0 &&
			!settings
		) {
			dispatch(setLoadingTrue());
			const result = await dispatch(getAllData());
			const mainData = result.payload ? result.payload.response : null;
			if (mainData) {
				await dispatch(allCompanies(mainData.companies));
				await dispatch(allCustomers(mainData.customers));
				await dispatch(allProducts(mainData.products));
				await dispatch(allSettings(mainData.settings));
				await dispatch(allBills(mainData.totalBills));
				if (window.location.href.indexOf('single') > -1) {
					const single = await dispatch(
						getBill({ singleId: singleId, sandbox: sandbox })
					);
					console.log(single);
				}

				setAllData(mainData);
			}
		} else if (
			token &&
			Object.keys(companies).length <= 0 &&
			Object.keys(customers).length > 0 &&
			products.length > 0 &&
			settings
		) {
			dispatch(setLoadingTrue());
			await dispatch(getCompanies());
		} else if (
			token &&
			Object.keys(companies).length > 0 &&
			Object.keys(customers).length <= 0 &&
			products.length > 0 &&
			settings
		) {
			dispatch(setLoadingTrue());
			await dispatch(getCustomers());
		} else if (
			token &&
			Object.keys(companies).length > 0 &&
			Object.keys(customers).length > 0 &&
			products.length <= 0 &&
			settings
		) {
			dispatch(setLoadingTrue());
			await dispatch(getProducts());
		} else if (
			token &&
			Object.keys(companies).length > 0 &&
			Object.keys(customers).length > 0 &&
			products.length > 0 &&
			!settings
		) {
			dispatch(setLoadingTrue());
			await dispatch(getSetting());
		}
		if (token && window.location.href.indexOf('single') > -1) {
			dispatch(setLoadingTrue());
			const single = await dispatch(
				getBill({ singleId: singleId, sandbox: sandbox })
			);
		}
		dispatch(setLoadingFalse());
	};

	const handleMenuClick = (e) => {
		console.log('click', e.key);
		if (e.key === 'logout') {
			dispatch(LogoutUser);
			window.location.reload(true);
			storage.removeItem('persist:root');
		} else {
			navigate(e.key);
		}
	};
	const items = [
		{
			label: 'ارسال از طریق فرم',
			key: 'send',
			icon: <SendOutlined rotate={180} />,
			disabled: !user || !token ? true : false,
		},
		{
			label: 'شناسه کالا',
			key: '/products',
			icon: <DatabaseOutlined />,
			disabled: !user || !token ? true : false,
		},
		{
			label: 'شرکت های تعریف شده',
			key: '/companies',
			icon: <DatabaseOutlined />,
			disabled: !user || !token ? true : false,
		},
		{
			label: 'تعریف کاربر',
			key: '/users',
			icon: <DatabaseOutlined />,
			disabled: !user || !token ? true : false,
		},
		{
			label: 'خروج',
			key: 'logout',
			icon: <LogoutOutlined rotate={180} />,
			danger: true,
			disabled: !user || !token ? true : false,
		},
	];
	const menuProps = {
		items,
		onClick: handleMenuClick,
	};

	let url = new URL(window.location.href);
	let after_slash = url.hash;
	const active_url = () => {
		if (
			window.location.href.indexOf('single') > -1 &&
			window.location.href.indexOf('sandbox') > -1
		) {
			navigate('/single?singleId=' + singleId + '&&sandbox=1');
			return;
		} else if (
			window.location.href.indexOf('single') > -1 &&
			window.location.href.indexOf('sandbox') < 0
		) {
			navigate('/single?singleId=' + singleId);
			return;
		}
		switch (after_slash) {
			case '#/sendWithForm':
				return true;
			case '#/sent':
				return true;
			case '#/companies':
				token &&
				user.main_user === '1' &&
				user.roles[0] === 'ma_extra_user'
					? navigate('/login')
					: navigate('/companies');
				break;
			case '#/products':
				return '/products';
			case '#/users':
				token &&
				user.main_user === '1' &&
				user.roles[0] === 'ma_extra_user'
					? navigate('/login')
					: navigate('/users');
				break;
			case '#/licenses':
				token &&
				user.main_user === '1' &&
				user.roles[0] === 'ma_extra_user'
					? navigate('/login')
					: navigate('/licenses');
				break;
			case '#/payments':
				token &&
				user.main_user === '1' &&
				user.roles[0] === 'ma_extra_user'
					? navigate('/login')
					: navigate('/payments');
				break;
			case '#/customers':
				return true;
				break;
			case '#/settings':
				token &&
				user.main_user === '1' &&
				user.roles[0] === 'ma_extra_user'
					? navigate('/login')
					: navigate('/settings');
				break;
			case '#/sentSandbox':
				return true;
			default:
				token &&
				user.main_user === '1' &&
				user.roles[0] === 'ma_extra_user'
					? navigate('/login')
					: navigate('/');
				break;
		}
	};
	useEffect(() => {
		get_all_things();
		active_url();
		if (validTime && timeDifference(Date.now(), validTime, 'day') > 7) {
			dispatch(LogoutUser);
			window.location.reload(true);
			storage.removeItem('persist:root');
		}
	}, [token, url.hash]);
	return (
		<div className="headerWrap">
			<div className="topHeader">
				<div className="mainContainer">
					<ul>
						<li>
							<Link to="#">آموزش</Link>
						</li>
						<li>
							<Link to="#">پشتیبانی</Link>
						</li>
					</ul>
				</div>
			</div>
			<div className="middleHeader">
				<div className="mainContainer">
					<div className="middleHeaderContent">
						<div className="middleHeaderRight">
							<div className="headerLogo">
								<Logo />
							</div>
							<div className="headerSlogan">
								<h1>مؤدیان ابزار</h1>
								<h3>نرم افزار واسط مؤدیان مالیاتی</h3>
							</div>
						</div>
						<div className="middleHeaderLeft">
							{!user || !token ? (
								<Button className="headerDropdownButton">
									<Space align="center">
										<LockFilled style={{ fontSize: 24 }} />
										<div className="headerDropdownText">
											حساب کاربری
										</div>
									</Space>
								</Button>
							) : (
								<Dropdown
									className="headerDropdownButton"
									placement="bottomRight"
									overlayClassName="headerDropdown"
									menu={menuProps}
								>
									<Button>
										<Space align="center">
											<UnlockFilled
												style={{
													fontSize: 24,
													color: '#00a693',
												}}
											/>
											<div className="headerDropdownText">
												{user.display_name}
											</div>
										</Space>
									</Button>
								</Dropdown>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className="bottomHeader">
				<div className="mainContainer">
					<Menu
						className="bottomHeaderMenu"
						mode="horizontal"
						items={[
							{
								key: 'home',
								label: (
									<a
										href={Address.homeURL}
										target="_blank"
										rel="noopener noreferrer"
									>
										صفحه اصلی
									</a>
								),
							},
							{
								key: 'price',
								label: (
									<a
										href={Address.priceURL}
										target="_blank"
										rel="noopener noreferrer"
									>
										تعرفه ها
									</a>
								),
							},
							{
								key: 'blog',
								label: (
									<a
										href={Address.blogURL}
										target="_blank"
										rel="noopener noreferrer"
									>
										وبلاگ
									</a>
								),
							},
							{
								key: 'about',
								label: (
									<a
										href={Address.aboutURL}
										target="_blank"
										rel="noopener noreferrer"
									>
										درباره ما
									</a>
								),
							},
							{
								key: 'contact',
								label: (
									<a
										href={Address.contactURL}
										target="_blank"
										rel="noopener noreferrer"
									>
										تماس باما
									</a>
								),
							},
						]}
					/>
				</div>
			</div>
		</div>
	);
}

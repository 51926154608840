import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Menu, Dropdown } from 'antd';
import { ReactComponent as Payments } from '../../images/money-check.svg';
import { ReactComponent as Licenses } from '../../images/shopping-cart.svg';
import { ReactComponent as Invoices } from '../../images/invoice-icon.svg';
import { ReactComponent as CompanyIcon } from '../../images/company-icon.svg';
import { ReactComponent as CustomerIcon } from '../../images/customer-icon.svg';
import { ReactComponent as DashboardIcon } from '../../images/dashboard-icon.svg';
import { ReactComponent as InvoiceSentIcon } from '../../images/invoice-sent-icon.svg';
import { ReactComponent as ProdcutsIcon } from '../../images/product-icon.svg';
import { ReactComponent as ExtrauserIcon } from '../../images/extrauser-icon.svg';
import { ReactComponent as Logout } from '../../images/logout.svg';
import { ReactComponent as Settings } from '../../images/settings.svg';
import { useSelector } from 'react-redux';
import storage from 'redux-persist/lib/storage';
import {
	SendOutlined,
	DatabaseOutlined,
	LogoutOutlined,
} from '@ant-design/icons';
import { isBrowser } from 'react-device-detect';
import { MenuOutlined } from '@ant-design/icons';

export default function RightMenu(props) {
	const { active } = props;
	const navigate = useNavigate();
	const { token, user } = useSelector((state) => state.user);
	const logout = () => {
		window.location.reload(true);
		storage.removeItem('persist:root');
	};

	const handleMenuClick = (e) => {
		console.log('click', e.key);
		navigate(e.key);
	};
	const items = [
		{
			label: 'ارسال از طریق فرم',
			key: 'send',
			icon: <SendOutlined rotate={180} />,
			disabled: !user || !token ? true : false,
		},
		{
			label: 'شناسه کالا',
			key: '/products',
			icon: <DatabaseOutlined />,
			disabled: !user || !token ? true : false,
		},
		{
			label: 'شرکت های تعریف شده',
			key: '/companies',
			icon: <DatabaseOutlined />,
			disabled: !user || !token ? true : false,
		},
		{
			label: 'تعریف کاربر',
			key: '/users',
			icon: <DatabaseOutlined />,
			disabled: !user || !token ? true : false,
		},
		{
			label: 'خروج',
			key: 'logout',
			icon: <LogoutOutlined rotate={180} />,
			danger: true,
			disabled: !user || !token ? true : false,
		},
	];
	const menuProps = {
		items,
		onClick: handleMenuClick,
		style: { direction: 'rtl' },
	};
	return isBrowser ? (
		<div className="dashboardRightMenuWrap">
			<Menu
				className="MADashboardRightMenu"
				onClick={({ key }) => {
					if (key === 'singout') {
						//Todo
					} else {
						navigate(key);
					}
				}}
				theme="dark"
				mode="inline"
				defaultSelectedKeys={[active]}
				items={[
					{
						key: '/',
						icon: <DashboardIcon />,
						label: 'تمام فعالیت ها',
						disabled:
							token &&
							user.main_user === '1' &&
							user.roles[0] === 'ma_extra_user'
								? true
								: false,
					},
					{
						key: '/sendWithForm',
						icon: <Invoices />,
						label: 'ارسال از طریق فرم',
					},
					{
						key: '/sent',
						icon: <InvoiceSentIcon />,
						label: 'صورتحساب ها',
					},
					{
						key: '/sentSandbox',
						icon: <InvoiceSentIcon />,
						label: 'سندباکس (محیط تست)',
					},
					{
						key: '/companies',
						icon: <CompanyIcon />,
						label: 'فروشندگان / شرکت ها',
						disabled:
							token &&
							user.main_user === '1' &&
							user.roles[0] === 'ma_extra_user'
								? true
								: false,
					},
					{
						key: '/customers',
						icon: <CustomerIcon />,
						label: 'خریداران / مشتری ها',
					},
					{
						key: '/products',
						icon: <ProdcutsIcon />,
						label: 'شناسه کالا',
					},
					{
						key: '/users',
						icon: <ExtrauserIcon />,
						label: 'همکاران',
						disabled:
							token &&
							user.main_user === '1' &&
							user.roles[0] === 'ma_extra_user'
								? true
								: false,
					},
					{
						key: '/licenses',
						icon: <Licenses />,
						label: 'لایسنس ها',
						disabled:
							token &&
							user.main_user === '1' &&
							user.roles[0] === 'ma_extra_user'
								? true
								: false,
					},
					{
						key: '/settings',
						icon: <Settings />,
						label: 'تنطیمات',
					},
					// {
					// 	key: '/payments',
					// 	icon: <Payments />,
					// 	label: 'سابقه پرداخت ها',
					// 	disabled:
					// 		token &&
					// 		user.main_user === '1' &&
					// 		user.roles[0] === 'ma_extra_user'
					// 			? true
					// 			: false
					// }
				]}
			/>
			<div className="rightMenuLogout">
				<Button icon={<Logout />} danger onClick={() => logout()}>
					خروج از حساب
				</Button>
			</div>
		</div>
	) : (
		<Dropdown placement="bottom" menu={menuProps}>
			<Button>
				<MenuOutlined />
				منو
			</Button>
		</Dropdown>
	);
}

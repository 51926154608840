import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Form, Input, Empty, notification, Switch, Row, Col, Tooltip } from 'antd'
import { updateSetting, getSetting, saveSetting } from '../../app/services/settings/Slice'
import Loading from '../../features/Loading/Loading'
import { ReactComponent as QuestionIcon } from '../../images/question-icon.svg'
import '../../styles/settings.scss'

const { TextArea } = Input

export default function Settings() {
	const dispatch = useDispatch()
	const [form] = Form.useForm()
	const sandboxValue = Form.useWatch('sandbox', form)
	const stepValue = Form.useWatch('step', form)
	const changePasswordValue = Form.useWatch('changePassword', form)
	const password = Form.useWatch('password', form)
	const rePassword = Form.useWatch('rePassword', form)
	const [api, contextHolder] = notification.useNotification({
		placement: 'bottomRight',
		rtl: true
	})
	const { settings, settingIsLoading } = useSelector((state) => state.settings)
	const { user } = useSelector((state) => state.user)

	console.log(settings)
	const onSaveSettings = () => {
		const obj = form.getFieldsValue()
		if ((password && password.length < 8) || (rePassword && rePassword.length < 8)) {
			api.error({
				message: ' ',
				description:
					'رمز عبور حداقل ۸ رقم باید باشد. بهتر است از رمز عبور قوی استفاده کنید.'
			})
			return
		}
		if (password !== rePassword) {
			api.error({
				message: ' ',
				description: 'رمز عبور با تکرار آن متفاوت است'
			})
			return
		}
		form.validateFields()
			.then(async (values) => {
				form.setFieldValue('mobile', user ? user.user_mobile : null)

				obj.privateCode = obj.privateCode.replace(/(\r\n|\n|\r)/gm, '')

				const addP = await dispatch(saveSetting(obj))
				if (addP.payload.status === 200) {
					dispatch(updateSetting(obj))
					api.success({
						message: ' ',
						description: addP.payload.response
					})
				} else {
					api.error({
						message: ' ',
						description: addP.payload.response
					})
				}
			})
			.catch((errorInfo) => {})
	}

	useEffect(() => {
		form.setFieldsValue(settings)
	}, [form, settings])
	return (
		<div className='settingssWrap'>
			{contextHolder}
			<div className='dashboardTitle'>
				<div>
					<h4>تنظیمات</h4>
					<p>
						این بخش فقط توسط خریدار لایسنس قابل تغییر خواهد بود و همکار اجازه
						مشاهده این صفحه را نخواهد داشت.
					</p>
				</div>
				<Button
					disabled={settingIsLoading ? true : false}
					loading={settingIsLoading ? true : false}
					className='MAAdd'
					// icon={<PlusIcon />}
					onClick={async () => {
						onSaveSettings()
					}}>
					ذخیره
				</Button>
			</div>
			{settingIsLoading ? (
				<Loading loading='true' />
			) : (
				<div
					className={
						settings
							? 'settingContent dashboardMainContent'
							: 'settingContent dashboardMainContent isEmpty'
					}>
					<Form
						name='basic'
						className='settingForm'
						form={form}
						labelCol={{
							span: 8
						}}
						wrapperCol={{
							span: 16
						}}
						style={{
							maxWidth: 'auto',
							width: '100%'
						}}
						initialValues={settings}
						autoComplete='off'>
						<Row gutter={[16, 16]}>
							<Col span={8}>
								<h4>
									ارسال به محیط تست (sandbox)
									<Form.Item valuePropName='checked' name='sandbox'>
										<Switch />
									</Form.Item>
								</h4>

								{sandboxValue ? (
									<Row gutter={[4, 2]} className='settingFormContent'>
										<Col span={24}>
											<Form.Item
												name='codeEghtesadi'
												rules={[
													{
														required: sandboxValue
															? true
															: false,
														message: 'فیلد اجباری'
													}
												]}>
												<Input placeholder='کد اقتصادی' />
											</Form.Item>
											<Form.Item
												name='shenaseYekta'
												rules={[
													{
														required: sandboxValue
															? true
															: false,
														message: 'فیلد اجباری'
													}
												]}>
												<Input placeholder='شناسه یکتا' />
											</Form.Item>
											<Form.Item
												name='privateCode'
												rules={[
													{
														required: sandboxValue
															? true
															: false,
														message: 'فیلد اجباری'
													}
												]}>
												<TextArea
													rows={4}
													placeholder='کد خصوصی'
												/>
											</Form.Item>
										</Col>
									</Row>
								) : null}
							</Col>
							<Col span={8}>
								<h4>
									تغییر گذرواژه
									<Form.Item
										valuePropName='checked'
										name='changePassword'>
										<Switch />
									</Form.Item>
								</h4>

								{changePasswordValue ? (
									<Row gutter={[4, 2]} className='settingFormContent'>
										<Col span={24}>
											<Form.Item
												name='password'
												rules={[
													{
														required: changePasswordValue
															? true
															: false,
														message: 'فیلد اجباری'
													}
												]}>
												<Input.Password placeholder='رمز عبور' />
											</Form.Item>
											<Form.Item
												name='rePassword'
												rules={[
													{
														required: changePasswordValue
															? true
															: false,
														message: 'فیلد اجباری'
													}
												]}>
												<Input.Password placeholder='تکرار رمز عبور' />
											</Form.Item>
										</Col>
									</Row>
								) : null}
							</Col>
							<Col span={8}>
								<h4>
									ارسال صوتحساب دو مرحله ای
									<Tooltip title='با فعال سازی این گزینه در زمان ارسال صورتحساب، کد تایید برای خریدار لایسنس ارسال خواهد شد و تا زمان ورود کد مورد نظر، امکان ارسال وجود نخواهد داشت '>
										<Button>
											<QuestionIcon />
										</Button>
									</Tooltip>
									<Form.Item valuePropName='checked' name='step'>
										<Switch />
									</Form.Item>
								</h4>

								{stepValue ? (
									<Row gutter={[4, 2]} className='settingFormContent'>
										<Col span={24}>
											<Form.Item name='mobile'>
												<Input
													disabled
													placeholder={
														user
															? user.user_mobile
															: 'شماره موبایل'
													}
												/>
											</Form.Item>
										</Col>
									</Row>
								) : null}
							</Col>
						</Row>

						{!sandboxValue && !stepValue && !changePasswordValue ? (
							<Empty description=''></Empty>
						) : null}
					</Form>
					{/* {settings.length > 0 ? (
						settings.map((setting, index) => (
							<div key={index} className='singleWidget'>
								<div className='singleWidgetRight'>
									<span>{setting.taxRate}%</span>
								</div>
								<div className='singleWidgetLeft'>
									<div className='singleWidgetTitle'>
										{setting.name}
									</div>
									<div className='singleWidgetSubtitle'>
										شناسه مالیاتی: {setting.id}
									</div>
								</div>
							</div>
						))
					) : (
						<Empty description={false} />
					)} */}
				</div>
			)}
		</div>
	)
}

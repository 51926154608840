import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../../features/Loading/Loading';
import {
	showDrawer,
	formData,
	addCompany,
	getCompanies,
	getLicenses,
	saveCompany,
	deleteCompany,
	removeCompany,
} from '../../app/services/companies/Slice';
import { Button, Drawer, Form, Input, Empty, Select, message } from 'antd';
import '../../styles/companies.scss';
import { ReactComponent as DeleteIcon } from '../../images/delete-icon.svg';
import { ReactComponent as EditIcon } from '../../images/edit-icon.svg';
import { ReactComponent as CloseIcon } from '../../images/close-icon.svg';
import { ReactComponent as CompanyIcon } from '../../images/company-icon.svg';
import { ReactComponent as PlusIcon } from '../../images/plus-icon.svg';
import ConfirmModal from '../../features/ConfirmModal/ConfirmModal';
import NoticeBox from '../Docs/NoticeBox';

export default function Companies() {
	const dispatch = useDispatch();
	const [messageApi, contextHolder] = message.useMessage();
	const { companies, companyIsLoading, isOpen } = useSelector(
		(state) => state.companies
	);
	const { user } = useSelector((state) => state.user);

	const get_all_things = async () => {
		if (Object.keys(companies).length <= 0) {
			console.log('first');
			const allCompanies = await dispatch(getCompanies());
			console.log(allCompanies);
		}
	};

	const removeHandle = async (company_id) => {
		const deletedCompany = await dispatch(deleteCompany(company_id));
		if (deletedCompany.payload.status === 200) {
			dispatch(removeCompany(company_id));
			const allCompanies = await dispatch(getCompanies());
			dispatch(addCompany(allCompanies.payload.response.companies));
			messageApi.success(deletedCompany.payload.response);
		} else {
			messageApi.error(deletedCompany.payload.response);
		}
	};

	useEffect(() => {
		// get_all_things()
	}, []);
	console.log(companies);
	return (
		<div className="companiesWrap">
			{contextHolder}
			<div className="dashboardTitle">
				<div>
					<h4>فروشندگان / شرکت ها</h4>
					<p>
						شرکت هایی که قصد ارسال فاکتور برای آنها را دارید در این
						بخش تعریف خواهند شد و سپس در بخش "ارسال از طریق فرم"
						قابل انتخاب خواهند بود. دقت کنید که بر اساس لایسنس
						خریداری شده میتوانید شرکت های خود را تعریف نمایید.
					</p>
				</div>
				<Button
					className="MAAdd"
					icon={<PlusIcon />}
					onClick={async () => {
						dispatch(
							showDrawer({
								open: !isOpen,
								edit: false,
								companyID: false,
							})
						);
						const allLicenses = await dispatch(
							getLicenses(user.user_email)
						);
						console.log(allLicenses);
					}}
				>
					افزودن شرکت
				</Button>
			</div>
			<NoticeBox
				title="فروشندگان / شرکت ها"
				descriptions="فروشندگان / شرکت ها چیست و چه اطلاعاتی در اختیار ما میگذارد"
				button="نمایش مقاله"
				video="https://moadianabzar.ir/wp-content/uploads/2024/01/فروشندگان-و-شرکت-ها.mp4"
				modalDescription={`<p> در بخش <strong> فروشندگان / شرکت ها </strong> ، در <strong> نرم افزار رابط مودیان </strong>ابزار شما میتوانید شرکت ها و یا فروشندگانی را که میخواهید برای آن ها فاکتور مالیاتی رد بکنید را ثبت بکنید تا در زمان ارسال فاکتور های خود از طریق نرم افزار رابط مودیان ابزار دیگر نیاز به وارد کردن مجدد این اطلاعات نباشد.
</p>
<h2> ثبت و یا اضافه کردن فروشندگان / شرکت ها</h2>
<p>در قدم اول برای اضافه کردن شرکت و یا مشتری جدید باید از طریق دکمه افزودن شرکت که در بالا سمت چپ این پنل وجود دارد اقدام کنید. تا بتوانید مشتری های خود را در نرم افزار واسط مودیان ابزار ثبت بکنید. بعد از کلیک کردن روی دکمه افزودن شرکت یک فرم با 5 فیلد به شما نمایش داده میشود</p>
<h2> فیلد های الزامی جهت اضاف کردن فروشنده و یا شرکت در نرم افزار واسط مودیان ابزار</h2>
<ul>
    <li>شماره اقتصادی یا شماره ملی</li>
    <li>کد یکتا <a href="#"> (نحوه گرفتن کد یکتا)</a></li>
    <li>نام شرکت</li>
    <li>انتخاب لایسنس خریدار شده</li>
    <li>کد شخصی و یا private code <a href="#"> (نحوه گرفتن کد شخصی)</a></li>
</ul>
<p> بعد از پر کردن فیلد های بالا با زدن دکمه ذخیره که در بالای فرم قرار دارد اطلاعات را ذخیره کنید تا اطلاعات مشتری ثبت شود تا در هنگام ارسال فاکتور با استفاده از ابزار ارسال از طریق فرم در قسمت "صورتحساب برای کدام فروشنده / شرکت است؟" بتوانید اطلاعات این مشتری را با انتخاب کردن نام آن به فاکتور اضافه کنید.</p>
<p> در قدم آخر شما در بخش فروشندگان / شرکت ها نرم افزار واسط مودیا ابزار میتوانید لیست شرکت ها و فروشندگان را که از قبل اضافه کردید را ببیند ، آن ها را حذف و یا ویرایش کنید.</p>
`}
			/>
			<br />
			{companyIsLoading ? (
				<Loading loading="true" />
			) : (
				<div
					className={
						typeof companies == 'object' &&
						Object.keys(companies).length > 0
							? 'dashboardMainContent companyContent'
							: 'dashboardMainContent companyContent isEmpty'
					}
				>
					{typeof companies == 'object' &&
					Object.keys(companies).length > 0 ? (
						Object.values(companies).map((company, index) => (
							<div
								key={index}
								className="singleWidget"
								id={company.company_id}
							>
								<div className="singleWidgetRight">
									<CompanyIcon />
								</div>
								<div className="singleWidgetLeft">
									<div className="singleWidgetTitle">
										{company.name}
									</div>
									<div className="singleWidgetSubtitle">
										{' کداقتصادی: '}
										{company.cod_eqtesadi}
									</div>
									<div className="singleWidgetSubtitle">
										{' کدیکتا: '}
										{company.cod_yekta}
									</div>
									<div className="singleWidgetSubtitle">
										{' لایسنس: '}
										{company.license}
									</div>

									<Button
										className="MARemove"
										onClick={() =>
											ConfirmModal({
												id: company.company_id,
												handle: removeHandle,
											})
										}
									>
										<DeleteIcon />
									</Button>
									<Button
										className="MAEdit"
										onClick={() => {
											dispatch(formData(company));
											dispatch(
												showDrawer({
													open: !isOpen,
													edit: true,
													companyID:
														company.company_id,
												})
											);
										}}
									>
										<EditIcon />
									</Button>
								</div>
							</div>
						))
					) : (
						<Empty description={false} />
					)}
				</div>
			)}
			<CompaniesDrawer messageApi={messageApi} />
		</div>
	);
}

export function CompaniesDrawer(props) {
	const { messageApi } = props;
	const dispatch = useDispatch();
	const { isOpen, isEditing, companyID, isLoading } = useSelector(
		(state) => state.companies
	);
	const [form] = Form.useForm();

	const onFormSubmit = async () => {
		const obj = form.getFieldsValue();
		form.validateFields()
			.then(async (values) => {
				// Set CompanyID for edit item
				if (companyID) {
					obj.company_id = companyID;
				}

				const newFormData = await dispatch(formData(obj));
				const savedCompany = await dispatch(saveCompany(obj));
				const allCompanies = await dispatch(getCompanies());

				console.log(savedCompany);
				if (savedCompany.payload.status === 200) {
					dispatch(
						addCompany(allCompanies.payload.response.companies)
					);
					dispatch(
						showDrawer({
							open: !isOpen,
							edit: false,
							companyID: false,
						})
					);
					dispatch(formData({}));

					setTimeout(() => {
						form.resetFields();
					}, 500);
					messageApi.success(savedCompany.payload.response);
				} else {
					messageApi.error(savedCompany.payload.response);
				}
			})
			.catch((error) => {
				console.log('Form has validation errors:', error);
			});
	};

	const handleFormChange = () => {
		dispatch(formData(form.getFieldsValue()));
	};

	return (
		<Drawer
			title=" "
			placement="left"
			maskClosable={false}
			closable={false}
			open={isOpen}
			key="left"
			extra={
				<React.Fragment>
					<Button
						className="MAButton MASecondry MAClose"
						type="primary"
						disabled={isLoading}
						onClick={() => {
							dispatch(
								showDrawer({
									open: !isOpen,
									edit: false,
									companyID: false,
								})
							);
							dispatch(formData({}));

							setTimeout(() => {
								form.resetFields();
							}, 500);
						}}
					>
						<CloseIcon />
					</Button>
					<Button
						className="MAButton"
						type="primary"
						htmlType="submit"
						onClick={onFormSubmit}
						disabled={isLoading}
					>
						{isEditing ? 'بروز رسانی' : 'ذخیره'}
					</Button>
				</React.Fragment>
			}
		>
			{isLoading ? (
				<Loading loading="true" />
			) : (
				<DrawerFields form={form} onChange={handleFormChange} />
			)}
		</Drawer>
	);
}

export function DrawerFields(props) {
	const { form, onChange } = props;
	const { currentFormData, isEditing, licenses } = useSelector(
		(state) => state.companies
	);
	const { TextArea } = Input;
	form.setFieldsValue(currentFormData);

	return (
		<Form
			name="dynamic_form_nest_item"
			form={form}
			layout="vertical"
			onChange={onChange}
		>
			<Form.Item
				hasFeedback
				name="cod_eqtesadi"
				rules={[
					{
						required: true,
						message: 'فیلد اجباری است',
					},
					{
						validator: async (_, cod_eqtesadi) => {
							if (
								!cod_eqtesadi ||
								10 > cod_eqtesadi.length ||
								cod_eqtesadi.length > 14
							) {
								return Promise.reject(
									new Error(
										'مقدار این فیلد باید 10 یا 14 رقم باشد'
									)
								);
							}
						},
						message: 'مقدار این فیلد باید 10 یا 14 رقم باشد',
					},
				]}
			>
				<Input
					size="large"
					placeholder="شماره اقتصادی یا شماره‌ملی"
					disabled={isEditing}
				/>
			</Form.Item>
			<Form.Item
				hasFeedback
				name="cod_yekta"
				rules={[
					{
						required: true,
						message: 'فیلد اجباری است',
					},
				]}
			>
				<Input size="large" placeholder="کدیکتا" />
			</Form.Item>
			<Form.Item
				hasFeedback
				name="name"
				rules={[
					{
						required: true,
						message: 'فیلد اجباری است',
					},
				]}
			>
				<Input size="large" placeholder="نام‌شرکت" />
			</Form.Item>
			<Form.Item
				hasFeedback
				name="license"
				rules={[
					{
						required: true,
						message: 'فیلد اجباری است',
					},
				]}
			>
				<Select
					disabled={!isEditing ? false : true}
					size="large"
					placeholder="لایسنس خریداری شده را انتخاب کنید"
				>
					{Object.values(licenses).map((license, index) => (
						<Select.Option
							key={license.key}
							value={license.key}
							disabled={license.allowed === 0 ? true : false}
						>
							{license.name}
						</Select.Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item
				hasFeedback
				name="private_key"
				rules={[
					{
						required: true,
						message: 'فیلد اجباری است',
					},
				]}
			>
				<TextArea
					size="large"
					placeholder="کدشخصی (Private Code)"
					rows={4}
				/>
			</Form.Item>
		</Form>
	);
}

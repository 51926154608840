import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import SendWithForm from '../SendWithForm/SendWithForm'
import { useDispatch } from 'react-redux'
import { getBill } from '../../app/services/bills/Slice'
import { useSelector } from 'react-redux'
import Loading from '../../features/Loading/Loading'
import { Button, Empty } from 'antd'
import Page404 from '../../features/Page404/Page404'

function useQuery() {
	const { search } = useLocation()
	return React.useMemo(() => new URLSearchParams(search), [search])
}

export default function Single(props) {
	let query = useQuery()
	const singleId = query.get('singleId')
	console.log(props.location)
	const dispatch = useDispatch()
	const { singleBill, billsIsLoading, singleBillStatus, singleIrtaxid } = useSelector(
		(state) => state.bills
	)
	const { allDataIsLoading } = useSelector((state) => state.general)
	console.log(singleIrtaxid)
	return (
		<div>
			{billsIsLoading || allDataIsLoading ? (
				<Loading loading='true' />
			) : singleIrtaxid || singleIrtaxid === '' ? (
				<SendWithForm
					edit={true}
					send_status={singleBillStatus}
					irtaxid={singleIrtaxid}
					singleId={singleId}
					propFormData={
						singleBill
							? singleBill
							: {
									gomrok: false,
									sub17: false,
									payments: false,
									inty: '1',
									inp: '1',
									inno: '',
									ins: 1,
									ft: null,
									setm: '1',
									cap: '0',
									insp: '0',
									tins: '1',
									sbc: null,
									crn: null,
									customer: null,
									bbc: null,
									bpn: null,
									billid: null,
									products: [
										{
											sstt: null,
											sstid: null,
											am: '1',
											mu: null,
											nw: null,
											fee: null,
											dis: '0',
											prdis: null,
											adis: null,
											vra: null,
											vam: null,
											cop: null,
											vop: null,
											tsstam: '0',
											consfee: null,
											spro: null,
											bros: null,
											tcpbs: null,
											odt: null,
											odr: null,
											odam: null,
											olt: null,
											olr: null,
											olam: null,
											cfee: null,
											cut: null,
											exr: null,
											ssrv: null,
											sscv: null,
											pspd: '0',
											bsrn: null
										}
									],
									tprdis: '0',
									tdis: '0',
									tadis: '0',
									tvop: '0',
									tvam: '0',
									todam: '0',
									tonw: '0',
									torv: null,
									tocv: null,
									tbill: '0',
									scln: null,
									scc: null,
									cdcn: null,
									tax17: null,
									iinn: null,
									acn: null,
									trmn: null,
									pmt: null,
									trn: null,
									pcn: null,
									pid: null,
									pv: null
							  }
					}
				/>
			) : (
				<Page404 />
			)}
		</div>
	)
}

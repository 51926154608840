import React, { useState, useEffect } from 'react';
import { Empty, Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../../features/Loading/Loading';
import { getUserLicenses } from '../../app/services/licenses/Slice';
import { ReactComponent as LicensesIcon } from '../../images/shopping-cart.svg';
import { ReactComponent as ClockIcon } from '../../images/clock-icon.svg';
import { ReactComponent as DollorIcon } from '../../images/dollor-icon.svg';
import { ReactComponent as RefreshIcon } from '../../images/refresh-icon.svg';
import '../../styles/licenses.scss';
import { remainingTime } from '@persian-tools/persian-tools';

export default function Licenses(props) {
	const { dropdown } = props;
	const dispatch = useDispatch();
	const { licenses, isLoading } = useSelector((state) => state.licenses);
	const { user } = useSelector((state) => state.user);
	const [dropDownValue, setdropDownValue] = useState({
		value: 'null',
		label: 'یک لایسنس انتخاب کنید',
	});

	const getAllLicenses = async () => {
		const allLicenses = await dispatch(
			getUserLicenses({ email: user.user_email, type: 'list' })
		);
		console.log(allLicenses.payload.status);

		if (dropdown) {
			if (allLicenses.payload.status == 200) {
				let dropDownVals = [
					{
						value: 'null',
						label: 'یک لایسنس انتخاب کنید',
					},
				];
				JSON.parse(allLicenses.payload.response.licenses).forEach(
					(license) => {
						dropDownVals.push({
							value: license.id,
							label: license.name,
						});
					}
				);
				setdropDownValue(dropDownVals);
			}
		}
	};
	const number = 1234567.89;

	const options = {
		style: 'decimal', // Other options: 'currency', 'percent', etc.
		minimumFractionDigits: 0,
		maximumFractionDigits: 2,
	};
	const DropDownChange = (value) => {
		console.log(`selected ${value}`);

		// Get the selected value from the dropdown
		var selectedValue = `licenseWidget-` + value;

		// Hide all items
		var allItems = document.querySelectorAll('.licenseWidget');
		allItems.forEach(function (item) {
			item.style.display = 'none';
		});

		// Display the selected item
		var selectedItem = document.querySelectorAll('#' + selectedValue);
		selectedItem.forEach(function (item) {
			item.style.display = 'flex';
		});
		// selectedItem.style.display = 'flex';
	};

	useEffect(() => {
		getAllLicenses();
	}, []);

	return (
		<div className="productsWrap">
			<div className="dashboardTitle">
				<div>
					<h4>لایسنس ها</h4>
					<p>
						در این قسمت لایسنس های خریداری شده نمایش داده خواهند شد
					</p>
				</div>
			</div>
			{isLoading ? (
				<Loading loading="true" />
			) : (
				<div
					className={
						licenses.length > 0
							? 'licenseContent dashboardMainContent'
							: 'licenseContent dashboardMainContent isEmpty'
					}
				>
					{dropdown && dropDownValue.length > 0 ? (
						<Select
							defaultValue="null"
							options={dropDownValue}
							style={{ width: 250 }}
							onChange={DropDownChange}
						/>
					) : null}
					{licenses.length > 0 ? (
						licenses.map((license, index) => (
							<div
								key={index}
								className="singleWidget licenseWidget"
								id={`licenseWidget-` + license.id}
								data-hide={
									dropdown && index !== 0 ? 'true' : 'false'
								}
							>
								<div className="singleWidgetRight">
									<LicensesIcon />
								</div>
								<div className="singleWidgetLeft">
									<div className="singleWidgetTitle">
										{license.name}
									</div>
									<div className="singleWidgetSubtitle">
										<ClockIcon /> <span>انقضا: </span>
										<span>
											{' '}
											{remainingTime(
												license.expire
											).toString()}
										</span>
									</div>
									<div className="singleWidgetSubtitle">
										<RefreshIcon /> <span>وضعیت: </span>
										<span> {license.status}</span>
									</div>
									<div className="licensePrice">
										{license.total.toLocaleString(
											'en-US',
											options
										)}{' '}
										تومان
									</div>
								</div>
							</div>
						))
					) : (
						<Empty description={false} />
					)}
				</div>
			)}
		</div>
	);
}

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import authService from './Service'

const initialState = {
	user: null,
	token: null,
	isLoading: false,
	notification: null,
	validTime: null
}

export const checkLoginToken = createAsyncThunk(
	'auth/checkLoginToken',
	async (token, thunkAPI) => {
		try {
			return await authService.checkLoginToken(token)
		} catch (error) {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString()
			return thunkAPI.rejectWithValue(message)
		}
	}
)

export const LoginUser = createAsyncThunk(
	'auth/LoginUser',
	async (userData, thunkAPI) => {
		try {
			return await authService.LoginUser(userData)
		} catch (error) {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString()
			return thunkAPI.rejectWithValue(message)
		}
	}
)

export const LogoutUser = createAsyncThunk('auth/LogoutUser', async (thunkAPI) => {
	try {
		return await authService.LogoutUser()
	} catch (error) {
		const message =
			(error.response && error.response.data && error.response.data.message) ||
			error.message ||
			error.toString()
		return thunkAPI.rejectWithValue(message)
	}
})
export const sendLoginCode = createAsyncThunk(
	'auth/sendLoginCode',
	async (data, thunkAPI) => {
		try {
			return await authService.sendLoginCode(data)
		} catch (error) {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString()
			return thunkAPI.rejectWithValue(message)
		}
	}
)

export const loginWithCode = createAsyncThunk(
	'auth/loginWithCode',
	async (data, thunkAPI) => {
		try {
			return await authService.loginWithCode(data)
		} catch (error) {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString()
			return thunkAPI.rejectWithValue(message)
		}
	}
)

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		logOut: (state) => {
			state.user = null
			state.token = null
			state.validTime = null
			state.isLoading = false
			state.notification = null
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(checkLoginToken.pending, (state) => {
				state.isLoading = true
			})
			.addCase(checkLoginToken.fulfilled, (state, action) => {
				state.isLoading = false
			})
			.addCase(checkLoginToken.rejected, (state, action) => {
				state.isLoading = false
			})
			.addCase(LogoutUser.pending, (state) => {
				state.isLoading = true
			})
			.addCase(LogoutUser.fulfilled, (state, action) => {
				state.isLoading = false
			})
			.addCase(LogoutUser.rejected, (state, action) => {
				state.isLoading = false
			})
			.addCase(LoginUser.pending, (state) => {
				state.isLoading = true
			})
			.addCase(LoginUser.fulfilled, (state, action) => {
				state.isLoading = false
				state.user = action.payload.response.user
				state.token = action.payload.response.token
				state.validTime = Date.now()
			})
			.addCase(LoginUser.rejected, (state, action) => {
				state.isLoading = false
				state.message = action.payload
			})
			.addCase(loginWithCode.fulfilled, (state, action) => {
				state.user = action.payload.response.user
				state.token = action.payload.response.token
				state.validTime = Date.now()
			})
	}
})

export const { setAuth, logOut } = authSlice.actions

export default authSlice.reducer

import React, { useEffect } from 'react'
import { Row, Col } from 'antd'
import NoticeBox from '../Docs/NoticeBox'
import {
	getBills,
	billsAnalytics,
	sandboxAnalytics
} from '../../app/services/bills/Slice'
import { useSelector, useDispatch } from 'react-redux'
import Loading from '../../features/Loading/Loading'
import Licenses from '../Licenses/Licenses'
import '../../styles/dashboard.scss'
import { Button } from 'antd'
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { defaults } from 'chart.js'
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

defaults.font.family = 'Vazirmatn'
const options = {
	responsive: true
}

export function MainActivitesChart() {
	const dispatch = useDispatch()
	const { billsIsLoading, billsAnalyticsData, bills } = useSelector(
		(state) => state.bills
	)

	useEffect(() => {
		fetchData()
	}, [billsIsLoading && billsAnalyticsData === null])

	const fetchData = async () => {
		const allData = await dispatch(
			getBills({
				tableParams: {
					pagination: {
						current: 1,
						pageSize: -1
					}
				},
				database: 'main'
			})
		)

		const mainFetchedData =
			typeof allData.payload === 'string'
				? JSON.parse(allData.payload)
				: allData.payload

		let mainPublished = 0
		let mainDraft = 0
		mainFetchedData.result.forEach((invoice) => {
			if (invoice.publish_status === 'published') {
				mainPublished++
			} else if (invoice.publish_status === 'draft') {
				mainDraft++
			}
		})

		const chartData = await dispatch(
			billsAnalytics({
				total: mainFetchedData.total ? parseInt(mainFetchedData.total) : 0,
				draft: mainDraft ? parseInt(mainDraft) : 0,
				published: mainPublished ? parseInt(mainPublished) : 0
			})
		)
	}

	const onReloadBills = () => {
		fetchData()
	}

	if (billsAnalyticsData !== null) {
		var labels = ['ارسال شده', 'ذخیره شده جهت ارسال', 'کل فاکتور ها']

		var config = {
			labels,
			datasets: [
				{
					fill: true,
					label: 'صورت حساب',
					data: [
						billsAnalyticsData.published,
						billsAnalyticsData.draft,
						billsAnalyticsData.total
					],
					borderColor: 'rgba(255, 99, 132, 1)',
					backgroundColor: 'rgba(255, 99, 132, 0.5)'
				}
			]
		}
	}

	return billsIsLoading || billsAnalyticsData === null ? (
		<Loading loading='true' />
	) : (
		<>
			<Button
				className='MAButton'
				type='primary'
				htmlType='submit'
				onClick={onReloadBills}>
				{billsIsLoading
					? 'درحال بروز رسانی صورت حساب ها'
					: 'بروز رسانی صورت حساب ها'}
			</Button>
			<Bar options={options} data={config} />
		</>
	)
}

export function SandboxActivitesChart() {
	const dispatch = useDispatch()
	const { billsIsLoading, sandboxAnalyticsData, bills } = useSelector(
		(state) => state.bills
	)

	useEffect(() => {
		fetchData()
	}, [billsIsLoading && sandboxAnalyticsData === null])

	const fetchData = async () => {
		const allData = await dispatch(
			getBills({
				tableParams: {
					pagination: {
						current: 1,
						pageSize: -1
					}
				},
				database: 'sandbox'
			})
		)

		const sandboxFetchedData =
			typeof allData.payload === 'string'
				? JSON.parse(allData.payload)
				: allData.payload

		let sandboxPublished = 0
		let sandboxDraft = 0
		sandboxFetchedData.result.forEach((invoice) => {
			if (invoice.publish_status === 'published') {
				sandboxPublished++
			} else if (invoice.publish_status === 'draft') {
				sandboxDraft++
			}
		})

		const chartData = await dispatch(
			sandboxAnalytics({
				total: sandboxFetchedData.total ? parseInt(sandboxFetchedData.total) : 0,
				draft: sandboxDraft ? parseInt(sandboxDraft) : 0,
				published: sandboxPublished ? parseInt(sandboxPublished) : 0
			})
		)
	}

	const onReloadBills = () => {
		fetchData()
	}

	if (sandboxAnalyticsData !== null) {
		var labels = ['ارسال شده', 'ذخیره شده جهت ارسال', 'کل فاکتور ها']

		var config = {
			labels,
			datasets: [
				{
					fill: true,
					label: 'سندباکس',
					data: [
						sandboxAnalyticsData.published,
						sandboxAnalyticsData.draft,
						sandboxAnalyticsData.total
					],
					borderColor: 'rgba(54, 162, 235, 1)',
					backgroundColor: 'rgba(54, 162, 235, 1)'
				}
			]
		}
	}

	return billsIsLoading || sandboxAnalyticsData === null ? (
		<Loading loading='true' />
	) : (
		<>
			<Button
				className='MAButton'
				type='primary'
				htmlType='submit'
				onClick={onReloadBills}>
				{billsIsLoading ? 'درحال بروز بروز رسانی سندباکس' : 'بروز رسانی سندباکس'}
			</Button>
			<Bar options={options} data={config} />
		</>
	)
}

export default function Dashboard() {
	return (
		<div className='activitesWrap'>
			<div className='dashboardTitle'>
				<Row>
					<Col md={24}>
						<h4>تمام فعالیت ها</h4>
						<p>
							در این بخش یک آمار کلی از فعالیت های انجام شده روی حساب کاربری
							شما تهیه شده. شما می توانید با استفاده از این ابزار ها ، روی
							فعالیت های انجام شده کنترل داشته باشید و آنها را برسی کنید.
							لازم به ذکر هست که در این بخش شما میتوانید میزان اعتبار لایسنس
							های خریداری شده را نیز ببینید
						</p>
					</Col>
				</Row>
			</div>
			<NoticeBox
				title='آموزش فعالیت ها'
				descriptions='فعالیت ها چیست و چه اطلاعاتی در اختیار ما میگذارد'
				button='نمایش مقاله'
				video='http://moadianabzar.ir/wp-content/uploads/2024/01/تمام-فعالیت-ها.mp4'
				modalDescription='در قسمت داشبورد یا تمام فعالیت‌ها و شما یه سری اطلاعات کلی در رابطه با لایسنس ها و صورت حساب ها داردید ، برای مثال اینجا ما می‌تونیم لایسنس ها را در این بخش انتخاب بکنیم مثلا نرم افزار واسط مودیان ابزار پنج شرکت ، اطلاعات کلی لایسنس رو در این قسمت نمایش میده وضعیت اینکه قابل استفاده هست یا نیست ، تاریخ انقضای لایسنس چه تاریخی است و ... ، در پایین‌تر آمار صورت حساب ها رو داریم که کل فاکتور هامون را نمیاش میدهد چه تعداد فاکتور توسط اکانت شما ثبت شده ، چه تعداد جهت ارسال ذخیره شده و چه تعداد فاکتور برای سامانه مودیان اداره مالیات توسط نرم افزار رابط مودیان ابزار ارسال شده است. و در پایین تر آمار فاکتور های ثبت شده در محیط سند باکس نمایش میدهد. که شامل کل فاکتور ها ، فاکتور های ثبت شده و فاکتور های ذخیره شده جهت ارسال به سندباکس را نمایش میده. شما میتوانید با استفاده از دکمه بروز رسانی این آمار ها رو بروز کنید.'
			/>
			<Row>
				<Col md={24} className='dashboard-divider'>
					<Licenses dropdown={true} />
				</Col>
				<Col md={24} className='dashboard-divider'>
					<h4>آمار صورت حساب ها</h4>
					<MainActivitesChart />
				</Col>
				<Col md={24} className='dashboard-divider'>
					<h4>آمار فعالیت های سندباکس (محیط تست)</h4>
					<SandboxActivitesChart />
				</Col>
			</Row>
		</div>
	)
}

import { Modal } from 'antd';
import { ReactComponent as WarningIcon } from '../../images/warning-icon.svg';

const { confirm } = Modal;

export default function ConfirmModal(props) {
	const { id, handle } = props;
	return confirm({
		title: ' ',
		className: 'MAConfirm',
		width: '484px',
		icon: <WarningIcon />,
		content: 'آیا از تصمیم خود مطمئن هستید ؟',
		okText: 'بله',
		cancelText: 'خیر',
		okButtonProps: {
			className: 'MAButton',
		},
		centered: true,
		cancelButtonProps: {
			className: 'MAButton MASecondry',
		},
		onOk() {
			handle(id);
		},
		onCancel() {},
	});
}

import React, { useState, useEffect } from 'react'
import {
	sendBill,
	getTotalBills,
	setSingleBill,
	setCurrentBillToSend,
	sendTwoStepFormCode
} from '../../app/services/bills/Slice'
import {
	Button,
	message,
	Spin,
	Form,
	Input,
	Row,
	Col,
	Select,
	notification,
	Tooltip,
	Collapse,
	InputNumber,
	Skeleton,
	Switch,
	Popconfirm
} from 'antd'
import DatePicker from 'react-multi-date-picker'
import persian from 'react-date-object/calendars/persian'
import persian_fa from 'react-date-object/locales/persian_fa'
import DateObject from 'react-date-object'
import InputIcon from 'react-multi-date-picker/components/input_icon'
import '../../styles/sendForm.scss'
import moment from 'jalali-moment'
import { digitsEnToFa } from '@persian-tools/persian-tools'
import { useSelector, useDispatch } from 'react-redux'
import Loading from '../../features/Loading/Loading'
import TimePicker from 'react-multi-date-picker/plugins/time_picker'
import { ReactComponent as QuestionIcon } from '../../images/question-icon.svg'
import { isBrowser } from 'react-device-detect'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'

import { allMeasurement, iso4217 } from '../../features/Globals/Globals'
import { ReactComponent as DeleteIcon } from '../../images/delete-icon.svg'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

export default function SendWithForm(props) {
	const { edit, propFormData, send_status, irtaxid, singleId } = props
	const dispatch = useDispatch()
	const [form] = Form.useForm()
	const inty = Form.useWatch('inty', form)
	const inp = Form.useWatch('inp', form)
	const setm = Form.useWatch('setm', form)
	const ins = Form.useWatch('ins', form)
	const [api, contextHolder] = notification.useNotification({
		placement: 'bottomRight',
		rtl: true
	})
	let todayJalali = moment().locale('fa').format('dddd D MMMM YYYY')
	const { companies, companyIsLoading } = useSelector((state) => state.companies)
	const [listCompanies, setListCompanies] = useState([])
	const [step, setStep] = useState(1)
	const [fullSend, setFullSend] = useState(false)
	const [confirmCode, setConfirmCode] = useState(false)
	const { customers, customerIsLoading } = useSelector((state) => state.customers)
	const [listCustomers, setListCustomers] = useState([])
	const { products, productIsLoading } = useSelector((state) => state.products)
	const [listProducts, setListProducts] = useState(null)
	const { totalBills, billsIsLoading } = useSelector((state) => state.bills)
	const { user } = useSelector((state) => state.user)
	const { settings } = useSelector((state) => state.settings)
	const [indatim, setIndatim] = useState(propFormData ? propFormData.indatim : null)
	const [Indati2m, setIndati2m] = useState(propFormData ? propFormData.Indati2m : null)
	const [cdcd, setCdcd] = useState(propFormData ? propFormData.cdcd : null)
	const [pdt, setPdt] = useState(propFormData ? propFormData.pdt : null)
	const [gomrok, setGomrok] = useState(propFormData ? propFormData.gomrok : false)
	const [sub17, setSub17] = useState(propFormData ? propFormData.sub17 : false)
	const [payments, setPayments] = useState(propFormData ? propFormData.payments : false)

	const emptyNotification = (name) => {
		api.error({
			message: ' ',
			description: `لطفا فیلد ${name} را پر کنید`
		})
	}
	const errorNotification = (desc, duration = 4.5) => {
		api.error({
			message: ' ',
			description: desc,
			duration: duration
		})
	}

	const successNotification = (desc, duration = 4.5) => {
		api.success({
			message: ' ',
			description: desc,
			duration: duration
		})
	}

	//check Integer Format
	const checkIntegerFormat = async (_, value) => {
		if (value && /^\d+$/.test(value) && value.length === 10) return Promise.resolve()
		if (value && !/^\d+$/.test(value)) {
			errorNotification('فقط عدد با کیبورد انگلیسی وارد کنید')
			return Promise.reject('فقط عدد  با کیبورد انگلیسی وارد کنید')
		}
	}

	const checkErrors = (formValues) => {
		if (edit) {
			if (propFormData.indatim === indatim) {
				errorNotification(
					'تاریخ صدور صورتحساب اصلاحی نمی تواند با صورتحساب اصلی برابر باشد'
				)
			}
			if (propFormData.Indati2m === Indati2m) {
				errorNotification(
					'تاریخ ایجاد صورتحساب اصلاحی نمی تواند با صورتحساب اصلی برابر باشد'
				)
			}
		}
		//2-indatim
		const indatimDate = indatim
			? new DateObject({
					date: indatim.toDate()
			  })
			: null
		formValues.indatim = indatimDate
			? Date.parse(indatimDate.format('YYYY-MM-DDTHH:mm:ss', ['Date', 'Time']))
			: null

		//3-indatim
		const Indati2mDate = Indati2m
			? new DateObject({
					date: Indati2m.toDate()
			  })
			: null
		formValues.Indati2m = Indati2mDate
			? Date.parse(Indati2mDate.format('YYYY-MM-DDTHH:mm:ss', ['Date', 'Time']))
			: null

		//5-inno
		if (formValues.inno.length > 0 && formValues.inno.length !== 10) {
			errorNotification(
				'مقدار فیلد شماره صورتحساب الکترونیک بیشتر از ۱۰ کاراکتر نمیتواند باشد'
			)
		}

		if (formValues.sbc && formValues.sbc.length > 4) {
			errorNotification('مقدار فیلد شماره شعبه بیشتر  از ۴ کاراکتر نمیتواند باشد')
		}

		if (formValues.bbc && formValues.bbc.length > 4) {
			errorNotification('مقدار فیلد شماره شعبه بیشتر  از ۴ کاراکتر نمیتواند باشد')
		}

		if (formValues.bpn && formValues.bpn.length > 0 && formValues.bpn.length !== 9) {
			errorNotification('مقدار فیلد گذرنامه بیشتر  از ۹ رقم نمیتواند باشد')
		}

		if (formValues.scln && formValues.scln.length > 14) {
			errorNotification(
				'مقدار فیلد شماره پروانه گمرکی بیشتر  از ۱۴ رقم نمیتواند باشد'
			)
		}

		if (formValues.scc && formValues.scc.length > 14) {
			errorNotification('کد گمرک محل اظهار فروشنده ۵ رقمی است')
		}

		if (formValues.crn && formValues.crn.length > 12) {
			errorNotification(
				'مقدار فیلد شماره پروانه گمرکی بیشتر  از ۱۲ رقم نمیتواند باشد'
			)
		}

		if (formValues.cdcn && formValues.cdcn.length > 14) {
			errorNotification(
				'مقدار فیلد شماره کوتاژ اظهارنامه گمرکی بیشتر  از ۱۴ رقم نمیتواند باشد'
			)
		}

		const cdcdDate = cdcd
			? new DateObject({
					date: cdcd.toDate()
			  })
			: null
		formValues.cdcd = cdcdDate
			? Date.parse(cdcdDate.format('YYYY-MM-DD', ['Date']))
			: null

		if (formValues.cdcd > formValues.indatim) {
			errorNotification(
				'تاریخ کوتاژ اظهارنامه گمرکی از تاریخ صدور صورتحساب نباید بزرگتر باشد'
			)
		}

		if (formValues.billid && formValues.billid.length > 19) {
			errorNotification(
				'مقدار فیلد شماره اشتراک/ شناسه قبض بهره بردار بیشتر  از ۱۹ رقم نمیتواند باشد'
			)
		}

		if (formValues.iinn && formValues.iinn.length !== 9) {
			errorNotification('مقدار فیلد شماره سوییچ پرداخت ۹ رقمی است')
		}

		if (formValues.acn && formValues.acn.length !== 14) {
			errorNotification('مقدار فیلد شماره پذیرنده فروشگاهی ۱۴ رقمی است')
		}

		if (formValues.trmn && formValues.trmn.length !== 8) {
			errorNotification('مقدار فیلد شماره پایانه  ۸ رقمی است')
		}

		if (formValues.trn && formValues.trn.length > 14) {
			errorNotification(
				'مقدار فیلد شماره پیگیری/ شماره مرجع بیشتر  از ۱۴ رقم نمیتواند باشد'
			)
		}

		if (formValues.pcn && formValues.pcn.length !== 16) {
			errorNotification('مقدار فیلد شماره کارت پرداخت کننده صورتحساب ۱۶ رقمی است')
		}
		if (formValues.pid && formValues.pid.length > 12) {
			errorNotification(
				'مقدار فیلد شماره/ شناسه ملی/کد فراگیر پرداخت کننده صورتحساب بیشتر  از ۱۲ رقم نمیتواند باشد'
			)
		}

		const pdtDate = pdt
			? new DateObject({
					date: pdt.toDate()
			  })
			: null
		formValues.pdt = pdtDate
			? Date.parse(pdtDate.format('YYYY-MM-DD', ['Date']))
			: null

		if (edit) {
			if (formValues.inno === propFormData.inno) {
				errorNotification(
					'شماره فاکتور / سریال صورتحساب اصلاحی نمیتواند با صورتحساب اصلی برابر باشد. لطفا آن را اصلاح نمایید'
				)
			}
		}
		return formValues
	}

	const handleFormSubmit = (status) => {
		form.validateFields()
			.then(async (values) => {
				const formData = checkErrors(values)
				//inno
				formData.inno =
					formData.inno !== ''
						? formData.inno
						: String(totalBills + 1).padStart(10, '0')

				// Product name
				formData.products.map((product) => {
					products.map(({ name, id }) => {
						if (product.sstid === id) {
							product.sstt = name
						}
					})
				})

				const data = {}

				// publishStatus
				data.publishStatus = status

				// Form Data
				data.formData = formData

				// Customer Data
				data.customer = customers[formData.customer]
				data.customer.id = formData.customer

				// Company Data
				data.company =
					formData.tins === 'sandbox'
						? {
								codeEghtesadi: settings.codeEghtesadi,
								shenaseYekta: settings.shenaseYekta,
								privateCode: settings.privateCode
						  }
						: companies[formData.tins]
				data.edit = edit
				data.irtaxid = irtaxid
				data.singleId = singleId
				data.step = settings ? settings.step : false
				const setSingleInReducer = await dispatch(setSingleBill(formData))

				// Send
				if (settings && settings.step) {
					sendTwoStepFormCodeFunc(data)
				} else {
					sendToTaxFunction(data)
				}
			})
			.catch((errorInfo) => {
				console.log(errorInfo.errorFields)
			})
	}

	const sendToTaxFunction = async (data) => {
		document.body.scrollTop = 0 // For Safari
		document.documentElement.scrollTop = 0
		const callSendBill = await dispatch(sendBill(data))
		console.log(callSendBill)
		if (callSendBill.payload.status === 403) {
			if (typeof callSendBill.payload.response === 'object') {
				callSendBill.payload.response.map((res) => {
					return errorNotification(res.msg, 7)
				})
			} else {
				errorNotification(callSendBill.payload.response, 7)
			}
		} else if (callSendBill.payload.status === 200) {
			successNotification(callSendBill.payload.response, 7)
			form.resetFields()
			setFullSend(true)
		}
	}

	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo.errorFields)
		errorInfo.errorFields.map((error) => {
			error.errors.map((item) => {
				errorNotification(item)
			})
		})
	}

	const handleTotal = (_, formValues) => {
		const allProductsStage = [...formValues.products]
		var totalPrdis = 0
		var totalDis = 0
		var totalAdis = 0
		var totalVam = 0
		var totalOdam = 0
		var totalBill = 0
		var totalOwn = 0
		var totalOrv = 0
		var totalOcv = 0

		allProductsStage.forEach((fieldGroup, index) => {
			if (fieldGroup && fieldGroup.am && fieldGroup.fee && fieldGroup.dis) {
				// مجموع اجرت، سود و حق العمل
				var tcpbs =
					parseInt(fieldGroup.consfee) +
					parseInt(fieldGroup.spro) +
					parseInt(fieldGroup.bros)
				fieldGroup.tcpbs = tcpbs.toLocaleString()

				// مبلغ قبل از تخفیف
				const prdis = parseInt(parseInt(fieldGroup.am) * parseInt(fieldGroup.fee))
				fieldGroup.prdis = prdis.toLocaleString()

				// مبلغ بعد از تخفیف
				var adis = parseInt(prdis - parseInt(fieldGroup.dis))
				if (inp === '3') {
					adis = parseInt(prdis + tcpbs - parseInt(fieldGroup.dis))
				} else if (inp === '2') {
					adis =
						parseInt(fieldGroup.pspd) * parseInt(fieldGroup.cfee) -
						parseInt(fieldGroup.dis)
				}
				fieldGroup.adis = adis.toLocaleString()

				//نرخ مالیات و مبلغ مالیات بر ارزش افزوده
				var vam = 0
				var vra = 0
				products.map((product) => {
					if (product.id === fieldGroup.sstid) {
						vra = parseFloat(product.taxRate)
						fieldGroup.vra = vra
						vam = parseInt((adis * vra) / 100)
						if (inp === '3') {
							vam = parseInt((tcpbs * 9) / 100 + (prdis * vra) / 100)
						}
						fieldGroup.vam = ((adis * vra) / 100).toLocaleString()
					}
				})

				// سایر مالیات و عوارض
				var odam = 0 //مبلغ سایر مالیات و عوارض
				var odr = fieldGroup.odr ? parseInt(fieldGroup.odr) : 0 //نرخ سایر مالیات و عوارض
				var ssrv = fieldGroup.ssrv ? parseInt(fieldGroup.ssrv) : 0 //ارزش ریالی کالا
				var sscv = fieldGroup.sscv ? parseFloat(fieldGroup.sscv) : 0 //ارزش ارزی کالا
				if (odr) {
					odam = parseInt((adis * odr) / 100)
					if (inp === '7') {
						odam = parseInt((ssrv * odr) / 100)
					}
					if (inp === '3') {
						odam = parseInt((tcpbs * odr) / 100)
					}
					fieldGroup.odam = odam.toLocaleString()
				}

				// سایر وجوه قانونی
				var olam = 0 //مبلغ سایر وجوه قانونی
				var olr = parseInt(fieldGroup.olr) //نرخ سایر وجوه قانونی
				if (olr) {
					olam = parseInt((adis * olr) / 100)
					if (inp === '7') {
						olam = parseInt((ssrv * olr) / 100)
					}
					if (inp === '3') {
						olam = parseInt((tcpbs * olr) / 100)
					}
					fieldGroup.olam = olam.toLocaleString()
				}

				// مبلغ کل کالا / خدمت
				var tsstam = 0
				fieldGroup.odam = fieldGroup.odam ? parseInt(fieldGroup.odam) : 0
				fieldGroup.olam = fieldGroup.olam ? parseInt(fieldGroup.olam) : 0
				fieldGroup.nw = fieldGroup.nw ? parseInt(fieldGroup.nw) : null
				tsstam = vam + adis + fieldGroup.odam + fieldGroup.olam
				if (inp === '7') {
					tsstam =
						vam + ssrv + parseInt(fieldGroup.odam) + parseInt(fieldGroup.olam)
				}
				if (inp === '3') {
					tsstam =
						vam +
						prdis +
						parseInt(fieldGroup.odam) +
						parseInt(fieldGroup.olam)
				}
				fieldGroup.tsstam = tsstam.toLocaleString()

				// مجموع ها
				totalPrdis += prdis
				totalDis += parseInt(fieldGroup.dis)
				totalAdis += adis
				totalVam += vam
				totalOdam += parseInt(fieldGroup.odam) + parseInt(fieldGroup.olam)
				totalBill += tsstam
				totalOwn += fieldGroup.nw ? parseInt(fieldGroup.nw) : null
				totalOrv += ssrv
				totalOcv += sscv

				// سهم نقدی از پرداخت
				var cop = 0
				if (setm === '3') {
					cop = (tsstam * parseInt(formValues.cap)) / totalAdis
					fieldGroup.cop = cop.toLocaleString()
				}

				var vop = 0
				if (setm === '3') {
					vop = (cop * vam) / totalAdis
					fieldGroup.vop = vop.toLocaleString()
				}
			}
		})

		form.setFieldsValue({
			tprdis: totalPrdis.toLocaleString(),
			tdis: totalDis.toLocaleString(),
			tadis: totalAdis.toLocaleString(),
			tvam: totalVam.toLocaleString(),
			todam: totalOdam.toLocaleString(),
			tbill: totalBill.toLocaleString(),
			tonw: totalOwn,
			torv: totalOrv.toLocaleString(),
			tocv: totalOcv.toLocaleString()
		})
	}

	const secondStepSendCode = () => {
		document.body.scrollTop = 0 // For Safari
		document.documentElement.scrollTop = 0
		setStep(2)
	}

	const sendTwoStepFormCodeFunc = async (data) => {
		const saveCurrentBill = await dispatch(setCurrentBillToSend(data))
		setConfirmCode(true)
		const sendCode = await dispatch(sendTwoStepFormCode({ data: user.user_mobile }))
		console.log(sendCode)
	}

	useEffect(() => {
		if (Object.keys(companies).length > 0) {
			let listCom = []
			if (settings && settings.sandbox) {
				listCom.push({
					value: 'sandbox',
					label: 'ارسال به محیط تست (سندباکس)'
				})
			}
			Object.keys(companies).forEach((key) => {
				listCom.push({
					value: key,
					label: companies[key]['name']
				})
			})
			setListCompanies(listCom)
		}
		if (Object.keys(customers).length > 0) {
			let listCos = []
			Object.keys(customers).forEach((key) => {
				listCos.push({
					value: key,
					label: customers[key]['fullname']
				})
			})
			setListCustomers(listCos)
		}
		if (products.length > 0) {
			let listPro = []
			products.map(({ name, id }) => {
				listPro.push({
					value: id,
					label: name
				})
			})
			setListProducts(listPro)
		}
	}, [companies, customers, products])
	return (
		<div className='sendWithFormWrap'>
			{contextHolder}
			<div className='dashboardTitle'>
				<div>
					<h4>{digitsEnToFa(todayJalali)}</h4>
				</div>
			</div>

			{billsIsLoading && !edit ? (
				<Loading loading='true' />
			) : (
				<React.Fragment>
					{settings && settings.step ? (
						<p className='twoStepNotification'>
							لطفا توجه کنید که ارسال صورتحساب دو مرحله ای در تنظیمات فعال
							شده است. برای غیر فعال سازی آن از طریق منوی سمت راست وارد بخش
							تنظیمات شده و آن را خاموش نمایید.
						</p>
					) : null}
					{step === 1 ? (
						<Form
							onFinishFailed={onFinishFailed}
							onValuesChange={handleTotal}
							className='sendingForm'
							form={form}
							name='basic'
							labelCol={{
								span: 8
							}}
							wrapperCol={{
								span: 16
							}}
							style={{
								maxWidth: 'auto'
							}}
							initialValues={
								propFormData
									? propFormData
									: {
											gomrok: false,
											sub17: false,
											payments: false,
											inty: '1',
											inp: '1',
											inno: '',
											ins: 1,
											ft: null,
											setm: '1',
											cap: '0',
											insp: '0',
											tins:
												settings && settings.sandbox
													? 'sandbox'
													: null,
											sbc: null,
											crn: null,
											customer: null,
											bbc: null,
											bpn: null,
											billid: null,
											products: [
												{
													sstt: null,
													sstid: null,
													am: '1',
													mu: null,
													nw: null,
													fee: null,
													dis: '0',
													prdis: null,
													adis: null,
													vra: null,
													vam: null,
													cop: null,
													vop: null,
													tsstam: '0',
													consfee: null,
													spro: null,
													bros: null,
													tcpbs: null,
													odt: null,
													odr: null,
													odam: null,
													olt: null,
													olr: null,
													olam: null,
													cfee: null,
													cut: null,
													exr: null,
													ssrv: null,
													sscv: null,
													pspd: '0',
													bsrn: null
												}
											],
											tprdis: '0',
											tdis: '0',
											tadis: '0',
											tvop: '0',
											tvam: '0',
											todam: '0',
											tonw: '0',
											torv: null,
											tocv: null,
											tbill: '0',
											scln: null,
											scc: null,
											cdcn: null,
											tax17: null,
											iinn: null,
											acn: null,
											trmn: null,
											pmt: null,
											trn: null,
											pcn: null,
											pid: null,
											pv: null
									  }
							}>
							{/* اطلاعات صورتحساب */}
							<Collapse
								collapsible={isBrowser ? 'icon' : 'header'}
								defaultActiveKey={['1']}
								items={[
									{
										key: '1',
										showArrow: isBrowser ? false : true,
										label: <h2>اطلاعات صورتحساب</h2>,
										children: (
											<Row
												className='rowWrapFormContent'
												gutter={[16, 4]}>
												<Col
													span={8}
													sm={24}
													xs={24}
													md={24}
													lg={12}
													xl={8}>
													<h4>
														چه نوع صورتحسابی میخواهید ارسال
														کنید؟
													</h4>
													<Form.Item name='inty'>
														<Select
															disabled={edit}
															style={{
																width: '100%'
															}}
															options={[
																{
																	value: '1',
																	label: 'الکترونیکی نوع اول'
																},
																{
																	value: '2',
																	label: 'الکترونیکی نوع دوم'
																},
																{
																	value: '3',
																	label: 'الکترونیکی نوع سوم'
																}
															]}
														/>
													</Form.Item>
												</Col>
												<Col
													span={8}
													sm={24}
													xs={24}
													md={24}
													lg={12}
													xl={8}>
													<h4>
														کدام الگو را برای این صورتحساب
														انتخاب می کنید؟
													</h4>
													<Form.Item name='inp'>
														<Select
															disabled={
																inty === '3' || edit
																	? true
																	: false
															}
															style={{
																width: '100%'
															}}
															options={[
																{
																	value: '1',
																	label: 'فروش',
																	disabled:
																		inty === '1' ||
																		inty === '2'
																			? false
																			: true
																},
																{
																	value: '2',
																	label: 'فروش ارز',
																	disabled:
																		inty === '1'
																			? false
																			: true
																},
																{
																	value: '3',
																	label: 'طلا، جواهر و پلاتین',
																	disabled:
																		inty === '1' ||
																		inty === '2'
																			? false
																			: true
																},
																{
																	value: '4',
																	label: 'قرارداد پیمانکاری',
																	disabled:
																		inty === '1'
																			? false
																			: true
																},
																{
																	value: '5',
																	label: 'قبوض خدماتی',
																	disabled:
																		inty === '1'
																			? false
																			: true
																},
																{
																	value: '6',
																	label: 'بلیط هواپیما',
																	disabled:
																		inty === '1'
																			? false
																			: true
																},
																{
																	value: '7',
																	label: 'صادرات',
																	disabled:
																		inty === '1'
																			? false
																			: true
																}
															]}
														/>
													</Form.Item>
												</Col>
												{edit ? (
													<Col
														sm={24}
														xs={24}
														md={24}
														lg={12}
														xl={8}
														span={8}>
														<h4>موضوع صورتحساب چیست؟</h4>
														<Form.Item
															name='ins'
															rules={[
																{
																	required: true,
																	message:
																		'موضوع صورتحساب  را انتخاب کنید'
																}
															]}>
															<Select
																style={{
																	width: '100%'
																}}
																options={[
																	// {
																	// 	value: '1',
																	// 	label: 'اصلی فروش'
																	// },
																	{
																		value: '2',
																		label: 'اصلاحی'
																	},
																	// {
																	// 	value: '3',
																	// 	label: 'ابطالی'
																	// },
																	{
																		value: '4',
																		label: 'برگشت از فروش'
																	}
																]}
															/>
														</Form.Item>
													</Col>
												) : null}

												<Col
													sm={24}
													xs={24}
													md={24}
													lg={12}
													xl={8}
													span={8}>
													<h4>
														سریال صورتحساب / شماره فاکتور
														<Tooltip title='این فیلد باید ۱۰ رقمی باشد. در صورت خالی بودن این فیلد، مقدار آن به صورت تصادفی توسط مؤدیان ابزار اعمال خواهد شد '>
															<Button>
																<QuestionIcon />
															</Button>
														</Tooltip>
													</h4>
													<Form.Item
														name='inno'
														rules={[
															{
																validator:
																	checkIntegerFormat
															}
														]}>
														<Input />
													</Form.Item>
												</Col>
												<Col
													sm={24}
													xs={24}
													md={24}
													lg={12}
													xl={8}
													span={8}>
													<h4>تاریخ ایجاد</h4>
													<Form.Item
														name='Indati2m'
														rules={[
															{
																required: true,
																message:
																	'تاریخ ایجاد صورتحساب را وارد کنید'
															}
														]}>
														<DatePicker
															onChange={setIndati2m}
															plugins={[
																<TimePicker position='bottom' />
															]}
															render={<InputIcon />}
															format='HH:mm:ss YYYY/MM/DD'
															calendar={persian}
															locale={persian_fa}
														/>
													</Form.Item>
												</Col>
												<Col
													sm={24}
													xs={24}
													md={24}
													lg={12}
													xl={8}
													span={8}>
													<h4>تاریخ صدور</h4>
													<Form.Item
														name='indatim'
														rules={[
															{
																required: true,
																message:
																	'تاریخ صدور صورتحساب را وارد کنید'
															}
														]}>
														<DatePicker
															onChange={setIndatim}
															plugins={[
																<TimePicker position='bottom' />
															]}
															render={<InputIcon />}
															format='HH:mm:ss YYYY/MM/DD'
															calendar={persian}
															locale={persian_fa}
														/>
													</Form.Item>
												</Col>
												{inty === '1' && inp === '6' ? (
													<Col
														sm={24}
														xs={24}
														md={24}
														lg={12}
														xl={8}
														span={8}>
														<h4>نوع پرواز</h4>
														<Form.Item name='ft'>
															<Select
																disabled={edit}
																placeholder='انتخاب نوع پرواز'
																options={[
																	{
																		value: '1',
																		label: 'داخلی'
																	},
																	{
																		value: '2',
																		label: 'خارجی'
																	}
																]}
															/>
														</Form.Item>
													</Col>
												) : null}
												{inty === '1' &&
												(inp === '1' ||
													inp === '3' ||
													inp === '4') ? (
													<>
														<Col
															sm={24}
															xs={24}
															md={24}
															lg={12}
															xl={8}
															span={8}>
															<h4>
																روش تسویه با مشتری را
																انتخاب کنید
															</h4>
															<Form.Item name='setm'>
																<Select
																	placeholder='گزینه مورد نظر را انتخاب کنید'
																	options={[
																		{
																			value: '1',
																			label: 'نقد'
																		},
																		{
																			value: '2',
																			label: 'نسیه',
																			disabled:
																				inty ===
																					'2' ||
																				inty ===
																					'3'
																					? true
																					: false
																		},
																		{
																			value: '3',
																			label: 'نقد / نسیه',
																			disabled:
																				inty ===
																					'2' ||
																				inty ===
																					'3'
																					? true
																					: false
																		}
																	]}
																/>
															</Form.Item>
														</Col>
														{setm === '3' &&
														(inp === '1' ||
															inp === '3' ||
															inp === '4') ? (
															<>
																<Col
																	sm={24}
																	xs={24}
																	md={24}
																	lg={12}
																	xl={8}
																	span={8}>
																	<h4>
																		چقدر از صورتحساب
																		نقدی پرداخت شده
																		است؟
																	</h4>
																	<Form.Item name='cap'>
																		<Input placeholder='مبلغ دریافت شده به صورت نقدی' />
																	</Form.Item>
																</Col>
																<Col
																	sm={24}
																	xs={24}
																	md={24}
																	lg={12}
																	xl={8}
																	span={8}>
																	<h4>
																		چقدر از صورتحساب
																		نسیه پرداخت خواهد
																		شد؟
																	</h4>
																	<Form.Item name='insp'>
																		<Input placeholder='مبلغ دریافت شده به صورت نسیه' />
																	</Form.Item>
																</Col>
															</>
														) : null}
													</>
												) : null}
											</Row>
										)
									}
								]}
							/>

							{/* اطلاعات فروشنده */}
							<Collapse
								collapsible={isBrowser ? 'icon' : 'header'}
								defaultActiveKey={isBrowser ? ['1'] : ['0']}
								items={[
									{
										key: '1',
										showArrow: isBrowser ? false : true,
										label: <h2>اطلاعات فروشنده</h2>,
										children: (
											<Row
												className='rowWrapFormContent'
												gutter={[16, 4]}>
												<Col
													sm={24}
													xs={24}
													md={24}
													lg={12}
													xl={8}
													span={8}>
													<h4>
														صورتحساب برای کدام فروشنده / شرکت
														است؟
													</h4>
													<Form.Item
														name='tins'
														rules={[
															{
																required: true,
																message:
																	'فروشنده / شرکت را انتخاب کنید'
															}
														]}>
														{companyIsLoading ? (
															<Skeleton.Input
																active={true}
															/>
														) : (
															<Select
																disabled={edit}
																placeholder='انتخاب فروشنده / شرکت'
																style={{
																	width: '100%'
																}}
																options={
																	listCompanies.length >
																	0
																		? listCompanies
																		: null
																}
															/>
														)}
													</Form.Item>
												</Col>
												<Col
													sm={24}
													xs={24}
													md={24}
													lg={12}
													xl={8}
													span={8}>
													<h4>
														صورتحساب مربوط به کدام شعبه است؟
													</h4>
													<Form.Item
														name='sbc'
														rules={[
															{
																validator:
																	checkIntegerFormat
															}
														]}>
														<Input placeholder='کد شعبه' />
													</Form.Item>
												</Col>
												<Col
													sm={24}
													xs={24}
													md={24}
													lg={12}
													xl={8}
													span={8}>
													<h4>
														آیا قرارداد شما شناسه یکتا دارد؟
													</h4>
													<Form.Item
														name='crn'
														rules={[
															{
																validator:
																	checkIntegerFormat
															}
														]}>
														<Input placeholder='شناسه یکتای ثبت قرارداد فروشنده' />
													</Form.Item>
												</Col>
											</Row>
										)
									}
								]}
							/>

							{/* اطلاعات خریدار */}
							<Collapse
								collapsible={isBrowser ? 'icon' : 'header'}
								defaultActiveKey={isBrowser ? ['1'] : ['0']}
								items={[
									{
										key: '1',
										showArrow: isBrowser ? false : true,
										label: <h2>اطلاعات خریدار</h2>,
										children: (
											<Row
												className='rowWrapFormContent'
												gutter={[16, 4]}>
												<Col
													sm={24}
													xs={24}
													md={24}
													lg={12}
													xl={8}
													span={8}>
													<h4>
														صورتحساب مربوط به کدام خریدار /
														مشتری است؟
													</h4>
													<Form.Item
														name='customer'
														rules={[
															{
																required: true,
																message:
																	'خریدار / مشتری را انتخاب کنید'
															}
														]}>
														{customerIsLoading ? (
															<Skeleton.Input
																active={true}
															/>
														) : (
															<Select
																disabled={edit}
																placeholder='انتخاب خریدار / مشتری'
																style={{
																	width: '100%'
																}}
																options={
																	listCustomers.length >
																	0
																		? listCustomers
																		: null
																}
															/>
														)}
													</Form.Item>
												</Col>
												<Col
													sm={24}
													xs={24}
													md={24}
													lg={12}
													xl={8}
													span={8}>
													<h4>کد شعبه</h4>
													<Form.Item
														name='bbc'
														rules={[
															{
																validator:
																	checkIntegerFormat
															}
														]}>
														<Input
															disabled={edit}
															placeholder='کد شعبه'
														/>
													</Form.Item>
												</Col>
												{inty === '1' &&
												(inp === '2' || inp === '6') ? (
													<Col
														sm={24}
														xs={24}
														md={24}
														lg={12}
														xl={8}
														span={8}>
														<h4>شماره گذرنامه</h4>
														<Form.Item name='bpn'>
															<Input
																disabled={edit}
																placeholder='شماره گذرنامه'
															/>
														</Form.Item>
													</Col>
												) : null}
												{inty === '1' && inp === '5' ? (
													<Col
														sm={24}
														xs={24}
														md={24}
														lg={12}
														xl={8}
														span={8}>
														<h4>
															شماره اشتراک /شناسه قبض بهره
															بردار
														</h4>
														<Form.Item
															name='billid'
															rules={[
																{
																	validator:
																		checkIntegerFormat
																}
															]}>
															<Input
																disabled={edit}
																placeholder='شماره اشتراک /شناسه قبض'
															/>
														</Form.Item>
													</Col>
												) : null}
											</Row>
										)
									}
								]}
							/>

							{/* محصولات */}
							<Collapse
								collapsible={isBrowser ? 'icon' : 'header'}
								defaultActiveKey={isBrowser ? ['1'] : ['0']}
								items={[
									{
										key: '1',
										showArrow: isBrowser ? false : true,
										label: <h2>محصولات</h2>,
										children: (
											<Form.List name='products'>
												{(fields, { add, remove }) => (
													<>
														{fields.map(({ key, name }) => (
															<Row
																key={key}
																className='formProductRow'>
																<Col span={24}>
																	<Row
																		className='formProductTitleRow'
																		gutter={[32, 4]}>
																		<Col span={12}>
																			محصول شماره :{' '}
																			{key + 1}
																		</Col>
																		<Col
																			span={12}
																			className='formLeftProductTitleRow'>
																			{edit ? null : (
																				<DeleteIcon
																					className='formRemoveProduct'
																					onClick={() =>
																						remove(
																							name
																						)
																					}
																				/>
																			)}
																		</Col>
																	</Row>
																	<Row
																		className='rowWrapFormContent rowWrapFormProducts'
																		gutter={[16, 4]}>
																		{/* شناسه کالا */}
																		<Col
																			sm={24}
																			xs={24}
																			md={24}
																			lg={12}
																			xl={8}
																			span={8}>
																			<h4>
																				شناسه
																				کالای خود
																				را انتخاب
																				کنید
																			</h4>
																			<Form.Item
																				name={[
																					name,
																					'sstid'
																				]}
																				rules={[
																					{
																						required: true,
																						message:
																							'شناسه کالا را انتخاب کنید'
																					}
																				]}>
																				{productIsLoading ? (
																					<Skeleton.Input
																						active={
																							true
																						}
																					/>
																				) : (
																					<Select
																						disabled={
																							edit
																						}
																						showSearch
																						optionFilterProp='children'
																						filterOption={(
																							input,
																							option
																						) =>
																							(
																								option?.label ??
																								''
																							).includes(
																								input
																							)
																						}
																						placeholder='انتخاب شناسه کالا / خدمت'
																						options={
																							listProducts
																						}
																					/>
																				)}
																			</Form.Item>
																		</Col>

																		{/* تعداد / مقدار */}
																		{inp !== '2' ? (
																			<Col
																				sm={24}
																				xs={24}
																				md={24}
																				lg={12}
																				xl={8}
																				span={3}>
																				<h4>
																					تعداد
																					/
																					مقدار
																				</h4>
																				<Form.Item
																					name={[
																						name,
																						'am'
																					]}>
																					<InputNumber
																						placeholder='تعداد/مقدار'
																						min='1'
																						stringMode
																					/>
																				</Form.Item>
																			</Col>
																		) : null}

																		{/* واحد اندازه گیری */}
																		<Col
																			sm={24}
																			xs={24}
																			md={24}
																			lg={12}
																			xl={8}
																			span={4}>
																			<h4>
																				واحد
																				اندازه
																				گیری
																			</h4>
																			<Form.Item
																				name={[
																					name,
																					'mu'
																				]}>
																				<Select
																					disabled={
																						edit
																					}
																					showSearch
																					optionFilterProp='children'
																					filterOption={(
																						input,
																						option
																					) =>
																						(
																							option?.label ??
																							''
																						).includes(
																							input
																						)
																					}
																					placeholder='واحد اندازه گیری'
																					options={
																						allMeasurement
																					}
																				/>
																			</Form.Item>
																		</Col>

																		{/* مقدار وزن خالص */}
																		{inp === '7' ? (
																			<Col
																				sm={24}
																				xs={24}
																				md={24}
																				lg={12}
																				xl={8}
																				span={4}>
																				<h4>
																					مقدار
																					وزن
																					خالص
																				</h4>
																				<Form.Item
																					name={[
																						name,
																						'nw'
																					]}
																					rules={[
																						{
																							validator:
																								checkIntegerFormat
																						}
																					]}>
																					<Input placeholder='وزن خالص' />
																				</Form.Item>
																			</Col>
																		) : null}

																		{/* مبلغ واحد */}
																		{inp !== '2' ? (
																			<Col
																				sm={24}
																				xs={24}
																				md={24}
																				lg={12}
																				xl={8}
																				span={4}>
																				<h4>
																					مبلغ
																					واحد
																				</h4>
																				<Form.Item
																					name={[
																						name,
																						'fee'
																					]}
																					rules={[
																						{
																							validator:
																								checkIntegerFormat
																						},
																						{
																							required: true,
																							message:
																								'مبلغ واحد را وارد کنید'
																						}
																					]}>
																					<Input
																						disabled={
																							edit
																						}
																						placeholder='مبلغ واحد'
																					/>
																				</Form.Item>
																			</Col>
																		) : null}
																		{inp !== '7' ? (
																			<Col
																				sm={24}
																				xs={24}
																				md={24}
																				lg={12}
																				xl={8}
																				span={4}>
																				<h4>
																					مبلغ
																					تخفیف
																				</h4>
																				<Form.Item
																					name={[
																						name,
																						'dis'
																					]}
																					rules={[
																						{
																							validator:
																								checkIntegerFormat
																						}
																					]}>
																					<Input placeholder='مبلغ تخفیف' />
																				</Form.Item>
																			</Col>
																		) : null}
																	</Row>
																	{inp === '3' ? (
																		<Row
																			className='rowWrapFormProducts'
																			gutter={[
																				16, 4
																			]}>
																			{/* مربوط به طلا فروشی */}

																			<>
																				<Col
																					sm={
																						24
																					}
																					xs={
																						24
																					}
																					md={
																						24
																					}
																					lg={
																						12
																					}
																					xl={8}
																					span={
																						6
																					}>
																					<h4>
																						اجرت
																						ساخت
																					</h4>
																					<Form.Item
																						name={[
																							name,
																							'consfee'
																						]}
																						rules={[
																							{
																								validator:
																									checkIntegerFormat
																							}
																						]}>
																						<Input placeholder='اجرت ساخت' />
																					</Form.Item>
																				</Col>
																				<Col
																					sm={
																						24
																					}
																					xs={
																						24
																					}
																					md={
																						24
																					}
																					lg={
																						12
																					}
																					xl={8}
																					span={
																						6
																					}>
																					<h4>
																						سود
																						فروشنده
																					</h4>
																					<Form.Item
																						name={[
																							name,
																							'spro'
																						]}
																						rules={[
																							{
																								validator:
																									checkIntegerFormat
																							}
																						]}>
																						<Input placeholder='سود فروشنده' />
																					</Form.Item>
																				</Col>
																				<Col
																					sm={
																						24
																					}
																					xs={
																						24
																					}
																					md={
																						24
																					}
																					lg={
																						12
																					}
																					xl={8}
																					span={
																						6
																					}>
																					<h4>
																						حق
																						العمل
																					</h4>
																					<Form.Item
																						name={[
																							name,
																							'bros'
																						]}
																						rules={[
																							{
																								validator:
																									checkIntegerFormat
																							}
																						]}>
																						<Input placeholder='حق العمل' />
																					</Form.Item>
																				</Col>
																				<Col
																					sm={
																						24
																					}
																					xs={
																						24
																					}
																					md={
																						24
																					}
																					lg={
																						12
																					}
																					xl={8}
																					span={
																						6
																					}>
																					<h4>
																						جمع
																						کل
																						اجرت،
																						حق
																						العمل
																						و
																						سود
																					</h4>
																					<Form.Item
																						name={[
																							name,
																							'tcpbs'
																						]}>
																						<Input
																							disabled
																							placeholder='جمع کل اجرت، حق العمل و سود'
																						/>
																					</Form.Item>
																				</Col>
																			</>
																		</Row>
																	) : null}

																	<Row
																		className='rowWrapFormProducts'
																		gutter={[16, 4]}>
																		{/*  سایر مالیات و عوارض و سایر وجوه قانونی */}
																		<Col
																			sm={24}
																			xs={24}
																			md={24}
																			lg={12}
																			xl={8}
																			span={6}>
																			<h4>
																				سایر
																				مالیات و
																				عوارض و
																				وجوه
																				قانونی
																			</h4>
																			<Form.Item
																				valuePropName='checked'
																				name={[
																					name,
																					'sayer'
																				]}>
																				<Switch />
																			</Form.Item>
																		</Col>
																		{inp === '1' ||
																		inp === '3' ||
																		inp === '4' ? (
																			<Col
																				sm={24}
																				xs={24}
																				md={24}
																				lg={12}
																				xl={8}
																				span={8}>
																				<h4>
																					سهم
																					نقدی و
																					مالیات
																					بر
																					ارزش
																					افزوده
																					از
																					پرداخت
																				</h4>
																				<Form.Item
																					valuePropName='checked'
																					name={[
																						name,
																						'sahm'
																					]}>
																					<Switch />
																				</Form.Item>
																			</Col>
																		) : null}
																		<Col
																			sm={24}
																			xs={24}
																			md={24}
																			lg={12}
																			xl={8}
																			span={5}>
																			<h4>
																				اطلاعات
																				ارزی
																			</h4>
																			<Form.Item
																				valuePropName='checked'
																				name={[
																					name,
																					'arz'
																				]}>
																				<Switch />
																			</Form.Item>
																		</Col>
																		<Col
																			sm={24}
																			xs={24}
																			md={24}
																			lg={12}
																			xl={8}
																			span={5}>
																			<h4>
																				شناسه
																				یکتای ثبت
																				قرارداد
																			</h4>
																			<Form.Item
																				valuePropName='checked'
																				name={[
																					name,
																					'gharardad'
																				]}>
																				<Switch />
																			</Form.Item>
																		</Col>
																	</Row>

																	{form.getFieldValue([
																		'products',
																		name,
																		'sayer'
																	]) ? (
																		<Row
																			className='rowWrapFormProducts'
																			gutter={[
																				16, 4
																			]}>
																			<Col
																				sm={24}
																				xs={24}
																				md={24}
																				lg={12}
																				xl={8}
																				span={8}>
																				<h4>
																					موضوع
																					سایر
																					مالیات
																					و
																					عوارض
																				</h4>
																				<Form.Item
																					name={[
																						name,
																						'odt'
																					]}>
																					<Input placeholder='عنوان سایر مالیات و عوارض' />
																				</Form.Item>
																			</Col>
																			<Col
																				sm={24}
																				xs={24}
																				md={24}
																				lg={12}
																				xl={8}
																				span={8}>
																				<h4>
																					نرخ
																					سایر
																					مالیات
																					و
																					عوارض
																				</h4>
																				<Form.Item
																					name={[
																						name,
																						'odr'
																					]}>
																					<Input placeholder='نرخ سایر مالیات و عوارض' />
																				</Form.Item>
																			</Col>
																			<Col
																				sm={24}
																				xs={24}
																				md={24}
																				lg={12}
																				xl={8}
																				span={8}>
																				<h4>
																					مقدار
																					سایر
																					مالیات
																					و
																					عوارض
																					<Tooltip title='اگر نرخ وارد کنید این فیلد اتوماتیک محاسبه خواهد شد'>
																						<Button>
																							<QuestionIcon />
																						</Button>
																					</Tooltip>
																				</h4>
																				<Form.Item
																					name={[
																						name,
																						'odam'
																					]}
																					rules={[
																						{
																							validator:
																								checkIntegerFormat
																						}
																					]}>
																					<Input placeholder='مقدار سایر مالیات و عوارض' />
																				</Form.Item>
																			</Col>
																			<Col
																				sm={24}
																				xs={24}
																				md={24}
																				lg={12}
																				xl={8}
																				span={8}>
																				<h4>
																					موضوع
																					سایر
																					وجوه
																					قانونی
																				</h4>
																				<Form.Item
																					name={[
																						name,
																						'olt'
																					]}>
																					<Input placeholder='موضوع سایر وجوه قانونی' />
																				</Form.Item>
																			</Col>
																			<Col
																				sm={24}
																				xs={24}
																				md={24}
																				lg={12}
																				xl={8}
																				span={8}>
																				<h4>
																					نرخ
																					سایر
																					وجوه
																					قانونی
																				</h4>
																				<Form.Item
																					name={[
																						name,
																						'olr'
																					]}>
																					<Input placeholder='نرخ سایر وجوه قانونی' />
																				</Form.Item>
																			</Col>
																			<Col
																				sm={24}
																				xs={24}
																				md={24}
																				lg={12}
																				xl={8}
																				span={8}>
																				<h4>
																					مقدار
																					سایر
																					وجوه
																					قانونی
																					<Tooltip title='اگر نرخ وارد کنید این فیلد اتوماتیک محاسبه خواهد شد'>
																						<Button>
																							<QuestionIcon />
																						</Button>
																					</Tooltip>
																				</h4>
																				<Form.Item
																					name={[
																						name,
																						'olam'
																					]}
																					rules={[
																						{
																							validator:
																								checkIntegerFormat
																						}
																					]}>
																					<Input placeholder='مقدار سایر وجوه قانونی' />
																				</Form.Item>
																			</Col>
																		</Row>
																	) : null}

																	{/* سهم نقدی از پرداخت */}
																	{form.getFieldValue([
																		'products',
																		name,
																		'sahm'
																	]) ? (
																		<Row
																			className='rowWrapFormProducts'
																			gutter={[
																				16, 4
																			]}>
																			<Col
																				sm={24}
																				xs={24}
																				md={24}
																				lg={12}
																				xl={8}
																				span={8}>
																				<h4>
																					سهم
																					نقدی
																					از
																					پرداخت
																				</h4>
																				<Form.Item
																					name={[
																						name,
																						'cop'
																					]}>
																					<Input placeholder='سهم نقدی از پرداخت' />
																				</Form.Item>
																			</Col>
																			<Col
																				sm={24}
																				xs={24}
																				md={24}
																				lg={12}
																				xl={8}
																				span={8}>
																				<h4>
																					سهم
																					مالیات
																					بر
																					ارزش
																					افزوده
																					از
																					پرداخت
																				</h4>
																				<Form.Item
																					name={[
																						name,
																						'vop'
																					]}>
																					<Input placeholder='سهم مالیات بر ارزش افزوده از پرداخت' />
																				</Form.Item>
																			</Col>
																		</Row>
																	) : null}

																	{/* میزان ارز */}
																	{form.getFieldValue([
																		'products',
																		name,
																		'arz'
																	]) ? (
																		<Row
																			className='rowWrapFormProducts'
																			gutter={[
																				16, 4
																			]}>
																			{inp !==
																				'5' ||
																			inp !==
																				'7' ? (
																				<Col
																					sm={
																						24
																					}
																					xs={
																						24
																					}
																					md={
																						24
																					}
																					lg={
																						12
																					}
																					xl={8}
																					span={
																						4
																					}>
																					{/* میزان ارز */}
																					<h4>
																						میزان
																						ارز
																					</h4>
																					<Form.Item
																						name={[
																							name,
																							'cfee'
																						]}>
																						<Input placeholder='میزان ارز' />
																					</Form.Item>
																				</Col>
																			) : null}

																			{/* نوع ارز */}
																			{inp !==
																			'5' ? (
																				<Col
																					sm={
																						24
																					}
																					xs={
																						24
																					}
																					md={
																						24
																					}
																					lg={
																						12
																					}
																					xl={8}
																					span={
																						6
																					}>
																					<h4>
																						نوع
																						ارز
																					</h4>
																					<Form.Item
																						name={[
																							name,
																							'cut'
																						]}>
																						<Select
																							showSearch
																							optionFilterProp='children'
																							filterOption={(
																								input,
																								option
																							) =>
																								(
																									option?.label ??
																									''
																								)
																									.toLowerCase()
																									.includes(
																										input.toLowerCase()
																									)
																							}
																							placeholder='نوع ارز'
																							options={
																								iso4217
																							}
																						/>
																					</Form.Item>
																				</Col>
																			) : null}

																			{/* نرخ برابری ارز با ریال */}
																			{inp !==
																			'5' ? (
																				<Col
																					sm={
																						24
																					}
																					xs={
																						24
																					}
																					md={
																						24
																					}
																					lg={
																						12
																					}
																					xl={8}
																					span={
																						4
																					}>
																					<h4>
																						نرخ
																						برابری
																						ارز
																						با
																						ریال
																						<Tooltip
																							title='در مورد صورتحسابهای الکترونیکی با الگوی فروش ارز، نرخ ارز مورد معامله میبایست بر اساس تاریخ معامله (تاریخ صدور صورتحساب) بر اساس نرخ اعلامی بانک مرکزی جمهوری اسلامی ایران برای تاریخ مذکور درج و محاسبه شود.
																			در مورد صورتحسابهای الکترونیکی با الگوی صادرات، نرخ ارز مورد معامله
																			میبایست با نرخ ارز پروانه گمرکی مطابقت داشته باشد. '>
																							<Button>
																								<QuestionIcon />
																							</Button>
																						</Tooltip>
																					</h4>
																					<Form.Item
																						name={[
																							name,
																							'exr'
																						]}>
																						<Input placeholder='نرخ برابری ارز با ریال' />
																					</Form.Item>
																				</Col>
																			) : null}

																			{/* ارزش ریالی کالا */}
																			{inp ===
																			'7' ? (
																				<Col
																					sm={
																						24
																					}
																					xs={
																						24
																					}
																					md={
																						24
																					}
																					lg={
																						12
																					}
																					xl={8}
																					span={
																						4
																					}>
																					<h4>
																						ارزش
																						ریالی
																						کالا
																						<Tooltip title='ارزش ریالی کالا برابر ارزش ریالی هر قلم کالا/ خدمت بر اساس پروانه گمرکی خواهد بود.'>
																							<Button>
																								<QuestionIcon />
																							</Button>
																						</Tooltip>
																					</h4>
																					<Form.Item
																						name={[
																							name,
																							'ssrv'
																						]}>
																						<Input placeholder='ارزش ریالی کالا' />
																					</Form.Item>
																				</Col>
																			) : null}

																			{/* ارزش ارزی کالا */}
																			{inp ===
																			'7' ? (
																				<Col
																					sm={
																						24
																					}
																					xs={
																						24
																					}
																					md={
																						24
																					}
																					lg={
																						12
																					}
																					xl={8}
																					span={
																						4
																					}>
																					<h4>
																						ارزش
																						ارزی
																						کالا
																						<Tooltip title='در صورتحسابهای الکترونیکی با الگوی صادرات، ارزش ارزی کالا می بایست دقیقا منطبق با اظهارنامه گمرکی در صورتحساب ثبت شود.'>
																							<Button>
																								<QuestionIcon />
																							</Button>
																						</Tooltip>
																					</h4>
																					<Form.Item
																						name={[
																							name,
																							'sscv'
																						]}>
																						<Input placeholder='ارزش ارزی کالا' />
																					</Form.Item>
																				</Col>
																			) : null}

																			{/* تفاوت نرخ خرید و فروش ارز */}
																			{inp ===
																			'2' ? (
																				<Col
																					sm={
																						24
																					}
																					xs={
																						24
																					}
																					md={
																						24
																					}
																					lg={
																						12
																					}
																					xl={8}
																					span={
																						6
																					}>
																					<h4>
																						تفاوت
																						نرخ
																						خرید
																						و
																						فروش
																						ارز/
																						کارمزد
																						<Tooltip
																							title='مقدار این فیلد در فروش اسکناس/حواله برابر است با تفاوت نرخ خرید ارز اعلامی بانک مرکزی جمهوری اسلامی ایران از نرخ فروش آن در تاریخ معامله.(تاریخ صدور صورتحساب) یا حاشیه سود اضافه شده به قیمت خرید
																				(مطابق بخشنامههای بانک مرکزی جمهوری اسلامی)'>
																							<Button>
																								<QuestionIcon />
																							</Button>
																						</Tooltip>
																					</h4>
																					<Form.Item
																						name={[
																							name,
																							'pspd'
																						]}>
																						<Input placeholder='تفاوت نرخ خرید و فروش ارز/ کارمزد' />
																					</Form.Item>
																				</Col>
																			) : null}
																		</Row>
																	) : null}

																	{/* شناسه یکتا قرارداد */}
																	{form.getFieldValue([
																		'products',
																		name,
																		'gharardad'
																	]) ? (
																		<Row
																			className='rowWrapFormProducts'
																			gutter={[
																				16, 4
																			]}>
																			<Col
																				sm={24}
																				xs={24}
																				md={24}
																				lg={12}
																				xl={8}
																				span={6}>
																				<h4>
																					شناسه
																					یکتای
																					ثبت
																					قرارداد
																					حق
																					العمل
																					کاری
																				</h4>
																				<Form.Item
																					name={[
																						name,
																						'bsrn'
																					]}>
																					<Input placeholder='شناسه یکتای ثبت قرارداد حق العمل کاری' />
																				</Form.Item>
																			</Col>
																		</Row>
																	) : null}

																	<Row
																		className='rowWrapFormProducts'
																		gutter={[16, 4]}>
																		{/* مبلغ تخفیف , مبلغ قبل از تخفیف ,مبلغ بعد از تخفیف  */}
																		{inp !== '7' ? (
																			<>
																				<Col
																					sm={
																						24
																					}
																					xs={
																						24
																					}
																					md={
																						24
																					}
																					lg={
																						12
																					}
																					xl={6}
																					span={
																						6
																					}>
																					<h4>
																						مبلغ
																						قبل
																						از
																						تخفیف
																					</h4>
																					<Form.Item
																						name={[
																							name,
																							'prdis'
																						]}>
																						<Input
																							placeholder='مبلغ قبل از تخفیف'
																							disabled
																						/>
																					</Form.Item>
																				</Col>
																				<Col
																					sm={
																						24
																					}
																					xs={
																						24
																					}
																					md={
																						24
																					}
																					lg={
																						12
																					}
																					xl={6}
																					span={
																						6
																					}>
																					<h4>
																						مبلغ
																						بعد
																						از
																						تخفیف
																					</h4>
																					<Form.Item
																						name={[
																							name,
																							'adis'
																						]}>
																						<Input
																							placeholder='مبلغ بعد از تخفیف'
																							disabled
																						/>
																					</Form.Item>
																				</Col>
																			</>
																		) : null}

																		{/* نرخ مالیات بر ارزش افزوده */}
																		<Col
																			sm={24}
																			xs={24}
																			md={24}
																			lg={12}
																			xl={8}
																			span={6}>
																			<h4>
																				نرخ مالیات
																				بر ارزش
																				افزوده
																			</h4>
																			<Form.Item
																				name={[
																					name,
																					'vra'
																				]}>
																				<Input
																					placeholder='نرخ مالیات بر ارزش افزوده'
																					disabled
																				/>
																			</Form.Item>
																		</Col>

																		{/* مبلغ مالیات بر ارزش افزوده */}
																		<Col
																			sm={24}
																			xs={24}
																			md={24}
																			lg={12}
																			xl={8}
																			span={6}>
																			<h4>
																				مبلغ
																				مالیات بر
																				ارزش
																				افزوده
																			</h4>
																			<Form.Item
																				name={[
																					name,
																					'vam'
																				]}>
																				<Input
																					placeholder='مبلغ مالیات بر ارزش افزوده'
																					disabled
																				/>
																			</Form.Item>
																		</Col>
																		<Col
																			sm={24}
																			xs={24}
																			md={24}
																			lg={12}
																			xl={8}
																			span={8}>
																			<h4>
																				مبلغ کل
																				کالا/خدمت
																			</h4>
																			<Form.Item
																				name={[
																					name,
																					'tsstam'
																				]}>
																				<Input
																					disabled
																					placeholder='مبلغ کل کالا/خدمت'
																				/>
																			</Form.Item>
																		</Col>
																	</Row>
																</Col>
															</Row>
														))}
														{edit ? null : (
															<Form.Item>
																<Button
																	className='formAddProduct'
																	type='dashed'
																	onClick={() => add()}
																	block>
																	افزودن محصول
																</Button>
															</Form.Item>
														)}
													</>
												)}
											</Form.List>
										)
									}
								]}
							/>

							{/* اطلاعات اضافی */}
							<Collapse
								collapsible={isBrowser ? 'icon' : 'header'}
								defaultActiveKey={isBrowser ? ['1'] : ['0']}
								items={[
									{
										key: '1',
										showArrow: isBrowser ? false : true,
										label: <h2>اطلاعات تکمیلی</h2>,
										children: (
											<Row
												className='rowWrapFormContent'
												gutter={[16, 4]}>
												{inty === '1' &&
												(inp === '1' ||
													inp === '2' ||
													inp === '7') ? (
													<Col
														sm={24}
														xs={24}
														md={24}
														lg={12}
														xl={8}
														span={8}>
														<h4>اطلاعات گمرکی</h4>
														<Form.Item
															name='gomrok'
															valuePropName='checked'>
															<Switch
																defaultChecked={gomrok}
																onChange={() =>
																	setGomrok(!gomrok)
																}
															/>
														</Form.Item>
													</Col>
												) : null}
												{inp === '1' ||
												inp === '2' ||
												inp === '3' ||
												inp === '4' ||
												inp === '5' ||
												inp === '6' ? (
													<Col
														sm={24}
														xs={24}
														md={24}
														lg={12}
														xl={8}
														span={8}>
														<h4> مالیات موضوع ماده ۱۷</h4>
														<Form.Item
															name='sub17'
															valuePropName='checked'>
															<Switch
																defaultChecked={sub17}
																onChange={() =>
																	setSub17(!sub17)
																}
															/>
														</Form.Item>
													</Col>
												) : null}
												{inp !== '7' ? (
													<Col
														sm={24}
														xs={24}
														md={24}
														lg={12}
														xl={8}
														span={8}>
														<h4>
															اطلاعات پرداخت صورتحساب
															<Tooltip title='اطلاعاتی مانند شماره سوییچ، شماره پذیرنده و...'>
																<Button>
																	<QuestionIcon />
																</Button>
															</Tooltip>
														</h4>
														<Form.Item
															name='payments'
															valuePropName='checked'>
															<Switch
																defaultChecked={payments}
																onChange={() =>
																	setPayments(!payments)
																}
															/>
														</Form.Item>
													</Col>
												) : null}
											</Row>
										)
									}
								]}
							/>

							{/* اطلاعات گمرکی */}
							{gomrok ? (
								inty === '1' &&
								(inp === '1' || inp === '2' || inp === '7') ? (
									<Collapse
										collapsible={isBrowser ? 'icon' : 'header'}
										defaultActiveKey={isBrowser ? ['1'] : ['0']}
										items={[
											{
												key: '1',
												showArrow: isBrowser ? false : true,
												label: <h2>اطلاعات گمرکی</h2>,
												children: (
													<Row
														className='rowWrapFormContent'
														gutter={[16, 4]}>
														{inp === '1' || inp === '2' ? (
															<Col
																sm={24}
																xs={24}
																md={24}
																lg={12}
																xl={8}
																span={6}>
																<h4>
																	شماره پروانه گمرکی
																</h4>
																<Form.Item
																	name='scln'
																	rules={[
																		{
																			validator:
																				checkIntegerFormat
																		}
																	]}>
																	<Input placeholder='شماره پروانه' />
																</Form.Item>
															</Col>
														) : null}
														{inp === '1' ||
														inp === '2' ||
														inp === '7' ? (
															<Col
																sm={24}
																xs={24}
																md={24}
																lg={12}
																xl={8}
																span={6}>
																<h4>
																	کد گمرک محل اظهار
																	فروشنده
																</h4>
																<Form.Item
																	name='scc'
																	rules={[
																		{
																			validator:
																				checkIntegerFormat
																		}
																	]}>
																	<Input placeholder='کد گمرک' />
																</Form.Item>
															</Col>
														) : null}
														{inp === '1' ? (
															<React.Fragment>
																<Col
																	sm={24}
																	xs={24}
																	md={24}
																	lg={12}
																	xl={8}
																	span={6}>
																	<h4>
																		شماره کوتاژ
																		اظهارنامه گمرکی
																	</h4>
																	<Form.Item name='cdcn'>
																		<Input placeholder='شماره کوتاژ' />
																	</Form.Item>
																</Col>
																<Col
																	sm={24}
																	xs={24}
																	md={24}
																	lg={12}
																	xl={8}
																	span={6}>
																	<h4>
																		تاریخ کوتاژ
																		اظهارنامه گمرکی
																	</h4>
																	<Form.Item name='cdcd'>
																		<DatePicker
																			onChange={
																				setCdcd
																			}
																			render={
																				<InputIcon />
																			}
																			format='YYYY/MM/DD'
																			calendar={
																				persian
																			}
																			locale={
																				persian_fa
																			}
																		/>
																	</Form.Item>
																</Col>
															</React.Fragment>
														) : null}
													</Row>
												)
											}
										]}
									/>
								) : null
							) : null}

							{/* مالیات موضوع ماده 17 */}
							{sub17 ? (
								inp === '1' ||
								inp === '2' ||
								inp === '3' ||
								inp === '4' ||
								inp === '5' ||
								inp === '6' ? (
									<Collapse
										collapsible={isBrowser ? 'icon' : 'header'}
										defaultActiveKey={isBrowser ? ['1'] : ['0']}
										items={[
											{
												key: '1',
												showArrow: isBrowser ? false : true,
												label: (
													<h2>
														مالیات موضوع ماده 17
														<Tooltip title='تبصره ۱ ماده ۱۷ قانون مالیات بر ارزش افزوده سال ۱۴۰۰'>
															<Button>
																<QuestionIcon />
															</Button>
														</Tooltip>
													</h2>
												),
												children: (
													<Row
														className='rowWrapFormContent'
														gutter={[16, 4]}>
														<Col
															sm={24}
															xs={24}
															md={24}
															lg={12}
															xl={8}
															span={8}>
															<h4>
																مقدار مالیات پرداخت شده
																موضوع ماده 17
															</h4>
															<Form.Item name='tax17'>
																<Input placeholder='مقدار مالیات پرداخت شده را  به ریال وارد کنید' />
															</Form.Item>
														</Col>
													</Row>
												)
											}
										]}
									/>
								) : null
							) : null}

							{/* اطلاعات پرداخت صورتحساب */}
							{payments ? (
								<Collapse
									collapsible={isBrowser ? 'icon' : 'header'}
									defaultActiveKey={isBrowser ? ['1'] : ['0']}
									items={[
										{
											key: '1',
											showArrow: isBrowser ? false : true,
											label: <h2>اطلاعات پرداخت صورتحساب</h2>,
											children: (
												<Row
													className='rowWrapFormContent'
													gutter={[16, 4]}>
													{inp !== '7' ? (
														<>
															<Col
																sm={24}
																xs={24}
																md={24}
																lg={12}
																xl={8}
																span={8}>
																<h4>
																	شماره سوئیچ پرداخت
																</h4>
																<Form.Item
																	name='iinn'
																	rules={[
																		{
																			validator:
																				checkIntegerFormat
																		}
																	]}>
																	<Input placeholder='شماره سوئیچ پرداخت' />
																</Form.Item>
															</Col>
															<Col
																sm={24}
																xs={24}
																md={24}
																lg={12}
																xl={8}
																span={8}>
																<h4>
																	شماره پذیرنده فروشگاهی
																</h4>
																<Form.Item
																	name='acn'
																	rules={[
																		{
																			validator:
																				checkIntegerFormat
																		}
																	]}>
																	<Input placeholder='شماره پذیرنده فروشگاهی' />
																</Form.Item>
															</Col>
															<Col
																sm={24}
																xs={24}
																md={24}
																lg={12}
																xl={8}
																span={8}>
																<h4>شماره پایانه</h4>
																<Form.Item
																	name='trmn'
																	rules={[
																		{
																			validator:
																				checkIntegerFormat
																		}
																	]}>
																	<Input placeholder='شماره پایانه' />
																</Form.Item>
															</Col>
															<Col
																sm={24}
																xs={24}
																md={24}
																lg={12}
																xl={8}
																span={8}>
																<h4>روش پرداخت</h4>
																<Form.Item name='pmt'>
																	<Select
																		placeholder='انتخاب روش پرداخت'
																		style={{
																			width: '100%'
																		}}
																		options={[
																			{
																				value: '1',
																				label: 'چک'
																			},
																			{
																				value: '2',
																				label: 'تهاتر'
																			},
																			{
																				value: '3',
																				label: 'وجه نقد'
																			},
																			{
																				value: '4',
																				label: 'POS'
																			},
																			{
																				value: '5',
																				label: 'درگاه پرداخت اینترنتی'
																			},
																			{
																				value: '6',
																				label: 'کارت به کارت'
																			},
																			{
																				value: '7',
																				label: 'انتقال به حساب'
																			},
																			{
																				value: '8',
																				label: 'سایر'
																			}
																		]}
																	/>
																</Form.Item>
															</Col>
															<Col
																sm={24}
																xs={24}
																md={24}
																lg={12}
																xl={8}
																span={8}>
																<h4>
																	شماره پیگیری/شماره
																	مرجع
																</h4>
																<Form.Item
																	name='trn'
																	rules={[
																		{
																			validator:
																				checkIntegerFormat
																		}
																	]}>
																	<Input placeholder='شماره پیگیری/شماره مرجع' />
																</Form.Item>
															</Col>
															<Col
																sm={24}
																xs={24}
																md={24}
																lg={12}
																xl={8}
																span={8}>
																<h4>
																	شماره کارت پرداخت
																	کننده
																</h4>
																<Form.Item
																	name='pcn'
																	rules={[
																		{
																			validator:
																				checkIntegerFormat
																		}
																	]}>
																	<Input placeholder='شماره کارت پرداخت کننده' />
																</Form.Item>
															</Col>
															<Col
																sm={24}
																xs={24}
																md={24}
																lg={12}
																xl={8}
																span={8}>
																<h4>
																	پرداخت کننده / مشتری
																</h4>
																<Form.Item
																	name='pid'
																	rules={[
																		{
																			validator:
																				checkIntegerFormat
																		}
																	]}>
																	<Input placeholder='شماره/ شناسه ملی/کد فراگیر پرداخت کننده صورتحساب' />
																</Form.Item>
															</Col>
															<Col
																sm={24}
																xs={24}
																md={24}
																lg={12}
																xl={8}
																span={8}>
																<h4>
																	تاریخ و زمان پرداخت
																</h4>
																<Form.Item name='pdt'>
																	<DatePicker
																		onChange={setPdt}
																		render={
																			<InputIcon />
																		}
																		format='YYYY/MM/DD'
																		calendar={persian}
																		locale={
																			persian_fa
																		}
																	/>
																</Form.Item>
															</Col>
															<Col
																sm={24}
																xs={24}
																md={24}
																lg={12}
																xl={8}
																span={8}>
																<h4>مبلغ پرداختی</h4>
																<Form.Item name='pv'>
																	<Input placeholder='مبلغ پرداختی' />
																</Form.Item>
															</Col>
														</>
													) : null}
												</Row>
											)
										}
									]}
								/>
							) : null}

							{/* اطلاعات مالی صورتحساب */}
							<Collapse
								collapsible={isBrowser ? 'icon' : 'header'}
								defaultActiveKey={isBrowser ? ['1'] : ['0']}
								items={[
									{
										key: '1',
										showArrow: isBrowser ? false : true,
										label: (
											<h2>
												اطلاعات مالی صورتحساب
												<Tooltip
													title='این بخش به علت حساس بودن و احتمال اشتباه، قابل
	  									تغییر نیست و به صورت اتوماتیک بر اساس اطلاعات وارد شده محاسبه خواهد شد'>
													<Button>
														<QuestionIcon />
													</Button>
												</Tooltip>
											</h2>
										),
										children: (
											<Row
												className='rowWrapFormContent'
												gutter={[16, 4]}>
												<Col
													sm={24}
													xs={24}
													md={24}
													lg={12}
													xl={8}
													span={8}>
													<h4>مجموع مبلغ قبل از کسر تخفیف</h4>
													<Form.Item name='tprdis'>
														<Input disabled />
													</Form.Item>
												</Col>
												<Col
													sm={24}
													xs={24}
													md={24}
													lg={12}
													xl={8}
													span={8}>
													<h4>مجموع تخفیفات</h4>
													<Form.Item name='tdis'>
														<Input disabled />
													</Form.Item>
												</Col>
												<Col
													sm={24}
													xs={24}
													md={24}
													lg={12}
													xl={8}
													span={8}>
													<h4>مجموع مبلغ پس از کسر تخفیف</h4>
													<Form.Item name='tadis'>
														<Input disabled />
													</Form.Item>
												</Col>
												{inp === '1' ||
												inp === '2' ||
												inp === '3' ||
												inp === '4' ? (
													<Col
														sm={24}
														xs={24}
														md={24}
														lg={12}
														xl={8}
														span={8}>
														<h4>
															مجموع سهم مالیات بر ارزش
															افزوده از پرداخت
														</h4>
														<Form.Item name='tvop'>
															<Input disabled />
														</Form.Item>
													</Col>
												) : null}
												<Col
													sm={24}
													xs={24}
													md={24}
													lg={12}
													xl={8}
													span={8}>
													<h4>مجموع مالیات بر ارزش افزوده</h4>
													<Form.Item name='tvam'>
														<Input disabled />
													</Form.Item>
												</Col>
												<Col
													sm={24}
													xs={24}
													md={24}
													lg={12}
													xl={8}
													span={8}>
													<h4>
														مجموع سایر مالیات، عوارض و وجوه
														قانونی
													</h4>
													<Form.Item name='todam'>
														<Input disabled />
													</Form.Item>
												</Col>
												{inp === '7' ? (
													<React.Fragment>
														<Col
															sm={24}
															xs={24}
															md={24}
															lg={12}
															xl={8}
															span={8}>
															<h4>مجموع وزن خالص</h4>
															<Form.Item name='tonw'>
																<Input disabled />
															</Form.Item>
														</Col>
														<Col
															sm={24}
															xs={24}
															md={24}
															lg={12}
															xl={8}
															span={8}>
															<h4>مجموع ارزش ریالی</h4>
															<Form.Item name='torv'>
																<Input disabled />
															</Form.Item>
														</Col>
														<Col
															sm={24}
															xs={24}
															md={24}
															lg={12}
															xl={8}
															span={8}>
															<h4>مجموع ارزش ارزی</h4>
															<Form.Item name='tocv'>
																<Input disabled />
															</Form.Item>
														</Col>
													</React.Fragment>
												) : null}
												<Col
													sm={24}
													xs={24}
													md={24}
													lg={12}
													xl={8}
													span={8}>
													<h4>مجموع صورتحساب</h4>
													<Form.Item name='tbill'>
														<Input disabled />
													</Form.Item>
												</Col>
											</Row>
										)
									}
								]}
							/>
							<Row gutter={16} className='buttonsWrap'>
								{confirmCode && !fullSend ? (
									<SecondStep sendToTaxFunction={sendToTaxFunction} />
								) : (
									<Col sm={24} xs={24} md={24} lg={12} xl={8} span={24}>
										{settings && settings.step ? (
											<Button
												onClick={() =>
													handleFormSubmit('published')
												}
												disabled={
													send_status === -1 ||
													send_status === '-1'
														? true
														: false
												}
												className='MAButton MASecondry'
												type='primary'>
												مرحله بعد - ارسال کد تایید
											</Button>
										) : (
											<Popconfirm
												title='ارسال به سامانه'
												description='آیا از انجام این عملیات اطمینان دارید؟'
												okText='بله'
												cancelText='خیر'
												onConfirm={() =>
													handleFormSubmit('published')
												}>
												<Button
													disabled={
														send_status === -1 ||
														send_status === '-1'
															? true
															: false
													}
													className='MAButton MASecondry'
													type='primary'>
													{edit
														? 'ارسال صورتحساب اصلاحی به اداره مالیات'
														: 'ارسال به اداره مالیات'}
												</Button>
											</Popconfirm>
										)}

										{edit ? null : (
											<Button
												onClick={() => handleFormSubmit('draft')}
												type='dashed'
												className='MAButton MADashed'
												htmlType='submit'>
												ذخیره
											</Button>
										)}
									</Col>
								)}
							</Row>
						</Form>
					) : (
						<SecondStep />
					)}
				</React.Fragment>
			)}
		</div>
	)
}

const SecondStep = (props) => {
	const { sendToTaxFunction } = props
	const dispatch = useDispatch()
	const [messageApi, contextHolder] = message.useMessage()
	const [resendButton, setResendButton] = useState(false)
	const [loading, setLoading] = useState(false)
	const [duration, setDuration] = useState(120)
	const [twoStepForm] = Form.useForm()
	const { currentBillToSend } = useSelector((state) => state.bills)
	const { user } = useSelector((state) => state.user)
	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo)
	}

	const sendConfirmCodeAgain = async () => {
		setLoading(true)
		const sendCode = await dispatch(sendTwoStepFormCode({ data: user.user_mobile }))
		console.log(sendCode)
		if (sendCode.payload.status === 200) {
			setDuration(120)
			setResendButton(false)
			setLoading(false)
		}
	}
	const finalSendBill = () => {
		twoStepForm
			.validateFields()
			.then(async (values) => {
				const allData = { ...currentBillToSend }
				allData.twoStepCode = values.code
				await sendToTaxFunction(allData)
			})
			.catch((errorInfo) => {
				console.log(errorInfo.errorFields)
			})
	}
	return (
		<Col span={24}>
			{contextHolder}
			<div className='secondStepWrap'>
				<Spin spinning={loading}>
					<Form
						form={twoStepForm}
						className='sendCodeToSendBill'
						name='twoStepForm'
						onFinishFailed={onFinishFailed}>
						<Row>
							<Col span={12}>
								<Form.Item
									name='code'
									rules={[
										{
											required: true,
											message: 'کد را وارد کنید'
										}
									]}>
									<Input placeholder='کد ارسال شده به موبایل خود را وارد کنید' />
								</Form.Item>
								{!resendButton ? (
									<div className='circleTimerWrap'>
										<CountdownCircleTimer
											size={35}
											strokeWidth={1}
											isPlaying={true}
											duration={duration}
											colors={['#487bff', '#ffab48']}
											colorsTime={[duration, 0]}
											onComplete={() => {
												// do your stuff here
												setResendButton(true)
												messageApi.error(
													'کد شما منقضی شده است. لطفا مجدد کد را دریافت کنید'
												)
											}}>
											{({ remainingTime }) => {
												return (
													<div className='MAcircleTimer'>
														{digitsEnToFa(remainingTime)}
													</div>
												)
											}}
										</CountdownCircleTimer>
									</div>
								) : null}
							</Col>
							<Col span={12}>
								<div className='twoStepButtonsWrap'>
									<div className='resendCodeWrap'>
										<Button
											className='MAButton'
											disabled={resendButton ? false : true}
											onClick={() => sendConfirmCodeAgain()}>
											ارسال کد تایید
										</Button>
									</div>
									<Form.Item>
										<Button
											onClick={() => finalSendBill()}
											type='primary'
											className='MAButton MASecondry'
											htmlType='submit'>
											ارسال صورتحساب
										</Button>
									</Form.Item>
								</div>
							</Col>
						</Row>
					</Form>
				</Spin>
			</div>
		</Col>
	)
}

import React from 'react'
import Lottie from 'lottie-react'
import LoadingJSON from '../../images/loading9.json'

export default function Loading(props) {
	const { loading } = props
	return loading ? (
		<div className='loadingWrap'>
			<Lottie animationData={LoadingJSON} loop={true} />
		</div>
	) : null
}

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import usersService from './Service';

const initialState = {
	extraUsers: [],
	isLoading: false,
	isSuccess: false,
	secretKey: null,
};

export const getUsers = createAsyncThunk('users/getUsers', async (thunkAPI) => {
	try {
		return await usersService.getUsers();
	} catch (error) {
		const message =
			(error.response &&
				error.response.data &&
				error.response.data.message) ||
			error.message ||
			error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});

export const saveUser = createAsyncThunk(
	'users/saveUser',
	async (user, thunkAPI) => {
		try {
			return await usersService.saveUser(user);
		} catch (error) {
			const message =
				(error.response &&
					error.response.data &&
					error.response.data.message) ||
				error.message ||
				error.toString();
			return thunkAPI.rejectWithValue(message);
		}
	}
);

export const deleteUser = createAsyncThunk(
	'users/deleteUser',
	async (id, thunkAPI) => {
		try {
			return await usersService.deleteUser(id);
		} catch (error) {
			const message =
				(error.response &&
					error.response.data &&
					error.response.data.message) ||
				error.message ||
				error.toString();
			return thunkAPI.rejectWithValue(message);
		}
	}
);

export const GetSecretKey = createAsyncThunk(
	'auth/GetSecretKey',
	async (__, thunkAPI) => {
		try {
			return await usersService.GetSecretKey();
		} catch (error) {
			const message =
				(error.response &&
					error.response.data &&
					error.response.data.message) ||
				error.message ||
				error.toString();
			return thunkAPI.rejectWithValue(message);
		}
	}
);

export const usersSlice = createSlice({
	name: 'users',
	initialState,
	reducers: {
		addUser: (state, action) => {
			state.extraUsers = [...state.extraUsers, action.payload[0]];
		},
		removeUser: (state, action) => {
			state.extraUsers = state.extraUsers.filter(
				(item) => item.ID !== action.payload
			);
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getUsers.pending, (state) => {
				state.isLoading = true;
				state.isSuccess = false;
			})
			.addCase(getUsers.fulfilled, (state, action) => {
				state.isLoading = false;
				state.extraUsers =
					action.payload.status === 200
						? action.payload.response
						: [];
				state.isSuccess = true;
			})
			.addCase(getUsers.rejected, (state, action) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(saveUser.pending, (state) => {
				state.isLoading = true;
				state.isSuccess = false;
			})
			.addCase(saveUser.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isSuccess = true;
			})
			.addCase(saveUser.rejected, (state, action) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(deleteUser.pending, (state) => {
				state.isLoading = true;
				state.isSuccess = false;
			})
			.addCase(deleteUser.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isSuccess = true;
			})
			.addCase(deleteUser.rejected, (state, action) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(GetSecretKey.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(GetSecretKey.fulfilled, (state, action) => {
				state.isLoading = false;
				state.secretKey = action.payload.data.response;
			})
			.addCase(GetSecretKey.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.payload.response;
			});
	},
});

export const { addUser, removeUser } = usersSlice.actions;

export default usersSlice.reducer;

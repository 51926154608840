import React, { useState, useEffect } from 'react';
import { Button, Drawer, Form, Input, Empty, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import {
	addProduct,
	removeProduct,
	getProducts,
	saveProduct,
	deleteProduct,
} from '../../app/services/products/Slice';
import { ReactComponent as DeleteIcon } from '../../images/delete-icon.svg';
import Loading from '../../features/Loading/Loading';
import { ReactComponent as CloseIcon } from '../../images/close-icon.svg';
import { ReactComponent as PlusIcon } from '../../images/plus-icon.svg';
import NoticeBox from '../Docs/NoticeBox';

export default function Products() {
	const dispatch = useDispatch();
	const [messageApi, contextHolder] = message.useMessage();
	const { token } = useSelector((state) => state.user);
	const { products, productIsLoading } = useSelector(
		(state) => state.products
	);
	console.log(products);
	const [open, setOpen] = useState(false);
	const showDrawer = () => {
		setOpen(true);
	};

	const getAllProducts = async () => {
		const allProducts = await dispatch(getProducts());
		console.log(allProducts);
	};

	const removeSingleWidget = async (id) => {
		const response = await dispatch(deleteProduct(id));
		console.log(response);
		dispatch(removeProduct(id));
	};

	useEffect(() => {
		// if (products.length <= 0) getAllProducts()
	}, []);

	return (
		<div className="productsWrap">
			{contextHolder}
			<div className="dashboardTitle">
				<div>
					<h4>شناسه کالا</h4>
					<p>
						لطفا شناسه مورد تایید سازمان را اینجا وارد کنید. در بخش
						"ارسال از طریق فرم" این شناسه ها قابل انتخاب و سپس به
						سامانه سازمان اداره مالیات ارسال خواهند شد. پس لطفا در
						وارد کردن شرح ، کد شناسه کالا / خدمت و درصد ارزش افزوده
						آن دقت نمایید..
					</p>
				</div>
				<Button
					className="MAAdd"
					icon={<PlusIcon />}
					onClick={showDrawer}
				>
					افزودن شناسه
				</Button>
			</div>
			<NoticeBox
				title="شناسه کالا"
				descriptions="شناسه کالا چیست و چه امکاناتی در اختیار ما میگذارد"
				button="نمایش مقاله"
				video="https://moadianabzar.ir/wp-content/uploads/2024/01/شناسه-کالا.mp4"
				modalDescription={`<p>شناسه کالا به شما این امکان را میدهد تا کالا های خود و یا خدماتی که مشتریان خود ارائه میدهید را تعریف کنید. تا در هنگام ارسال فاکتور به سازمان مالیاتی کشور دیگر نیاز نباشه آنها را وارد کنید و تنها با انتخاب محصولات و یا خدمات از پیش تعریف شده آن ها را به صورت حساب خود اضافه کنید.</p>
<h2>اضافه کردن محصول ، خدمات جدید</h2>
<p>برای اضافه کردن محصول ، خدمات جدید کافیه از طریق منو شناسه کالا دکمه افزودن شناسه را بزنید. سپس شما یک فرم با 3 فیلد اصلی و اجباری روبرو خواهید شد که شامل موار زیر است</p>
<ul>
    <li>نام محصول</li>
    <li>شناسه مالیاتی</li>
    <li>مالیات بر ارزش افزوده</li>
</ul>
<p>بعد از پر کردن مقادیر بالا روی دکمه ذخیره سازی کلیک کنید تا محصول شما به داشبورد اضافه شود.</p>`}
			/>
			<br />
			{productIsLoading ? (
				<Loading loading="true" />
			) : (
				<div
					className={
						products.length > 0
							? 'productContent dashboardMainContent'
							: 'productContent dashboardMainContent isEmpty'
					}
				>
					{products.length > 0 ? (
						products.map((product, index) => (
							<div key={index} className="singleWidget">
								<div className="singleWidgetRight">
									<span>{product.taxRate}%</span>
								</div>
								<div className="singleWidgetLeft">
									<div className="singleWidgetTitle">
										{product.name}
									</div>
									<div className="singleWidgetSubtitle">
										شناسه مالیاتی: {product.id}
									</div>
									<Button
										className="MARemove"
										onClick={() =>
											removeSingleWidget(product.id)
										}
									>
										<DeleteIcon />
									</Button>
								</div>
							</div>
						))
					) : (
						<Empty description={false} />
					)}
				</div>
			)}

			<ProductsDrawer
				open={open}
				setOpen={setOpen}
				messageApi={messageApi}
				isLoading={productIsLoading}
			/>
		</div>
	);
}

export const ProductsDrawer = (props) => {
	const dispatch = useDispatch();
	const { open, setOpen, messageApi, isLoading } = props;
	const [form] = Form.useForm();
	const onClose = () => {
		setOpen(false);
	};
	const onFormSave = async () => {
		const obj = form.getFieldsValue();
		form.validateFields()
			.then(async (values) => {
				console.log(values);
				const addP = await dispatch(saveProduct(obj));
				if (addP.payload.status === 200) {
					dispatch(addProduct(obj));
					form.resetFields();
					messageApi.success(addP.payload.response);
					setOpen(false);
				} else {
					messageApi.error(addP.payload.response);
				}
			})
			.catch((errorInfo) => {
				console.log(errorInfo.errorFields);
			});
	};
	return (
		<Drawer
			title=" "
			placement="left"
			maskClosable={false}
			closable={false}
			onClose={onClose}
			open={open}
			key="left"
			extra={
				<React.Fragment>
					<Button
						className="MAButton MASecondry MAClose"
						type="primary"
						onClick={() => setOpen(false)}
					>
						<CloseIcon />
					</Button>
					<Button
						className="MAButton"
						onClick={onFormSave}
						type="primary"
						disabled={isLoading}
						loading={isLoading}
					>
						ذخیره
					</Button>
				</React.Fragment>
			}
		>
			{isLoading ? (
				<Loading loading="true" />
			) : (
				<Form name="dynamic_form_nest_item" form={form}>
					<Form.Item
						name="name"
						hasFeedback
						rules={[
							{
								required: true,
								message: '',
							},
						]}
					>
						<Input size="large" placeholder="نام محصول" />
					</Form.Item>
					<Form.Item
						name="id"
						hasFeedback
						rules={[
							{
								pattern: /[0-9]/,
								message: 'لطفا فقط عدد به انگلیسی وارد کنید',
							},
							{
								required: true,
								message: '',
							},
						]}
					>
						<Input size="large" placeholder="شناسه مالیاتی" />
					</Form.Item>
					<Form.Item
						name="taxRate"
						hasFeedback
						rules={[
							{
								pattern: /[0-9]/,
								message: 'لطفا فقط عدد به انگلیسی وارد کنید',
							},
							{
								required: true,
								message: '',
							},
						]}
					>
						<Input
							size="large"
							max="99"
							maxLength="2"
							placeholder="مالیات بر ارزش افزوده"
						/>
					</Form.Item>
				</Form>
			)}
		</Drawer>
	);
};

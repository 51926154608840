import axios from 'axios'
import URL from '../config.json'

const restURL = URL.homeURL + URL.restURL
const API_URL = restURL + `${URL.allData}`

const getAllData = async () => {
	const response = await axios.get(API_URL)
	console.log(response.data)
	return response.data
}

const allDataService = { getAllData }

export default allDataService

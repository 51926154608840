import axios from 'axios'
import URL from '../config.json'

const restURL = URL.homeURL + URL.restURL
const API_URL = restURL + `${URL.companiesURL}`
const LICENSES_API_URL = restURL + `${URL.licensesURL}`

const getCompanies = async () => {
	const response = await axios.get(API_URL)
	return response.data
}

const getLicenses = async (email) => {
	const response = await axios.get(LICENSES_API_URL, {
		params: { email: email }
	})
	return response.data
}

const saveCompany = async (object) => {
	const response = await axios.post(API_URL, object)
	return response.data
}

const deleteCompany = async (company_id) => {
	const response = await axios.delete(API_URL, {
		data: { company_id: company_id }
	})
	return response.data
}

const companiesService = {
	getCompanies,
	getLicenses,
	saveCompany,
	deleteCompany
}

export default companiesService

import React from 'react'
import RightMenu from './RightMenu'
import '../../styles/dashboard.scss'
import { isBrowser } from 'react-device-detect'
import { Row, Col } from 'antd'
export default function DashboardLayout(props) {
	const { children } = props
	let url = new URL(window.location.href)
	let after_slash = url.hash
	const active_url = () => {
		if (window.location.href.indexOf('single') > -1) return '/sent'
		switch (after_slash) {
			case '#/sendWithForm':
				return '/sendWithForm'
			case '#/sent':
				return '/sent'
			case '#/companies':
				return '/companies'
			case '#/customers':
				return '/customers'
			case '#/products':
				return '/products'
			case '#/users':
				return '/users'
			case '#/licenses':
				return '/licenses'
			case '#/payments':
				return '/payments'
			case '#/settings':
				return '/settings'
			case '#/sentSandbox':
				return '/sentSandbox'
			default:
				return '/'
		}
	}
	return (
		<div className='dashboardWrap'>
			<div className='mainContainer'>
				<div
					className={
						isBrowser ? 'dashboardContent' : 'dashboardContent inMobile'
					}>
					<Row>
						<Col xl={6} md={32}>
							<RightMenu active={active_url()} />
						</Col>
						<Col xl={18} md={32}>
							<div className='dahboardContentWrap'>{children}</div>
						</Col>
					</Row>
				</div>
			</div>
		</div>
	)
}

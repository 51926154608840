import { Modal } from 'antd'
import { ReactComponent as WarningIcon } from '../../images/warning-icon.svg'
import { ReactComponent as InfoIcon } from '../../images/info-icon.svg'

const { confirm } = Modal

export default function InfoModal(props) {
	const { icon, content } = props
	return confirm({
		title: ' ',
		className: 'MAConfirm MAInfo',
		width: '484px',
		icon: icon,
		content: content,
		okText: '',
		cancelText: 'بستن',
		okButtonProps: {
			style: { display: 'none' }
		},
		centered: true,
		cancelButtonProps: {
			className: 'MAButton MASecondry'
		},
		onCancel() {}
	})
}

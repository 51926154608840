import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import customersService from './Service'

const initialState = {
	customerIsLoading: false,
	isSuccess: false,
	isOpen: false,
	isEditing: false,
	customerID: false,
	currentFormData: {},
	customers: [],
	message: []
}

export const getCustomers = createAsyncThunk(
	'customers/getCustomers',
	async (thunkAPI) => {
		try {
			return await customersService.getCustomers()
		} catch (error) {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString()
			return thunkAPI.rejectWithValue(message)
		}
	}
)

export const saveCustomer = createAsyncThunk(
	'customers/saveCustomer',
	async (object, thunkAPI) => {
		try {
			return await customersService.saveCustomer(object)
		} catch (error) {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString()
			return thunkAPI.rejectWithValue(message)
		}
	}
)

export const deleteCustomer = createAsyncThunk(
	'customers/deleteCustomer',
	async (cod_eqtesadi, thunkAPI) => {
		try {
			return await customersService.deleteCustomer(cod_eqtesadi)
		} catch (error) {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString()
			return thunkAPI.rejectWithValue(message)
		}
	}
)

export const customersSlice = createSlice({
	name: 'customers',
	initialState,
	reducers: {
		showDrawer: (state, action) => {
			const { open, edit, customerID } = action.payload
			state.isOpen = open
			state.isEditing = edit
			state.customerID = customerID
		},
		formData: (state, action) => {
			state.currentFormData = action.payload
		},
		addCustomer: (state, action) => {
			state.customers = JSON.parse(action.payload)
		},
		removeCustomer: (state, action) => {
			state.customers = Object.values(state.customers).filter(
				(customer) => customer.customer_id !== action.payload
			)
		},
		allCustomers: (state, action) => {
			state.customers = JSON.parse(action.payload.customers)
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getCustomers.pending, (state) => {
				state.customerIsLoading = true
				state.isSuccess = false
			})
			.addCase(getCustomers.fulfilled, (state, action) => {
				state.customerIsLoading = false
				state.customers =
					action.payload.status === 200
						? JSON.parse(action.payload.response.customers)
						: []
				state.message = action.payload
				state.isSuccess = true
			})
			.addCase(getCustomers.rejected, (state, action) => {
				state.customerIsLoading = false
				state.isError = true
			})
			.addCase(saveCustomer.pending, (state) => {
				state.customerIsLoading = true
				state.isSuccess = false
			})
			.addCase(saveCustomer.fulfilled, (state, action) => {
				state.customerIsLoading = false
				state.message = action.payload
				state.isSuccess = true
			})
			.addCase(saveCustomer.rejected, (state, action) => {
				state.customerIsLoading = false
				state.isError = true
			})
			.addCase(deleteCustomer.pending, (state) => {
				state.customerIsLoading = true
				state.isSuccess = false
			})
			.addCase(deleteCustomer.fulfilled, (state, action) => {
				state.customerIsLoading = false
				state.message = action.payload
				state.isSuccess = true
			})
			.addCase(deleteCustomer.rejected, (state, action) => {
				state.customerIsLoading = false
				state.isError = true
			})
	}
})

export const {
	showDrawer,
	formData,
	addCustomer,
	removeCustomer,
	editCustomer,
	allCustomers
} = customersSlice.actions

export default customersSlice.reducer

import axios from 'axios'
import URL from '../config.json'
const restURL = URL.homeURL + URL.restURL
const API_URL = restURL + `${URL.authURL}`
const Logout_URL = restURL + `${URL.logOut}`
const sendLoginCode_URL = restURL + `${URL.sendLoginCode}`
const loginWithCode_URL = restURL + `${URL.loginWithCode}`

const checkLoginToken = async (token) => {
	const response = await axios.post(API_URL, token)
	return response.data
}

const LoginUser = async (userData) => {
	const response = await axios.post(API_URL, JSON.stringify(userData))
	console.log(response.data)
	return response.data
}
const LogoutUser = async () => {
	const response = await axios.get(Logout_URL)
	return response.data
}

const sendLoginCode = async (data) => {
	console.log(data)
	const response = await axios.post(sendLoginCode_URL, data)
	return response.data
}
const loginWithCode = async (data) => {
	console.log(data)
	const response = await axios.post(loginWithCode_URL, data)
	return response.data
}

const authService = {
	checkLoginToken,
	LoginUser,
	LogoutUser,
	sendLoginCode,
	loginWithCode
}

export default authService

import React, { useState } from 'react'
import { Row, Col, Button, Modal } from 'antd'
import '../../styles/noticebox.scss'
import parse from 'html-react-parser'

export default function NoticeBox(props) {
	const { title, descriptions, button, video, modalDescription } = props

	const [isModalOpen, setIsModalOpen] = useState(false)
	const showModal = () => {
		setIsModalOpen(true)
	}
	const handleCancel = () => {
		setIsModalOpen(false)
	}

	return (
		<>
			<Row>
				<Col span={24}>
					<div className='ma-notice-box'>
						{title ? <h3>{title}</h3> : ''}
						{descriptions ? <p>{descriptions}</p> : ''}
						{button ? (
							<Button
								className='MAButton'
								type='primary'
								htmlType='submit'
								onClick={showModal}>
								{button}
							</Button>
						) : (
							''
						)}
					</div>
				</Col>
			</Row>
			<Modal
				title={title}
				open={isModalOpen}
				onCancel={handleCancel}
				destroyOnClose={true}
				footer=''
				style={{ direction: 'rtl' }}>
				{modalDescription ? parse(modalDescription) : ''}

				{video ? <video src={video} width='472' controls></video> : ''}
			</Modal>
		</>
	)
}

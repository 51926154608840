import axios from 'axios'
import URL from '../config.json'

const restURL = URL.homeURL + URL.restURL
const API_URL = restURL + `${URL.licensesURL}`

// get user licesnes
const getUserLicenses = async (data) => {
	console.log(data)
	const response = await axios.get(API_URL, {
		params: { email: data.email, type: data.type }
	})
	return response.data
}

const userLicenses = {
	getUserLicenses
}

export default userLicenses

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import productsService from './Service'

const initialState = {
	products: [],
	productIsLoading: false,
	isSuccess: false
}

export const getProducts = createAsyncThunk('products/getProducts', async (thunkAPI) => {
	try {
		return await productsService.getProducts()
	} catch (error) {
		const message =
			(error.response && error.response.data && error.response.data.message) ||
			error.message ||
			error.toString()
		return thunkAPI.rejectWithValue(message)
	}
})

export const saveProduct = createAsyncThunk(
	'products/saveProduct',
	async (product, thunkAPI) => {
		try {
			return await productsService.saveProduct(product)
		} catch (error) {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString()
			return thunkAPI.rejectWithValue(message)
		}
	}
)

export const deleteProduct = createAsyncThunk(
	'products/deleteProduct',
	async (id, thunkAPI) => {
		try {
			return await productsService.deleteProduct(id)
		} catch (error) {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString()
			return thunkAPI.rejectWithValue(message)
		}
	}
)

export const productsSlice = createSlice({
	name: 'products',
	initialState,
	reducers: {
		addProduct: (state, action) => {
			state.products = [...state.products, action.payload]
		},
		removeProduct: (state, action) => {
			state.products = state.products.filter((item) => item.id !== action.payload)
		},
		allProducts: (state, action) => {
			state.products = JSON.parse(action.payload.products)
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getProducts.pending, (state) => {
				state.productIsLoading = true
				state.isSuccess = false
			})
			.addCase(getProducts.fulfilled, (state, action) => {
				state.productIsLoading = false
				state.products =
					action.payload.status === 200
						? JSON.parse(action.payload.response.products)
						: []
				state.isSuccess = true
			})
			.addCase(getProducts.rejected, (state, action) => {
				console.log(action.payload)
				state.productIsLoading = false
				state.isError = true
			})
			.addCase(saveProduct.pending, (state) => {
				state.productIsLoading = true
				state.isSuccess = false
			})
			.addCase(saveProduct.fulfilled, (state, action) => {
				state.productIsLoading = false
				state.isSuccess = true
			})
			.addCase(saveProduct.rejected, (state, action) => {
				state.productIsLoading = false
				state.isError = true
			})
			.addCase(deleteProduct.pending, (state) => {
				state.productIsLoading = true
				state.isSuccess = false
			})
			.addCase(deleteProduct.fulfilled, (state, action) => {
				state.productIsLoading = false
				state.isSuccess = true
			})
			.addCase(deleteProduct.rejected, (state, action) => {
				state.productIsLoading = false
				state.isError = true
			})
	}
})

export const { addProduct, removeProduct, allProducts } = productsSlice.actions

export default productsSlice.reducer

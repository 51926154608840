import axios from 'axios'
import URL from '../config.json'

const restURL = URL.homeURL + URL.restURL
const API_URL = restURL + `${URL.extraUser}`
const SECRETKEY_URL = restURL + `${URL.GetSecretKey}`

// get users
const getUsers = async () => {
	const response = await axios.get(API_URL)
	return response.data
}

// save users
const saveUser = async (user) => {
	const response = await axios.post(API_URL, user)
	console.log(response.data)
	return response.data
}

// delete users
const deleteUser = async (PInfo) => {
	console.log(PInfo)
	const response = await axios.delete(API_URL, { data: PInfo })
	console.log(response.data)
	return response.data
}

const GetSecretKey = async () => {
	const request = await axios.get(SECRETKEY_URL)
	return request
}

const usersService = {
	getUsers,
	saveUser,
	deleteUser,
	GetSecretKey
}

export default usersService

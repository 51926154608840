import React, { useState, useEffect } from 'react';
import { Table, Button, Tag, Tooltip, notification, Popconfirm } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import {
	getBills,
	getInquiry,
	cancelBill,
} from '../../app/services/bills/Slice';
import moment from 'jalali-moment';
import { ReactComponent as InquiryIcon } from '../../images/inquiry-icon.svg';
import { ReactComponent as CancelIcon } from '../../images/cancel-icon.svg';
import { ReactComponent as ViewIcon } from '../../images/view-icon2.svg';
import { ReactComponent as PrintIcon } from '../../images/print-icon.svg';
import { ReactComponent as CompanyIcon } from '../../images/company-icon3.svg';
import { ReactComponent as ClientIcon } from '../../images/client-icon2.svg';
import { ReactComponent as DuplicateIcon } from '../../images/duplicate-icon2.svg';
import { Link } from 'react-router-dom';

import InfoModal from '../../features/InfoModal/InfoModal';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import '../../styles/sent.scss';
import NoticeBox from '../Docs/NoticeBox';

export default function Sent(props) {
	const { dbName } = props;
	const dispatch = useDispatch();
	const { billsIsLoading } = useSelector((state) => state.bills);
	const { customers } = useSelector((state) => state.customers);
	const { companies } = useSelector((state) => state.companies);
	const { settings } = useSelector((state) => state.settings);
	const { allDataIsLoading } = useSelector((state) => state.general);
	const [api, contextHolder] = notification.useNotification();
	const columns = [
		{
			title: 'ردیف',
			dataIndex: 'id',
			width: 80,
			align: 'center',
		},
		{
			title: 'شماره مالیاتی',
			dataIndex: 'irtaxid',
			ellipsis: true,
			width: 190,
			render: (text) =>
				text ? <Tooltip title={text}>{text}</Tooltip> : 'ذخیره شده',
			align: 'center',
		},
		{
			title: 'وضعیت',
			dataIndex: 'send_status',
			width: 100,
			align: 'center',
			render: (invoice_status) => {
				const color =
					invoice_status === '1'
						? 'green'
						: invoice_status === '-1'
						? 'magenta'
						: invoice_status === '-10'
						? 'cyan'
						: invoice_status === '-20'
						? 'red'
						: 'default';
				let text =
					invoice_status === '1'
						? 'ثبت شد '
						: invoice_status === '-1'
						? 'ابطال شد'
						: invoice_status === '0'
						? 'ارسال شد'
						: invoice_status === '-10'
						? 'در حال بررسی'
						: '';
				return (
					<Tag color={color} key={invoice_status}>
						{text}
					</Tag>
				);
			},
		},
		{
			title: 'تاریخ',
			dataIndex: 'submit_date',
			width: 100,
			render: (text) =>
				moment(text, 'YYYY/MM/DD').locale('fa').format('DD MMMM YYYY'),
			align: 'center',
		},
		{
			title: 'خریدار',
			dataIndex: 'customer_id',
			width: 80,
			render: (text) => (
				<div className="tableButtons">
					<Tooltip title="مشتری / خریدار">
						<Button
							className="svgButtonTable"
							onClick={() => displayCustomer(text)}
						>
							<ClientIcon />
						</Button>
					</Tooltip>
				</div>
			),
			align: 'center',
		},
		{
			title: 'فروشنده',
			dataIndex: 'company_id',
			ellipsis: true,
			width: 80,
			render: (text) => (
				<div className="tableButtons">
					<Tooltip title="شرکت / فروشنده">
						<Button
							className="svgButtonTable"
							onClick={() => displayCompany(text)}
						>
							<CompanyIcon />
						</Button>
					</Tooltip>
				</div>
			),
			align: 'center',
		},
		{
			title: '',
			dataIndex: 'actions',
			ellipsis: true,
			render: (_, record) => (
				<div className="tableButtons">
					{/* <Tooltip title='شماره ارجاع صورتحساب مالیاتی'>
						<Button
							className='svgButtonTable'
							onClick={() => console.log('refence')}>
							<ReferenceIcon />
						</Button>
					</Tooltip> */}
					{record.ref_number && record.send_status === '0' ? (
						<Tooltip title="استعلام وضعیت">
							<Button
								className="svgButtonTable"
								onClick={() =>
									getInquiryFunction({
										ref_number: record.ref_number,
										sandbox:
											record.company_id === 'sandbox'
												? 1
												: 0,
										id: record.id,
										company:
											record.company_id === 'sandbox'
												? {
														codeEghtesadi:
															settings.codeEghtesadi,
														shenaseYekta:
															settings.shenaseYekta,
														privateCode:
															settings.privateCode,
												  }
												: companies[record.company_id],
									})
								}
							>
								<InquiryIcon />
							</Button>
						</Tooltip>
					) : null}
					{record.irtaxid && record.send_status === '1' ? (
						<Tooltip title="ابطال صورتحساب">
							<Popconfirm
								title="ابطال صورتحساب"
								description="آیا از انجام این عملیات اطمینان دارید؟"
								onConfirm={() =>
									cancelBillFunction({
										id: record.id,
										submit_date: new Date(
											record.submit_date
										).getTime(),
										sandbox:
											record.company_id === 'sandbox'
												? 1
												: 0,
										irtaxid: record.irtaxid,
										company:
											record.company_id === 'sandbox'
												? {
														codeEghtesadi:
															settings.codeEghtesadi,
														shenaseYekta:
															settings.shenaseYekta,
														privateCode:
															settings.privateCode,
												  }
												: companies[record.company_id],
									})
								}
								okText="بله"
								cancelText="خیر"
							>
								<Button className="svgButtonTable">
									<CancelIcon />
								</Button>
							</Popconfirm>
						</Tooltip>
					) : null}
					{console.log(record.company_id)}
					<Tooltip title="نمایش / اصلاح صورتحساب">
						<Link
							to={{
								pathname: '/single',
								search:
									'?singleId=' +
									record.id +
									`${
										record.company_id === 'sandbox'
											? '&sandbox=1'
											: ''
									}`,
								state: { singleId: record.id },
							}}
						>
							<Button className="svgButtonTable">
								<ViewIcon />
							</Button>
						</Link>
					</Tooltip>
					{record.irtaxid && record.send_status === '1' ? (
						<Tooltip title="چاپ فاکتور">
							<Button
								className="svgButtonTable"
								onClick={() =>
									InfoModal({
										icon: <PrintIcon />,
										content: (
											<div>
												<strong>
													{record.ref_number}
												</strong>
												<PrintIcon />
											</div>
										),
									})
								}
							>
								<PrintIcon />
							</Button>
						</Tooltip>
					) : null}

					{/* <Tooltip title='ایجاد نسخه کپی'>
						<Button
							className='svgButtonTable'
							onClick={() =>
								InfoModal({
									icon: <DuplicateIcon />,
									content: (
										<div>
											<strong>{record.ref_number}</strong>
											<DuplicateIcon />
										</div>
									)
								})
							}>
							<DuplicateIcon />
						</Button>
					</Tooltip> */}
				</div>
			),
			align: 'center',
		},
	];
	console.log(allDataIsLoading);
	const CompanyTableContent = (props) => {
		const { details } = props;
		return details ? (
			<div className="customerDetails">
				<div className="customerName">
					<span> نام :</span>
					{details.name ? details.name : null}
				</div>
				<div className="customerCodeEghtesadi">
					<span>کد اقتصادی:</span>
					{details.cod_eqtesadi ? details.cod_eqtesadi : null}
				</div>
				<div className="customerCodeYekta">
					<span>کد یکتا: </span>
					{details.cod_yekta ? details.cod_yekta : null}
				</div>
			</div>
		) : null;
	};
	const displayCompany = (companyId) => {
		InfoModal({
			icon: <CompanyIcon />,
			content: (
				<div>
					<CompanyTableContent details={companies[companyId]} />
				</div>
			),
		});
	};

	const CustomerTableContent = (props) => {
		const { details } = props;
		return (
			<div className="customerDetails">
				<div className="customerName">
					<span>نام و نام خانوادگی:</span>
					{details.fullname}
				</div>
				<div className="customerCodeMelli">
					<span>کد ملی:</span>
					{details.cod_meli}
				</div>
				<div className="customerCodeEghtesadi">
					<span>کد اقتصادی:</span>
					{details.cod_eqtesadi}
				</div>
				<div className="customerCodePosti">
					<span>کد پستی: </span>
					{details.postal_code}
				</div>
			</div>
		);
	};
	const displayCustomer = (customerId) => {
		console.log();
		if (customers[customerId]) {
			InfoModal({
				icon: <ClientIcon />,
				content: (
					<div>
						<CustomerTableContent details={customers[customerId]} />
					</div>
				),
			});
		}
	};

	const getInquiryFunction = async (data) => {
		const result = await dispatch(getInquiry(data));
		console.log(result);
		const message = result.payload.response;

		if (result.payload.status === 200) {
			api.success({
				message: 'وضعیت',
				description: message,
				placement: 'top',
			});
		} else if (result.payload.status === 300) {
			api.info({
				message: 'وضعیت',
				description: message,
				placement: 'top',
			});
		} else if (result.payload.status === 403) {
			api.error({
				message: 'وضعیت',
				description: message,
				placement: 'top',
			});
		} else if (result.payload.status === 500) {
			api.warning({
				message: 'وضعیت',
				description: message,
				placement: 'top',
				duration: 0,
			});
		}
	};

	const cancelBillFunction = async (data) => {
		const result = await dispatch(cancelBill(data));
		console.log(result);
		const message = result.payload.response;

		if (result.payload.status === 200) {
			api.success({
				message: 'وضعیت',
				description: message,
				placement: 'top',
			});
		} else if (result.payload.status === 403) {
			api.error({
				message: 'وضعیت',
				description: message,
				placement: 'top',
			});
		}
	};

	const cancelNestedBillFunction = async (data) => {
		console.log(data);
	};

	const itemRender = (_, type, originalElement) => {
		if (type === 'prev') {
			return (
				<a>
					<RightOutlined />
				</a>
			);
		}

		if (type === 'next') {
			return (
				<a>
					<LeftOutlined />
				</a>
			);
		}
		return originalElement;
	};

	const [data, setData] = useState();
	const [database, setDatabase] = useState(dbName);
	const [tableParams, setTableParams] = useState({
		pagination: {
			current: 1,
			pageSize: 10,
			locale: { items_per_page: 'عدد در هر صفحه' },
			position: ['bottomCenter'],
			itemRender: itemRender,
		},
	});

	const handleTableChange = (pagination, filters, sorter) => {
		setTableParams({
			pagination,
			filters,
			...sorter,
		});

		// `dataSource` is useless since `pageSize` changed
		if (pagination.pageSize !== tableParams.pagination?.pageSize) {
			setData([]);
		}

		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0;
	};

	const fetchData = async () => {
		if (!allDataIsLoading) {
			const allData = await dispatch(getBills({ tableParams, database }));
			console.log(allData.payload);
			setData(allData.payload['result']);
			setTableParams({
				...tableParams,
				pagination: {
					...tableParams.pagination,
					position: tableParams.pagination.position,
					itemRender: itemRender,
					total: allData.payload['total'],
				},
			});
		}
	};

	const [selectedRowKeys, setSelectedRowKeys] = useState([]);

	const onSelectChange = (newSelectedRowKeys) => {
		console.log('selectedRowKeys changed: ', newSelectedRowKeys);
		setSelectedRowKeys(newSelectedRowKeys);
	};
	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
	};
	const hasSelected = selectedRowKeys.length > 0;

	useEffect(() => {
		fetchData();
	}, [JSON.stringify(tableParams), allDataIsLoading]);

	const expandedRowRender = (record) => {
		const columns = [
			{
				title: 'وضعیت',
				dataIndex: 'send_status',
				align: 'center',
				render: (invoice_status) => {
					console.log(invoice_status);
					const color =
						invoice_status === '1'
							? 'green'
							: invoice_status === '-1'
							? 'magenta'
							: invoice_status === '-10'
							? 'cyan'
							: invoice_status === '-20'
							? 'warning'
							: invoice_status === '-100'
							? 'volcano'
							: 'default';
					let text =
						invoice_status === '1'
							? 'اصلاحی ثبت شد'
							: invoice_status === '-1'
							? 'اصلاحی ابطال شد'
							: invoice_status === '0'
							? 'ارسال شده'
							: invoice_status === '-10'
							? 'در حال بررسی'
							: invoice_status === '-100'
							? 'صورتحساب ابطالی'
							: '';
					return (
						<Tag color={color} key={invoice_status}>
							{text}
						</Tag>
					);
				},
			},
			{
				title: 'تاریخ ثبت',
				dataIndex: 'submit_date',
				render: (text) => {
					var dateFormat = new Date(text);
					const date =
						dateFormat.getFullYear() +
						'/' +
						(dateFormat.getMonth() + 1) +
						'/' +
						dateFormat.getDate();
					console.log(date);
					const faDate = moment(date, 'YYYY/MM/DD')
						.locale('fa')
						.format('DD MMMM YYYY');
					return faDate;
				},
				align: 'center',
			},
			{
				title: 'شماره مالیاتی',
				dataIndex: 'irtaxid',
				align: 'center',
			},
			{
				title: '',
				dataIndex: 'actions',
				ellipsis: true,
				render: (_, data) => (
					<div className="tableButtons">
						{/* <Tooltip title='شماره ارجاع صورتحساب مالیاتی'>
							<Button
								className='svgButtonTable'
								onClick={() => console.log('refence')}>
								<ReferenceIcon />
							</Button>
						</Tooltip> */}
						{data.send_status !== '-100' ? (
							<Tooltip title="نمایش / اصلاح صورتحساب">
								<Link
									to={{
										pathname: '/single',
										search:
											'?singleId=' +
											data.id +
											`${
												data.company_id === 'sandbox'
													? '&sandbox=1'
													: ''
											}`,
										state: {
											singleId: data.id,
											sandbox:
												data.company_id === 'sandbox'
													? 1
													: 0,
										},
									}}
								>
									<Button className="svgButtonTable">
										<ViewIcon />
									</Button>
								</Link>
							</Tooltip>
						) : null}

						{data.ref_number && data.send_status === '0' ? (
							<Tooltip title="استعلام وضعیت">
								<Button
									className="svgButtonTable"
									onClick={() =>
										getInquiryFunction({
											ref_number: data.ref_number,
											id: data.id,
											sandbox: data.sandbox,
											company:
												record.company_id === 'sandbox'
													? {
															codeEghtesadi:
																settings.codeEghtesadi,
															shenaseYekta:
																settings.shenaseYekta,
															privateCode:
																settings.privateCode,
													  }
													: companies[
															record.company_id
													  ],
										})
									}
								>
									<InquiryIcon />
								</Button>
							</Tooltip>
						) : null}
						{data.irtaxid && data.send_status === '1' ? (
							<Tooltip title="ابطال صورتحساب">
								<Popconfirm
									title="ابطال صورتحساب"
									description="آیا از انجام این عملیات اطمینان دارید؟"
									onConfirm={() =>
										cancelBillFunction({
											id: data.id,
											submit_date: new Date(
												data.submit_date
											).getTime(),
											sandbox:
												record.company_id === 'sandbox'
													? 1
													: 0,
											irtaxid: data.irtaxid,
											company:
												record.company_id === 'sandbox'
													? {
															codeEghtesadi:
																settings.codeEghtesadi,
															shenaseYekta:
																settings.shenaseYekta,
															privateCode:
																settings.privateCode,
													  }
													: companies[
															record.company_id
													  ],
										})
									}
									okText="بله"
									cancelText="خیر"
								>
									<Button className="svgButtonTable">
										<CancelIcon />
									</Button>
								</Popconfirm>
							</Tooltip>
						) : null}
					</div>
				),
				align: 'center',
			},
		];
		const data = JSON.parse(record.nested);
		const sortedKeys = Object.keys(data).sort((a, b) => b - a);
		const sortedObj = {};
		sortedKeys.forEach((key) => {
			sortedObj[key] = data[key];
		});
		const entries = Object.values(sortedObj);
		return record.nested && record.nested !== 0 ? (
			<Table
				rowKey={(record) => record.submit_date}
				rowSelection={rowSelection}
				columns={columns}
				dataSource={entries}
				pagination={false}
			/>
		) : null;
	};

	return (
		<div className="sentWrap">
			{contextHolder}
			<div className="dashboardTitle">
				<div>
					<h4>صورتحساب ها</h4>
					<p>
						در این بخش شما میتوانید صورتحساب های خود را مشاهده کنید.
						اگر صورتحساب شما ارسال یا ذخیره شده باشد، در این بخش
						نمایش داده میشود. صورتحساب های ارسالی به محیط ازمایشی
						(sandbox) در بخش مربوط به خود لیست خواهد شد.
						<br />
						<span className="helpSection">
							<Tag color="green">ثبت شد</Tag>
							<span>
								صورتحساب شما با موفقیت در سامانه اداره مالیات
								ثبت شده است. برای بررسی بیشتر میتوانید به
								کارپوشه خود مراجعه نمایید.
							</span>
						</span>
						<span className="helpSection">
							<Tag color="magenta">ابطال شد</Tag>
							<span>صورتحساب با موفقیت ابطال شده است</span>
						</span>
						<span className="helpSection">
							<Tag color="cyan">در حال بررسی</Tag>
							<span>
								این وضعیت همان وضعیت Pending است و لطفا تا زمان
								مشخص شدن وضعیت، هیچ فعالیتی روی صورتحساب انجام
								ندهید
							</span>
						</span>
						<span className="helpSection">
							<Tag color="default">ارسال شد</Tag>
							<span>
								صورتحساب با سامانه ارسال شده است. برای اطلاعات
								از وضعیت ثبت، فقط استعلام بگیرید
							</span>
						</span>
					</p>
				</div>
			</div>
			<div className="productContent dashboardMainContent">
				<Table
					columns={columns}
					rowKey={(record) => record.id}
					rowSelection={rowSelection}
					dataSource={data}
					pagination={tableParams.pagination}
					loading={billsIsLoading}
					onChange={handleTableChange}
					expandable={{
						expandedRowRender,
						rowExpandable: (record) =>
							record.nested && record.nested !== '0',
					}}
				/>
			</div>
		</div>
	);
}

import { configureStore, combineReducers } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'
import thunk from 'redux-thunk'
import authReducer from './services/auth/Slice'
import productsReducer from './services/products/Slice'
import companiesReducer from './services/companies/Slice'
import customersReducer from './services/customers/Slice'
import usersReducer from './services/extraUsers/Slice'
import licenseReducer from './services/licenses/Slice'
import billReducer from './services/bills/Slice'
import settingReducer from './services/settings/Slice'
import generalReducer from './services/general/Slice'
import internalNotificationsSliceReducer from './services/internalNotifications/Slice'

const persistConfig = {
	key: 'root',
	storage,
	whitelist: [
		'user',
		'products',
		'companies',
		'customers',
		'users',
		'licenses',
		'internalNotifications',
		'bills',
		'sandboxAnalytics',
		'settings',
		'general'
	]
}

const rootReducer = combineReducers({
	user: authReducer,
	users: usersReducer,
	products: productsReducer,
	companies: companiesReducer,
	customers: customersReducer,
	licenses: licenseReducer,
	internalNotifications: internalNotificationsSliceReducer,
	bills: billReducer,
	settings: settingReducer,
	general: generalReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
	reducer: persistedReducer,
	middleware: [thunk]
})
export const persistor = persistStore(store)

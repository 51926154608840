import React, { useEffect, useState, useLayoutEffect } from 'react'
import '../../styles/login.scss'
import { Button, Form, Input, message, Radio, Spin } from 'antd'
import { LoginUser, sendLoginCode, loginWithCode } from '../../app/services/auth/Slice'
import { useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { ReactComponent as Icon01 } from '../../images/icon01.svg'
import { ReactComponent as HeartIcon } from '../../images/heart-icon.svg'
import { ReactComponent as Refresh } from '../../images/refresh.svg'
import { useSelector } from 'react-redux'
import Loading from '../../features/Loading/Loading'
import {
	loadCaptchaEnginge,
	LoadCanvasTemplate,
	LoadCanvasTemplateNoReload,
	validateCaptcha
} from 'react-simple-captcha'
import {
	phoneNumberValidator,
	phoneNumberNormalizer,
	digitsEnToFa
} from '@persian-tools/persian-tools'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
export function LoginWithUserPass(props) {
	const { onFinishProps, onFinishFailedProps } = props

	useEffect(() => {
		loadCaptchaEnginge(6, '#f5f5f5', '#15121D', 'numbers')
	}, [])
	return (
		<Form
			className='loginForm'
			name='basic'
			wrapperCol={{
				span: 24
			}}
			style={{
				maxWidth: 600
			}}
			initialValues={{
				remember: true
			}}
			onFinish={onFinishProps}
			onFinishFailed={onFinishFailedProps}
			autoComplete='off'>
			<Form.Item
				name='username'
				rules={[
					{
						required: true,
						message: 'لطفا پست الکترونیک خود را وارد کنید'
					}
				]}>
				<Input placeholder='پست الکترونیک' />
			</Form.Item>

			<Form.Item
				name='password'
				rules={[
					{
						required: true,
						message: 'کلمه عبور خود را وارد کنید'
					}
				]}>
				<Input.Password placeholder='کلمه عبور' />
			</Form.Item>
			<Form.Item
				className='captcha'
				name='captcha'
				rules={[
					{
						required: true,
						message: 'کد امنیتی را وارد کنید'
					}
				]}>
				<div className='captchaWrap'>
					<Input placeholder='عبارت را وارد کنید' />
					<LoadCanvasTemplate reloadText='کد جدید' />
				</div>
			</Form.Item>
			<Form.Item
				wrapperCol={{
					span: 24
				}}>
				<Button type='primary' htmlType='submit' className='MAButton'>
					ورود
				</Button>
			</Form.Item>
		</Form>
	)
}

export function LoginWithCode(props) {
	const { onFinishProps, onFinishFailedProps, messageApi, setloginMethod } = props
	const [form] = Form.useForm()
	const [confirmCode, setConfirmCode] = useState(false)
	const [loading, setLoading] = useState(false)
	const [mobile, setMobile] = useState(null)
	const [time, setTime] = useState(null)
	const dispatch = useDispatch()

	const sendLoginCodeFunc = async (values) => {
		if (validateCaptcha(values.captcha) !== true) {
			messageApi.error('کد امنیتی اشتباه است')
			loadCaptchaEnginge(6, '#f5f5f5', '#15121D', 'numbers')
			return
		}
		setLoading(true)
		console.log(values)
		const response = await dispatch(sendLoginCode({ mobile: values.username }))
		console.log(response)
		if (response.payload.status === 403) {
			setLoading(false)
			messageApi.error(response.payload.response)
		} else if (response.payload.status === 200) {
			setLoading(false)
			setConfirmCode(true)
			const data = JSON.parse(response.payload.response)
			console.log(data)
			setMobile(data['mobile'])
			setTime(data['time'])
		}
	}

	const loginWithCodeFunc = async (values) => {
		setLoading(true)
		const response = await dispatch(
			loginWithCode({ mobile: mobile, code: values.code })
		)
		if (response.payload.status === 403) {
			setLoading(false)
			messageApi.error(response.payload.response)
		} else if (response.payload.status === 200) {
			setLoading(false)
		}
	}
	useLayoutEffect(() => {
		loadCaptchaEnginge(6, '#f5f5f5', '#15121D', 'numbers')
	}, [])
	return !confirmCode ? (
		<Spin spinning={loading}>
			<Form
				form={form}
				className='loginForm'
				name='basic'
				wrapperCol={{
					span: 24
				}}
				style={{
					maxWidth: 600
				}}
				onFinish={sendLoginCodeFunc}
				onFinishFailed={onFinishFailedProps}
				autoComplete='off'>
				<Form.Item
					name='username'
					rules={[
						{
							required: true,
							message: 'لطفا شماره موبایل خود را وارد کنید'
						}
					]}>
					<Input placeholder='شماره موبایل' />
				</Form.Item>

				<Form.Item
					className='captcha'
					name='captcha'
					rules={[
						{
							required: true,
							message: 'کد امنیتی را وارد کنید'
						}
					]}>
					<div className='captchaWrap'>
						<Input placeholder='عبارت را وارد کنید' />
						<LoadCanvasTemplate reloadText='کد جدید' />
					</div>
				</Form.Item>

				<Form.Item
					wrapperCol={{
						span: 24
					}}>
					<Button type='primary' className='MAButton' htmlType='submit'>
						ارسال کد یکبار مصرف
					</Button>
				</Form.Item>
			</Form>
		</Spin>
	) : (
		<Form
			className='loginForm'
			name='basic'
			wrapperCol={{
				span: 24
			}}
			style={{
				maxWidth: 600
			}}
			onFinish={loginWithCodeFunc}
			onFinishFailed={onFinishFailedProps}
			autoComplete='off'>
			<Form.Item
				name='code'
				rules={[
					{
						required: true,
						message: 'کد را وارد کنید'
					}
				]}>
				<Input placeholder='کد ارسال شده به موبایل خود را وارد کنید' />
			</Form.Item>
			<div className='circleTimerWrap'>
				<CountdownCircleTimer
					size={35}
					strokeWidth={1}
					isPlaying={true}
					duration={120}
					colors={['#487bff', '#ffab48']}
					colorsTime={[120, 0]}
					onComplete={() => {
						// do your stuff here
						setConfirmCode(false)
						setloginMethod('userpass')
						messageApi.error(
							'کد شما منقضی شده است. لطفا مجدد کد را دریافت کنید'
						)
					}}>
					{({ remainingTime }) => {
						return (
							<div className='MAcircleTimer'>
								{digitsEnToFa(remainingTime)}
							</div>
						)
					}}
				</CountdownCircleTimer>
			</div>
			<Form.Item
				wrapperCol={{
					span: 24
				}}>
				<Button type='primary' className='MAButton' htmlType='submit'>
					ورود
				</Button>
			</Form.Item>
		</Form>
	)
}

function useQuery() {
	const { search } = useLocation()
	return React.useMemo(() => new URLSearchParams(search), [search])
}

export default function Login() {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	let query = useQuery()
	const singleId = query.get('singleId')
	const [messageApi, contextHolder] = message.useMessage()
	const { isLoading } = useSelector((state) => state.user)
	const loginMethodOptions = [
		{ label: 'نام کاربری و رمز عبور', value: 'userpass' },
		{ label: 'کد یکبار مصرف', value: 'sms' }
	]
	const [loginMethod, setloginMethod] = useState('sms')

	const onFinish = async (values) => {
		if (validateCaptcha(values.captcha) !== true) {
			messageApi.error('کد امنیتی اشتباه است')
			loadCaptchaEnginge(6, '#f5f5f5', '#15121D', 'numbers')
			return
		}

		console.log('Success:', values)
		const loginInfo = {}
		loginInfo['username'] = values.username
		loginInfo['password'] = values.password
		const loginInformation = await dispatch(LoginUser(loginInfo))
		console.log(loginInformation)
		if (
			loginInformation.payload.status === 403 ||
			loginInformation.payload === 'Network Error'
		) {
			if (loginInformation.payload.status === 403)
				messageApi.error(loginInformation.payload.response)
			if (loginInformation.payload === 'Network Error')
				messageApi.error('ارتباط شما با وب سایت برقرار نشد')
		} else {
			let url = new URL(window.location.href)
			let after_slash = url.hash
			if (window.location.href.indexOf('single') > -1) {
				navigate('/single?singleId=' + singleId)
				return
			}
			switch (after_slash) {
				case '#/sendWithForm':
					navigate('/sendWithForm', { replace: true })
					break
				case '#/sent':
					navigate('/sent', { replace: true })
					break
				case '#/companies':
					navigate('/companies', { replace: true })
					break
				case '#/products':
					navigate('/products', { replace: true })
					break
				case '#/users':
					navigate('/users', { replace: true })
					break
				case '#/licenses':
					navigate('/licenses', { replace: true })
					break
				case '#/payments':
					navigate('/payments', { replace: true })
					break
				case '#/customers':
					navigate('/customers', { replace: true })
					break
				case '#/settings':
					navigate('/settings', { replace: true })
					break
				case '#/sentSandbox':
					navigate('/sentSandbox', { replace: true })
					break
				default:
					navigate('/', { replace: true })
					break
			}
		}
	}
	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo)
	}
	const loginMethodOnChange = ({ target: { value } }) => {
		setloginMethod(value)
	}

	return (
		<div className='mainContainer'>
			{contextHolder}
			{isLoading ? (
				<Loading loading='true' />
			) : (
				<div className='loginWrap'>
					<div className='loginWrapDetails'>
						<div className='loginDetailsContent'>
							<Icon01 />
							<h4>
								سامانه واسط مؤدیان مالیاتی
								<HeartIcon />
							</h4>
							<p>
								با ثبت نام در سامانه ثبت املاک و اسناد ایران و خرید اشتراک
								می توانید از تمام ویژگی این سامانه استفاده کنید و ملک یا
								زمین خود را برای فروش به ثبت برسانید
							</p>
						</div>
					</div>
					<div className='loginWrapForm'>
						<Radio.Group
							options={loginMethodOptions}
							onChange={loginMethodOnChange}
							value={loginMethod}
							optionType='button'
							size='large'
						/>
						<br />
						<br />
						{loginMethod === 'userpass' ? (
							<LoginWithUserPass
								onFinishProps={onFinish}
								onFinishFailedProps={onFinishFailed}
							/>
						) : (
							<LoginWithCode
								loginMethod={loginMethod}
								setloginMethod={setloginMethod}
								onFinishProps={onFinish}
								onFinishFailedProps={onFinishFailed}
								messageApi={messageApi}
							/>
						)}
					</div>
				</div>
			)}
		</div>
	)
}

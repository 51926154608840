import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import billsService from './Service'

const initialState = {
	totalBills: 0,
	billsIsLoading: false,
	message: [],
	bills: null,
	billsAnalyticsData: null,
	sandboxAnalyticsData: null,
	nextBills: null,
	prevBills: null,
	singleBill: null,
	singleBillStatus: null,
	singleIrtaxid: null,
	currentBillToSend: null
}

export const sendBill = createAsyncThunk('bills/sendBill', async (formData, thunkAPI) => {
	try {
		return await billsService.sendBill(formData)
	} catch (error) {
		const message =
			(error.response && error.response.data && error.response.data.message) ||
			error.message ||
			error.toString()
		return thunkAPI.rejectWithValue(message)
	}
})

export const getBills = createAsyncThunk('bills/getBills', async (params, thunkAPI) => {
	try {
		return await billsService.getBills(params)
	} catch (error) {
		const message =
			(error.response && error.response.data && error.response.data.message) ||
			error.message ||
			error.toString()
		return thunkAPI.rejectWithValue(message)
	}
})

export const getInquiry = createAsyncThunk(
	'bills/getInquiry',
	async (params, thunkAPI) => {
		try {
			return await billsService.getInquiry(params)
		} catch (error) {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString()
			return thunkAPI.rejectWithValue(message)
		}
	}
)

export const getTotalBills = createAsyncThunk(
	'bills/getTotalBills',
	async (params, thunkAPI) => {
		try {
			return await billsService.getTotalBills(params)
		} catch (error) {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString()
			return thunkAPI.rejectWithValue(message)
		}
	}
)
export const cancelBill = createAsyncThunk(
	'bills/cancelBill',
	async (params, thunkAPI) => {
		try {
			return await billsService.cancelBill(params)
		} catch (error) {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString()
			return thunkAPI.rejectWithValue(message)
		}
	}
)
export const sendTwoStepFormCode = createAsyncThunk(
	'bills/sendTwoStepFormCode',
	async (params, thunkAPI) => {
		try {
			return await billsService.sendTwoStepFormCode(params)
		} catch (error) {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString()
			return thunkAPI.rejectWithValue(message)
		}
	}
)
export const getBill = createAsyncThunk('bills/getBill', async (params, thunkAPI) => {
	try {
		return await billsService.getBill(params)
	} catch (error) {
		const message =
			(error.response && error.response.data && error.response.data.message) ||
			error.message ||
			error.toString()
		return thunkAPI.rejectWithValue(message)
	}
})

export const billSlice = createSlice({
	name: 'bill',
	initialState,
	reducers: {
		allBills: (state, action) => {
			state.totalBills = action.payload ? action.payload : 0
		},
		billsAnalytics: (state, action) => {
			state.billsAnalyticsData = action.payload
		},
		sandboxAnalytics: (state, action) => {
			state.sandboxAnalyticsData = action.payload
		},
		setSingleBill: (state, action) => {
			console.log(action.payload)
			state.singleBill = action.payload
		},
		setCurrentBillToSend: (state, action) => {
			console.log(action.payload)
			state.currentBillToSend = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(sendBill.pending, (state) => {
				state.billsIsLoading = true
			})
			.addCase(sendBill.fulfilled, (state, action) => {
				state.billsIsLoading = false
				state.message = action.payload
			})
			.addCase(sendBill.rejected, (state, action) => {
				state.billsIsLoading = false
			})
			.addCase(getBills.pending, (state) => {
				state.billsIsLoading = true
			})
			.addCase(getBills.fulfilled, (state, action) => {
				state.billsIsLoading = false
				state.billsAnalyticsData = state.billsAnalyticsData
				state.sandboxAnalyticsData = state.sandboxAnalyticsData
			})
			.addCase(getBills.rejected, (state, action) => {
				state.billsIsLoading = false
			})
			.addCase(getInquiry.pending, (state) => {
				state.billsIsLoading = true
			})
			.addCase(getInquiry.fulfilled, (state, action) => {
				state.billsIsLoading = false
				// state.message = action.payload
			})
			.addCase(getInquiry.rejected, (state, action) => {
				state.billsIsLoading = false
			})
			.addCase(cancelBill.pending, (state) => {
				state.billsIsLoading = true
			})
			.addCase(cancelBill.fulfilled, (state, action) => {
				state.billsIsLoading = false
				// state.message = action.payload
			})
			.addCase(cancelBill.rejected, (state, action) => {
				state.billsIsLoading = false
			})
			.addCase(getTotalBills.pending, (state) => {
				state.billsIsLoading = true
			})
			.addCase(getTotalBills.fulfilled, (state, action) => {
				state.billsIsLoading = false
				state.totalBills = action.payload ? action.payload.response : 0
			})
			.addCase(getTotalBills.rejected, (state, action) => {
				state.billsIsLoading = false
			})
			.addCase(getBill.pending, (state) => {
				state.billsIsLoading = true
			})
			.addCase(getBill.fulfilled, (state, action) => {
				state.billsIsLoading = false
				state.singleBill =
					action.payload.status === 200
						? action.payload.response
							? JSON.parse(action.payload.response.form_data)
							: null
						: null
				state.singleBillStatus =
					action.payload.status === 200
						? action.payload.response
							? action.payload.response.send_status
							: null
						: null
				state.singleIrtaxid =
					action.payload.status === 200
						? action.payload.response
							? action.payload.response.irtaxid
							: null
						: null
			})
			.addCase(getBill.rejected, (state, action) => {
				state.billsIsLoading = false
			})
	}
})

export const {
	allBills,
	billsAnalytics,
	sandboxAnalytics,
	setSingleBill,
	setCurrentBillToSend
} = billSlice.actions

export default billSlice.reducer

import React from 'react'
import image from '../../images/404.png' // Tell webpack this JS file uses this image
import '../../styles/page404.scss'

export default function Page404() {
	return (
		<div className='page404Wrapper'>
			<img src={image} alt='page404' />
			<p>صفحه مورد نظر شما پیدا نشد</p>
		</div>
	)
}

import React, { useState, useEffect } from 'react'
import { Button, Drawer, Space, Form, Input, Empty, message, Row, Col } from 'antd'
import '../../styles/users.scss'
import { useSelector, useDispatch } from 'react-redux'
import {
	addUser,
	removeUser,
	getUsers,
	saveUser,
	deleteUser,
	GetSecretKey
} from '../../app/services/extraUsers/Slice'
import axios from 'axios'
import URL from '../../app/services/config.json'
import Loading from '../../features/Loading/Loading'
import {
	phoneNumberValidator,
	phoneNumberNormalizer,
	digitsEnToFa
} from '@persian-tools/persian-tools'
import { PasswordInput } from 'antd-password-input-strength'
import { ReactComponent as SendCode } from '../../images/send-code.svg'
import { ReactComponent as DeleteIcon } from '../../images/delete-icon.svg'
import { ReactComponent as CloseIcon } from '../../images/close-icon.svg'
import { ReactComponent as PlusIcon } from '../../images/plus-icon.svg'
import { ReactComponent as ExtrauserIcon } from '../../images/extrauser-icon.svg'

import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import ConfirmModal from '../../features/ConfirmModal/ConfirmModal'
var CryptoJS = require('crypto-js')

export default function Users() {
	const dispatch = useDispatch()
	const [messageApi, contextHolder] = message.useMessage()
	const { token } = useSelector((state) => state.user)
	const { extraUsers, isLoading } = useSelector((state) => state.users)
	const [open, setOpen] = useState(false)
	const [secretKey, setSecretKey] = useState(false)

	const showDrawer = () => {
		setOpen(true)
	}

	const GetTheSecretKey = async () => {
		const getSecretKey = await dispatch(GetSecretKey())
		if (getSecretKey.payload.data.status == 200) {
			setSecretKey(getSecretKey.payload.data.response)
		}
	}

	const getAllUsers = async () => {
		const allUsers = await dispatch(getUsers(token))
	}

	const removeSingleUser = async (id) => {
		const response = await dispatch(deleteUser(id))
		dispatch(removeUser(id))
	}

	useEffect(() => {
		if (extraUsers.length <= 0) {
			getAllUsers()
		}

		if (!secretKey) {
			GetTheSecretKey()
		}
	}, [])

	return (
		<div className='usersWrap'>
			{contextHolder}
			<div className='dashboardTitle'>
				<div>
					<h4>همکاران</h4>
					<p>
						افرادی که در این قسمت تعریف میکنید میتوانند به بخش های: شناسه
						کالا، ارسال از طریق فرم، ارسال از طریق آپلود فایل، فاکتورهای ارسال
						شده ، فاکتور های ذخیره شده و مشتری ها دسترسی داشته باشند. توجه
						کنید که اگر اطلاعات این شخص قبلا در سامانه ثبت شده باشد و شما
						ایشان را به عنوان همکار خود انتخاب کنید، دسترسی ایشان به کارتابل
						قبلی حذف خواهد شد
					</p>
				</div>
				<Button className='MAAdd' icon={<PlusIcon />} onClick={showDrawer}>
					افزودن همکار
				</Button>
			</div>
			{isLoading ? (
				<Loading loading='true' />
			) : (
				<div
					className={
						extraUsers.length > 0
							? 'userContent dashboardMainContent'
							: 'userContent dashboardMainContent isEmpty'
					}>
					{extraUsers.length > 0 ? (
						extraUsers.map((user, index) => (
							<div key={index} className='singleWidget'>
								<div className='singleWidgetRight'>
									<ExtrauserIcon />
								</div>
								<div className='singleWidgetLeft'>
									<div className='singleWidgetTitle'>
										{user.display_name}
									</div>
									<div className='singleWidgetSubtitle'>
										{user.user_email}
									</div>
									<div className='singleWidgetSubtitle'>
										{user.user_login}
									</div>
									<Button
										className='MARemove'
										onClick={() => {
											ConfirmModal({
												id: user.ID,
												handle: removeSingleUser
											})
										}}>
										<DeleteIcon />
									</Button>
								</div>
							</div>
						))
					) : (
						<Empty description={false} />
					)}
				</div>
			)}

			<UsersDrawer
				open={open}
				setOpen={setOpen}
				messageApi={messageApi}
				isLoading={isLoading}
				secretKey={secretKey}
			/>
		</div>
	)
}

export const UsersDrawer = (props) => {
	const dispatch = useDispatch()
	const { open, setOpen, messageApi, isLoading, secretKey } = props
	const [level, setLevel] = useState(0)
	const [confirmed, setConfirmed] = useState(false)
	const [innerLoading, setInnerLoading] = useState(false)
	const [current, setCurrent] = useState(0)
	const [code, setCode] = useState(null)
	const [time, setTime] = useState(null)
	const [pin, setPin] = useState(null)
	const [sendAgainStatus, setSendAgainStatus] = useState(false)
	const [form] = Form.useForm()
	const onClose = () => {
		setOpen(false)
	}

	// check code melli
	const checkPersianWord = async (_, value) => {
		if (!value) return Promise.reject('فیلد اجباری است')
		if (/^[\u0600-\u06FF\s]*$/.test(value)) return Promise.resolve()
		return Promise.reject('فقط حروف فارسی وارد کنید')
	}

	// check code melli
	// const checkNationalCode = async (_, value) => {
	// 	if (!value) return Promise.reject('کد ملی اجباری است')

	// 	if (verifyIranianNationalId(value)) return Promise.resolve()
	// 	return Promise.reject('کد ملی را به صورت صحیح وارد کنید')
	// }

	// check mobile number (username)
	const checkMobileNumber = async (_, value) => {
		if (!value) return Promise.reject('شماره موبایل اجباری است')

		if (phoneNumberValidator(value)) {
			return Promise.resolve()
		}
		return Promise.reject('شماره موبایل را به صورت صحیح وارد نمایید')
	}

	// check email address
	const checkEmail = async (_, value) => {
		if (!value) return Promise.reject('پست الکترونیک اجباری است')
		if (
			value &&
			value
				.toLowerCase()
				.match(
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				)
		) {
			return Promise.resolve()
		} else {
			return Promise.reject('فرمت پست الکترونیک نوشته شده صحیح نیست')
		}
	}

	// check password
	const checkPassword = async (_, value) => {
		if (!value) return Promise.reject('رمز عبور اجباری است')
		const minLevel = 1
		const errorMessage =
			'رمزعبور قوی تر انتخاب کنید - رمز عبور باید شامل عدد، حرف و علامت باشد'
		return level >= minLevel ? Promise.resolve() : Promise.reject(errorMessage)
	}

	const onFormSave = () => {
		const encryptedData = sessionStorage.getItem('MAsignupForm')
		const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, secretKey)
		const decryptedData = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8))
		decryptedData.user_login = phoneNumberNormalizer(decryptedData.user_login, 0)
		form.validateFields()
			.then(async (values) => {
				console.log(values)
				const addP = await dispatch(saveUser(decryptedData))
				console.log(addP.payload.status)
				if (addP.payload.status === 200) {
					console.log(addP)
					dispatch(addUser(addP.payload.response.user))
					form.resetFields()
					messageApi.success(addP.payload.response.message)
					setOpen(false)
					setCurrent(0)
					sessionStorage.setItem('MAsignupForm', '')
				} else {
					addP.payload.response.map((error) => {
						messageApi.error(error)
					})
				}
			})
			.catch((errorInfo) => {
				console.log(errorInfo)
				console.log(errorInfo.errorFields)
			})
	}

	const steps = [
		{
			title: 'ثبت اطلاعات'
		},
		{
			title: 'تایید شماره موبایل'
		}
	]

	const next = () => {
		form.validateFields()
			.then(async () => {
				setInnerLoading(true)
				setConfirmed(false)
				const obj = form.getFieldsValue()
				const serializedData = JSON.stringify(obj)
				const encryptedData = CryptoJS.AES.encrypt(
					serializedData,
					secretKey
				).toString()
				sessionStorage.setItem('MAsignupForm', encryptedData)
				const response = await axios.post(
					URL.homeURL + URL.restURL + URL.sendCode,
					{
						mobile: phoneNumberNormalizer(obj.user_login, 0),
						email: obj.user_email
					}
				)
				console.log(response)
				if (response.data.status === 403) {
					setInnerLoading(false)
					response.data.response.map((error) => {
						messageApi.error(error)
					})
				} else if (response.data.status === 200) {
					var decoded_data = JSON.parse(atob(response.data.response))
					setPin(decoded_data.code)
					setTime(decoded_data.time)
					setInnerLoading(false)
					setCurrent(current + 1)
					setSendAgainStatus(false)
				}
			})
			.catch((errorInfo) => {
				console.log(errorInfo.errorFields)
			})
	}

	const prev = () => {
		setCurrent(current - 1)
	}

	const sendAgainFunction = async () => {
		setInnerLoading(true)
		const encryptedData = sessionStorage.getItem('MAsignupForm')
		const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, secretKey)
		const decryptedData = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8))
		const userLogin = phoneNumberNormalizer(decryptedData.user_login, 0)
		const response = await axios.post(URL.homeURL + URL.restURL + URL.sendCode, {
			mobile: userLogin
		})
		console.log(response)
		if (response.data.status === 403) {
			setInnerLoading(false)
			response.data.response.map((error) => {
				messageApi.error(error)
			})
		} else if (response.data.status === 200) {
			var decoded_data = JSON.parse(atob(response.data.response))
			setPin(decoded_data.code)
			setTime(decoded_data.time)
			setInnerLoading(false)
			setSendAgainStatus(false)
		}
	}

	return (
		<Drawer
			maskClosable={false}
			closable={false}
			className='addExtraUser'
			title=' '
			placement='left'
			onClose={onClose}
			open={open}
			key='left'
			extra={
				<React.Fragment>
					{current > 0 && (
						<React.Fragment>
							<Button
								className='MAButton MASecondry'
								disabled={!sendAgainStatus}
								onClick={() => prev()}>
								مرحله قبل
							</Button>
							<Button
								className='MAButton'
								onClick={onFormSave}
								type='primary'
								disabled={!confirmed}
								loading={isLoading}>
								ثبت کاربر
							</Button>
						</React.Fragment>
					)}
					{current === 0 &&
						(!isLoading ? (
							<React.Fragment>
								<Button
									disabled={innerLoading}
									className='MAButton MASecondry MAClose'
									type='primary'
									onClick={() => setOpen(false)}>
									<CloseIcon />
								</Button>
								<Button
									loading={innerLoading}
									className='MAButton'
									type='primary'
									onClick={() => next()}>
									تایید شماره موبایل
								</Button>
							</React.Fragment>
						) : null)}
				</React.Fragment>
			}>
			{isLoading ? (
				<Loading loading='true' />
			) : (
				<React.Fragment>
					{current < steps.length - 1 && (
						<React.Fragment>
							<Form name='signupExtraUser' form={form}>
								<Space
									direction='vertical'
									size='small'
									style={{ display: 'flex' }}>
									<Row gutter={16}>
										<Col span={12}>
											<Form.Item
												name='first_name'
												hasFeedback
												rules={[
													{
														validator: checkPersianWord
													},
													{
														required: true,
														message: ''
													}
												]}>
												<Input size='large' placeholder='نام' />
											</Form.Item>
										</Col>
										<Col span={12}>
											<Form.Item
												name='last_name'
												hasFeedback
												rules={[
													{
														validator: checkPersianWord
													},
													{
														required: true,
														message: ''
													}
												]}>
												<Input
													size='large'
													placeholder='نام خانوادگی'
												/>
											</Form.Item>
										</Col>
									</Row>
									<Form.Item
										name='user_login'
										hasFeedback
										rules={[
											{
												validator: checkMobileNumber
											},
											{
												required: true,
												message: ''
											}
										]}>
										<Input
											size='large'
											placeholder='شماره موبایل: 09129876543'
										/>
									</Form.Item>
									<Form.Item
										name='user_email'
										hasFeedback
										rules={[
											{
												validator: checkEmail
											},
											{
												required: true,
												message: ''
											}
										]}>
										<Input size='large' placeholder='پست الکترونیک' />
									</Form.Item>
									<Form.Item
										name='user_pass'
										hasFeedback
										rules={[
											{
												validator: checkPassword
											},
											{
												required: true,
												message: ''
											}
										]}>
										<PasswordInput
											settings={{
												colorScheme: {
													levels: [
														'#ff4033',
														'#fe940d',
														'#ffd908',
														'#cbe11d',
														'#6ecc3a'
													],
													noLevel: 'lightgrey'
												},
												height: 5,
												alwaysVisible: false
											}}
											onLevelChange={setLevel}
											size='large'
											placeholder='رمز عبور'
										/>
									</Form.Item>
								</Space>
							</Form>
						</React.Fragment>
					)}
					{current === steps.length - 1 && (
						<React.Fragment>
							<ConfirmationCodeD
								messageApi={messageApi}
								time={time}
								pin={pin}
								setPin={setPin}
								setTime={setTime}
								confirmed={confirmed}
								setConfirmed={setConfirmed}
								innerLoading={innerLoading}
								sendAgainFunction={sendAgainFunction}
								form={form}
								sendAgainStatus={sendAgainStatus}
								setSendAgainStatus={setSendAgainStatus}
								setCode={setCode}
								secretKey={secretKey}
							/>
						</React.Fragment>
					)}
				</React.Fragment>
			)}
		</Drawer>
	)
}

export const ConfirmationCodeD = (props) => {
	const {
		setCode,
		sendAgainStatus,
		setSendAgainStatus,
		sendAgainFunction,
		innerLoading,
		pin,
		setConfirmed,
		confirmed,
		time,
		messageApi,
		setPin,
		setTime,
		secretKey
	} = props
	const encryptedData = sessionStorage.getItem('MAsignupForm')
	var userLogin = null
	if (encryptedData) {
		const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, secretKey)
		const decryptedData = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8))
		userLogin = decryptedData.user_login
	}

	console.log(userLogin)
	let number =
		userLogin && phoneNumberNormalizer(userLogin, 0)
			? phoneNumberNormalizer(userLogin, 0)
			: null
	let phoneNumber = number ? digitsEnToFa(number) : null

	const confirmedFunction = () => {
		setConfirmed(true)
		// sessionStorage.setItem('MAsignupForm', '')
	}

	useEffect(() => {
		const inputElements = [...document.querySelectorAll('input.code-input')]
		inputElements.forEach((ele, index) => {
			ele.addEventListener('keydown', (e) => {
				// if the keycode is backspace & the current field is empty
				// focus the input before the current. Then the event happens
				// which will clear the "before" input box.
				if (e.keyCode === 8 && e.target.value === '')
					inputElements[Math.max(0, index - 1)].focus()
			})
			ele.addEventListener('input', (e) => {
				// take the first character of the input
				// this actually breaks if you input an emoji like 👨‍👩‍👧‍👦....
				// but I'm willing to overlook insane security code practices.
				const [first, ...rest] = e.target.value
				e.target.value = first ?? '' // first will be undefined when backspace was entered, so set the input to ""
				const lastInputBox = index === inputElements.length - 1
				const didInsertContent = first !== undefined
				if (didInsertContent && !lastInputBox) {
					// continue to input the rest of the string
					inputElements[index + 1].focus()
					inputElements[index + 1].value = rest.join('')
					inputElements[index + 1].dispatchEvent(new Event('input'))
				}
				const codee = inputElements.map(({ value }) => value).join('')
				setCode(codee)
				console.log(pin)
				var startDate = Date.now()
				var endDate = time
				var second = startDate - endDate
				var seconds = Math.floor((second / 1000) % 60)
				console.log(seconds)
				if (seconds > 59) {
					setPin(null)
					setTime(null)
					messageApi.error('کد شما منقضی شده است. لطفا مجدد کد را دریافت کنید')
				} else if (seconds <= 59 && codee.length === 6 && pin === codee) {
					confirmedFunction()
				}
			})
		})
	}, [pin, time])

	return (
		<div className='confimationCodeWrap'>
			<div className='confimationCodeText'>
				<p>کد</p>
				<SendCode />
				<p className='primaryP'>ارسال شده</p>
				<p> را وارد کنید</p>
			</div>
			{phoneNumber ? (
				<p className='greyP'>
					کد برای {phoneNumber} ارسال شده است. اگر آن را اشتباه وارد کرده اید،
					لطفا صبر کنید تا دکمه "مرحله قبل" روشن شود و سپس شماره خود را اصلاح
					نمایید.
				</p>
			) : null}

			<div className='confimationCodeContent'>
				<input
					disabled={confirmed}
					autoFocus
					name='code'
					className='code-input'
					required
				/>
				<input disabled={confirmed} name='code' className='code-input' required />
				<input disabled={confirmed} name='code' className='code-input' required />
				<input disabled={confirmed} name='code' className='code-input' required />
				<input disabled={confirmed} name='code' className='code-input' required />
				<input disabled={confirmed} name='code' className='code-input' required />
			</div>
			{!confirmed ? (
				<div className='confimationCodeTimerWrap'>
					{!sendAgainStatus ? (
						<React.Fragment>
							<CountdownCircleTimer
								size={40}
								strokeWidth={1}
								isPlaying={true}
								duration={60}
								colors={['#487bff', '#ffab48']}
								colorsTime={[60, 0]}
								onComplete={() => {
									// do your stuff here
									setSendAgainStatus(true)
									messageApi.error(
										'کد شما منقضی شده است. لطفا مجدد کد را دریافت کنید'
									)
								}}>
								{({ remainingTime }) => {
									return (
										<div className='MAcircleTimer'>
											{digitsEnToFa(remainingTime)}
										</div>
									)
								}}
							</CountdownCircleTimer>
							<p>ثانیه مانده تا دریافت مجدد کد</p>
						</React.Fragment>
					) : (
						<Button
							disabled={confirmed}
							loading={innerLoading}
							className='MAButton'
							onClick={() => sendAgainFunction()}>
							ارسال مجدد
						</Button>
					)}
				</div>
			) : null}
		</div>
	)
}

import React from 'react'
import '../../styles/footer.scss'
import { Menu } from 'antd'
import URL from '../../app/services/config.json'
import { Row, Col } from 'antd'

export function ContactInfo() {
	return (
		<ul className='ma-list'>
			<li className='ma-lists'>
				<a href='tel:9126183621' target='_blank' rel='nofollow'>
					<span className='list-icon-wrapper'>
						<i className='ma-icon'>
							<svg
								version='1.1'
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='28'
								viewBox='0 0 24 28'>
								<path
									fill='#222'
									d='M20 18.641c0-0.078 0-0.172-0.031-0.25-0.094-0.281-2.375-1.437-2.812-1.687-0.297-0.172-0.656-0.516-1.016-0.516-0.688 0-1.703 2.047-2.312 2.047-0.313 0-0.703-0.281-0.984-0.438-2.063-1.156-3.484-2.578-4.641-4.641-0.156-0.281-0.438-0.672-0.438-0.984 0-0.609 2.047-1.625 2.047-2.312 0-0.359-0.344-0.719-0.516-1.016-0.25-0.438-1.406-2.719-1.687-2.812-0.078-0.031-0.172-0.031-0.25-0.031-0.406 0-1.203 0.187-1.578 0.344-1.031 0.469-1.781 2.438-1.781 3.516 0 1.047 0.422 2 0.781 2.969 1.25 3.422 4.969 7.141 8.391 8.391 0.969 0.359 1.922 0.781 2.969 0.781 1.078 0 3.047-0.75 3.516-1.781 0.156-0.375 0.344-1.172 0.344-1.578zM24 6.5v15c0 2.484-2.016 4.5-4.5 4.5h-15c-2.484 0-4.5-2.016-4.5-4.5v-15c0-2.484 2.016-4.5 4.5-4.5h15c2.484 0 4.5 2.016 4.5 4.5z'></path>
							</svg>
						</i>
					</span>
					<span className='list-title elementor-inline-editing'>آتشین بار</span>
					<span
						className='list-description elementor-inline-editing'
						data-class='list-subtitle'>
						{' '}
						۰۹۱۲۶۱۸۳۶۲۱
					</span>
				</a>
			</li>
			<li className='ma-lists'>
				<a href='tel:09122723024' target='_blank' rel='nofollow'>
					<span className='list-icon-wrapper'>
						<i className='ma-icon'>
							<svg
								version='1.1'
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='28'
								viewBox='0 0 24 28'>
								<path
									fill='#222'
									d='M20 18.641c0-0.078 0-0.172-0.031-0.25-0.094-0.281-2.375-1.437-2.812-1.687-0.297-0.172-0.656-0.516-1.016-0.516-0.688 0-1.703 2.047-2.312 2.047-0.313 0-0.703-0.281-0.984-0.438-2.063-1.156-3.484-2.578-4.641-4.641-0.156-0.281-0.438-0.672-0.438-0.984 0-0.609 2.047-1.625 2.047-2.312 0-0.359-0.344-0.719-0.516-1.016-0.25-0.438-1.406-2.719-1.687-2.812-0.078-0.031-0.172-0.031-0.25-0.031-0.406 0-1.203 0.187-1.578 0.344-1.031 0.469-1.781 2.438-1.781 3.516 0 1.047 0.422 2 0.781 2.969 1.25 3.422 4.969 7.141 8.391 8.391 0.969 0.359 1.922 0.781 2.969 0.781 1.078 0 3.047-0.75 3.516-1.781 0.156-0.375 0.344-1.172 0.344-1.578zM24 6.5v15c0 2.484-2.016 4.5-4.5 4.5h-15c-2.484 0-4.5-2.016-4.5-4.5v-15c0-2.484 2.016-4.5 4.5-4.5h15c2.484 0 4.5 2.016 4.5 4.5z'></path>
							</svg>
						</i>
					</span>
					<span className='list-title elementor-inline-editing'>شمسی زاده</span>
					<span
						className='list-description elementor-inline-editing'
						data-class='list-subtitle'>
						{' '}
						۰۹۱۲۲۷۲۳۰۲۴
					</span>
				</a>
			</li>
		</ul>
	)
}
export default function Footer() {
	return (
		<div className='footerWrap'>
			<div className='MAfooterShape MAfooterShape-top' data-negative='false'>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					viewBox='0 0 1000 100'
					preserveAspectRatio='none'>
					<path
						className='MAfooterShape-fill'
						d='M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
	c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
	c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z'></path>
				</svg>
			</div>
			<div className='mainContainer'>
				<Row className='footerContent' gutter={30}>
					<Col className='footerContent1' md={9} sm={24}>
						<div className='footerWidgetContainer'>
							<h4>نرم افزار تحت وب مودیان ابزار</h4>
							<div className='footerWidgetContent'>
								<p>
									نرم افزار تحت وب نامنو در سال 1401 با هدف ارائه خدمات
									مالی شروع به فعالیت نمود. از همان ابتدا اولویت نامنو
									تولید نرم افزار هایی کاملا کاربردی و روان جهت تسریع در
									انجام کار و رضایت خاطر مشتریان عزیز بوده است و تا
									انتهای کار نامنو این روند ادامه دارد
								</p>
							</div>
						</div>
					</Col>
					<Col className='footerContent2' md={4} sm={24}>
						<div className='footerWidgetContainer'>
							<h4>مقالات آموزشی</h4>
							<div className='footerWidgetContent'>
								<Menu
									mode='inline'
									items={[
										{
											key: 'home',
											label: (
												<a
													href={URL.homeURL + URL.homeURL}
													target='_blank'
													rel='noopener noreferrer'>
													نرم افزار رابط مودیان
												</a>
											)
										},
										{
											key: 'price',
											label: (
												<a
													href={URL.homeURL + URL.priceURL}
													target='_blank'
													rel='noopener noreferrer'>
													ثبت و ارسال فاکتور
												</a>
											)
										},
										{
											key: 'blog',
											label: (
												<a
													href={URL.homeURL + URL.blogURL}
													target='_blank'
													rel='noopener noreferrer'>
													ابطال فاکتور
												</a>
											)
										},
										{
											key: 'about',
											label: (
												<a
													href={URL.homeURL + URL.aboutURL}
													target='_blank'
													rel='noopener noreferrer'>
													افزودن مشتری
												</a>
											)
										},
										{
											key: 'contact',
											label: (
												<a
													href={URL.homeURL + URL.contactURL}
													target='_blank'
													rel='noopener noreferrer'>
													اصلاح فاکتور
												</a>
											)
										}
									]}
								/>
							</div>
						</div>
					</Col>
					<Col className='footerContent3' md={4} sm={24}>
						<div className='footerWidgetContainer'>
							<h4>لینک‌های پرکاربرد</h4>
							<div className='footerWidgetContent'>
								<Menu
									mode='inline'
									items={[
										{
											key: 'home',
											label: (
												<a
													href={URL.homeURL + URL.priceURL}
													target='_blank'
													rel='noopener noreferrer'>
													تعرفه ها
												</a>
											)
										},
										{
											key: 'price',
											label: (
												<a
													href={URL.homeURL + URL.faqURL}
													target='_blank'
													rel='noopener noreferrer'>
													سوالات متداول
												</a>
											)
										},
										{
											key: 'blog',
											label: (
												<a
													href={URL.homeURL + URL.privacyURL}
													target='_blank'
													rel='noopener noreferrer'>
													حریم خصوصی
												</a>
											)
										},
										{
											key: 'about',
											label: (
												<a
													href={URL.homeURL + URL.termsURL}
													target='_blank'
													rel='noopener noreferrer'>
													قوانین و مقررات
												</a>
											)
										}
									]}
								/>
							</div>
						</div>
					</Col>
					<Col className='footerContent4' md={7} sm={24}>
						<div className='footerWidgetContainer'>
							<h4>راه های ارتباطی</h4>
							<div className='footerWidgetContent'>
								<p>شنبه تا پنج شنبه از ساعت 9 الی 5 بعد از ظهر</p>
								<ContactInfo />
								<p>
									آدرس : پردیس فاز 11 ، بلوار فرهنگ، خیابان تدبیر ،
									بلوک5B95، طبقه: 11، واحد: 45
								</p>
							</div>
						</div>
					</Col>
				</Row>
				<div className='footerBottom'>
					<Row align='middle'>
						<Col md={12} sm={24}>
							<p>تمامی حقوق برای وب سایت مؤدیان ابزار محفوظ می باشد</p>
						</Col>
						<Col md={12} sm={24}>
							<Row gutter={10} align='stretch'>
								<Col md={4} sm={24}></Col>
								<Col md={4} sm={24}></Col>
								<Col md={4} sm={24}></Col>

								<Col md={4} sm={24}>
									<div className='ma-namads'>
										<img
											src='https://moadianabzar.ir/wp-content/uploads/2020/07/etehadiekeshvaripng.parspng.png'
											alt=''
										/>
									</div>
								</Col>
								<Col md={4} sm={24}>
									<div className='ma-namads'>
										<a
											href='https://trustseal.enamad.ir/?id=425931&amp;Code=5TGdXlalYN9hVSuF6KR3LFZoYnq47r25'
											referrerPolicy='origin'>
											<img
												src='https://moadianabzar.ir/wp-content/uploads/2020/07/namad.png'
												alt=''
											/>
										</a>
									</div>
								</Col>
								<Col md={4} sm={24}>
									<div className='ma-namads'>
										<img
											src='https://moadianabzar.ir/wp-content/uploads/2020/07/samandehipng.png'
											alt=''
										/>
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
			</div>
		</div>
	)
}

import React, { useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import '../../styles/main.scss'
import '../../styles/dark.scss'

export default function Layout(props) {
	const { children } = props

	return (
		<div className='mainWrap'>
			<Header />
			{children}
			<Footer />
		</div>
	)
}

export const timeDifference = (date1, date2, diffType) => {
	var difference = date1 - date2

	var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24)
	difference -= daysDifference * 1000 * 60 * 60 * 24

	var hoursDifference = Math.floor(difference / 1000 / 60 / 60)
	difference -= hoursDifference * 1000 * 60 * 60

	var minutesDifference = Math.floor(difference / 1000 / 60)
	difference -= minutesDifference * 1000 * 60

	var secondsDifference = Math.floor(difference / 1000)
	console.log(
		'difference = ' +
			daysDifference +
			' day/s ' +
			hoursDifference +
			' hour/s ' +
			minutesDifference +
			' minute/s ' +
			secondsDifference +
			' second/s '
	)

	switch (diffType) {
		case 'day':
			return daysDifference
		case 'hour':
			return hoursDifference
		case 'min':
			return minutesDifference
		case 'sec':
			return secondsDifference
		default:
			break
	}
}

export const allMeasurement = [
	{
		label: 'لنگه',
		value: '1611'
	},
	{
		label: 'عدل',
		value: '1612'
	},
	{
		label: 'جعبه',
		value: '1613'
	},
	{
		label: 'توپ',
		value: '1618'
	},
	{
		label: 'ست',
		value: '1619'
	},
	{
		label: 'دست',
		value: '1620'
	},
	{
		label: 'کارتن',
		value: '1624'
	},
	{
		label: 'عدد',
		value: '1627'
	},
	{
		label: 'بسته',
		value: '1628'
	},
	{
		label: 'پاکت',
		value: '1629'
	},
	{
		label: 'دستگاه',
		value: '1631'
	},
	{
		label: 'تخته',
		value: '1640'
	},
	{
		label: 'رول',
		value: '1641'
	},
	{
		label: 'طاقه',
		value: '1642'
	},
	{
		label: 'جفت',
		value: '1643'
	},
	{
		label: 'متر مربع',
		value: '1645'
	},
	{
		label: 'پالت',
		value: '1649'
	},
	{
		label: 'دوجین',
		value: '1661'
	},
	{
		label: 'رینگ (حلقه)',
		value: '1668'
	},
	{
		label: 'قراص',
		value: '1673'
	},
	{
		label: 'قراصه',
		value: '1694'
	},
	{
		label: 'لیتر',
		value: '1637'
	},
	{
		label: 'ساشه',
		value: '1650'
	},
	{
		label: 'کپسول',
		value: '1683'
	},
	{
		label: 'بندیل',
		value: '1656'
	},
	{
		label: 'رول (حلقه)',
		value: '1630'
	},
	{
		label: 'قالب',
		value: '163'
	},
	{
		label: 'شانه',
		value: '1660'
	},
	{
		label: 'متر مكعب',
		value: '1647'
	},
	{
		label: 'ثوب',
		value: '1689'
	},
	{
		label: 'نیم دوجین',
		value: '1690'
	},
	{
		label: 'قرقره',
		value: '1635'
	},
	{
		label: 'کیلوگرم',
		value: '164'
	},
	{
		label: 'بطری',
		value: '1638'
	},
	{
		label: 'برگ',
		value: '161'
	},
	{
		label: 'سطل',
		value: '1625'
	},
	{
		label: 'ورق',
		value: '1654'
	},
	{
		label: 'شاخه',
		value: '1646'
	},
	{
		label: 'قوطي',
		value: '1644'
	},
	{
		label: 'جلد',
		value: '1617'
	},
	{
		label: 'تیوب',
		value: '162'
	},
	{
		label: 'متر',
		value: '165'
	},
	{
		label: 'کالف',
		value: '1610'
	},
	{
		label: 'کیسه',
		value: '1615'
	},
	{
		label: 'طغرا',
		value: '1680'
	},
	{
		label: 'بشكه',
		value: '1639'
	},
	{
		label: 'گالن',
		value: '1614'
	},
	{
		label: 'فاقد بسته بندی',
		value: '1687'
	},
	{
		label: 'کارتن )case master )',
		value: '1693'
	},
	{
		label: 'صفحه',
		value: '166'
	},
	{
		label: 'مخزن',
		value: '1666'
	},
	{
		label: 'تانكر',
		value: '1626'
	},
	{
		label: 'دبه',
		value: '1648'
	},
	{
		label: 'سبد',
		value: '1684'
	},
	{
		label: 'تن',
		value: '169'
	},
	{
		label: 'بانكه',
		value: '1651'
	},
	{
		label: 'سیلندر',
		value: '1633'
	},
	{
		label: 'فوت مربع',
		value: '1679'
	},
	{
		label: 'حلب',
		value: '168'
	},
	{
		label: 'شیت',
		value: '1665'
	},
	{
		label: 'چلیك',
		value: '1659'
	},
	{
		label: 'جام',
		value: '1636'
	},
	{
		label: 'گرم',
		value: '1622'
	},
	{
		label: 'نخ',
		value: '1616'
	},
	{
		label: 'شعله',
		value: '1652'
	},
	{
		label: 'قیراط',
		value: '1678'
	},
	{
		label: 'میلي لیتر',
		value: '16100'
	},
	{
		label: 'میلي متر',
		value: '16101'
	},
	{
		label: 'میلي گرم',
		value: '16102'
	},
	{
		label: 'ساعت',
		value: '16103'
	},
	{
		label: 'روز',
		value: '16104'
	},
	{
		label: 'تن کیلومتر',
		value: '16105'
	},
	{
		label: 'کیلووات ساعت',
		value: '1669'
	},
	{
		label: 'نفر',
		value: '1676'
	},
	{
		label: 'ثانیه',
		value: '16110'
	},
	{
		label: 'دقیقه',
		value: '16111'
	},
	{
		label: 'ماه',
		value: '16112'
	},
	{
		label: 'سال',
		value: '16113'
	},
	{
		label: 'قطعه',
		value: '16114'
	},
	{
		label: 'سانتي متر',
		value: '16115'
	},
	{
		label: 'سانتي متر مربع',
		value: '16116'
	},
	{
		label: 'فروند',
		value: '1632'
	},
	{
		label: 'واحد',
		value: '1653'
	},
	{
		label: 'لیوان',
		value: '16108'
	},
	{
		label: 'نوبت',
		value: '16117'
	},
	{
		label: 'مگا وات ساعت',
		value: '16118'
	},
	{
		label: 'گیگا بایت بر ثانیه',
		value: '16119'
	},
	{
		label: 'ویال',
		value: '1681'
	},
	{
		label: 'دیسک (حلقه)',
		value: '1667'
	},
	{
		label: 'نسخه (جلد)',
		value: '16120'
	}
]

export const iso4217 = [
	{ value: 'AED', label: 'درهم امارات - AED' },
	{ value: 'AFN', label: 'افغانی - AFN' },
	{ value: 'ALL', label: 'لک آلبانی - ALL' },
	{ value: 'AMD', label: 'درام ارمنستان - AMD' },
	{ value: 'ANG', label: 'گیلدر هلند - ANG' },
	{ value: 'AOA', label: 'کوانزا - AOA' },
	{ value: 'ARS', label: 'پزوی آرژانتین - ARS' },
	{ value: 'AUD', label: 'دلار استرالیا - AUD' },
	{ value: 'AWG', label: 'فلورین آروبا - AWG' },
	{ value: 'AZN', label: 'منات جمهوری آذربایجان - AZN' },
	{ value: 'BAM', label: 'مارک تبدیل‌پذیر بوسنی و هرزگوین - BAM' },
	{ value: 'BBD', label: 'دلار باربادوس - BBD' },
	{ value: 'BDT', label: 'تاکا بنگلادش - BDT' },
	{ value: 'BGN', label: 'لو بلغارستان - BGN' },
	{ value: 'BHD', label: 'دینار بحرین - BHD' },
	{ value: 'BIF', label: 'فرانک بوروندی - BIF' },
	{ value: 'BMD', label: 'دلار برمودا - BMD' },
	{ value: 'BND', label: 'دلار برونئی - BND' },
	{ value: 'BOB', label: 'بولیوانو - BOB' },
	{ value: 'BOV', label: 'میدل - BOV' },
	{ value: 'BRL', label: 'رئال برزیل - BRL' },
	{ value: 'BSD', label: 'دلار باهاما - BSD' },
	{ value: 'BTN', label: 'نگولتروم بوتان - BTN' },
	{ value: 'BWP', label: 'پولا - BWP' },
	{ value: 'BYR', label: 'روبل بلاروس - BYR' },
	{ value: 'BZD', label: 'دلار بلیز - BZD' },
	{ value: 'CAD', label: 'دلار کانادا - CAD' },
	{ value: 'CDF', label: 'فرانک کنگو - CDF' },
	{ value: 'CHE', label: 'واحدهای پولی مختلف - CHE' },
	{ value: 'CHF', label: 'فرانک سوئیس - CHF' },
	{ value: 'CHW', label: 'دابلیو آی آر بانک (واحد مکمل) - CHW' },
	{ value: 'CLF', label: 'CLF' },
	{ value: 'CLP', label: 'پزو شیلی - CLP' },
	{ value: 'CNY', label: 'رنمینبی و یوان - CNY' },
	{ value: 'COP', label: 'پزو کلمبیا - COP' },
	{ value: 'COU', label: 'COU' },
	{ value: 'CRC', label: 'کولون کاستاریکا - CRC' },
	{ value: 'CUP', label: 'پزو کوبا - CUP' },
	{ value: 'CVE', label: 'اسکودوی کیپ ورد - CVE' },
	{ value: 'CZK', label: 'کرون چک - CZK' },
	{ value: 'DJF', label: 'فرانک جیبوتی - DJF' },
	{ value: 'DKK', label: 'کرون دانمارک - DKK' },
	{ value: 'DOP', label: 'پزو دومینیکن - DOP' },
	{ value: 'DZD', label: 'دینار الجزایر - DZD' },
	{ value: 'EGP', label: 'پوند مصر - EGP' },
	{ value: 'ERN', label: 'ناکفا - ERN' },
	{ value: 'ETB', label: 'بیر اتیوپی - ETB' },
	{ value: 'EUR', label: 'یورو - EUR' },
	{ value: 'FJD', label: 'دلار فیجی - FJD' },
	{ value: 'FKP', label: 'پوند جزایر فالکلند - FKP' },
	{ value: 'GBP', label: 'پوند استرلینگ - GBP' },
	{ value: 'GEL', label: 'لاری - GEL' },
	{ value: 'GHS', label: 'سدی - GHS' },
	{ value: 'GIP', label: 'پوند جبل الطارق - GIP' },
	{ value: 'GMD', label: 'دالاسی گامبیا - GMD' },
	{ value: 'GNF', label: 'فرانک گینه - GNF' },
	{ value: 'GTQ', label: 'کویتزال گواتمالا - GTQ' },
	{ value: 'GYD', label: 'دلار گویان - GYD' },
	{ value: 'HKD', label: 'دلار هنگ کنگ - HKD' },
	{ value: 'HNL', label: 'لمپیرا - HNL' },
	{ value: 'HTG', label: 'گورد هائیتی - HTG' },
	{ value: 'HUF', label: 'فورینت مجارستان - HUF' },
	{ value: 'IDR', label: 'روپیه - IDR' },
	{ value: 'ILS', label: 'شکل جدید اسرائیل - ILS' },
	{ value: 'INR', label: 'روپیه - INR' },
	{ value: 'IQD', label: 'دینار عراق - IQD' },
	{ value: 'IRR', label: 'ریال ایران - IRR' },
	{ value: 'ISK', label: 'کرونای ایسلند - ISK' },
	{ value: 'JMD', label: 'دلار جامائیکا - JMD' },
	{ value: 'JOD', label: 'دینار اردن - JOD' },
	{ value: 'JPY', label: 'ین ژاپن - JPY' },
	{ value: 'KES', label: 'شیلینگ کنیا - KES' },
	{ value: 'KGS', label: 'سوم قرقیزستان - KGS' },
	{ value: 'KHR', label: 'ریل - KHR' },
	{ value: 'KMF', label: 'فرانک کمر - KMF' },
	{ value: 'KPW', label: 'وون کره شمالی - KPW' },
	{ value: 'KRW', label: 'وون کره جنوبی - KRW' },
	{ value: 'KWD', label: 'دینار کویت - KWD' },
	{ value: 'KYD', label: 'دلار جزایر کیمن - KYD' },
	{ value: 'KZT', label: 'تنگه قزاقستان - KZT' },
	{ value: 'LAK', label: 'کیپ - LAK' },
	{ value: 'LBP', label: 'پوند لبنان - LBP' },
	{ value: 'LKR', label: 'روپیه سریلانکا - LKR' },
	{ value: 'LRD', label: 'دلار لیبریا - LRD' },
	{ value: 'LSL', label: 'لوتی - LSL' },
	{ value: 'LYD', label: 'دینار لیبی - LYD' },
	{ value: 'MAD', label: 'درهم مراکش - MAD' },
	{ value: 'MDL', label: 'لئوی مولداوی - MDL' },
	{ value: 'MGA', label: 'آریاری - MGA' },
	{ value: 'MKD', label: 'دینار مقدونیه - MKD' },
	{ value: 'MMK', label: 'کیات - MMK' },
	{ value: 'MNT', label: 'توگریک - MNT' },
	{ value: 'MOP', label: 'پاتاکای ماکانز - MOP' },
	{ value: 'MRO', label: 'اوقیه - MRO' },
	{ value: 'MUR', label: 'روپیه موریس - MUR' },
	{ value: 'MVR', label: 'روفیا - MVR' },
	{ value: 'MWK', label: 'کواچا - MWK' },
	{ value: 'MXN', label: 'پزو مکزیک - MXN' },
	{ value: 'MXV', label: 'واحد سرمایه‌گذاری مکزیک - MXV' },
	{ value: 'MYR', label: 'رینگیت - MYR' },
	{ value: 'MZN', label: 'متیکال - MZN' },
	{ value: 'NAD', label: 'دلار نامیبیا - NAD' },
	{ value: 'NGN', label: 'نایرا - NGN' },
	{ value: 'NIO', label: 'کوردوبا - NIO' },
	{ value: 'NOK', label: 'کرون نروژ - NOK' },
	{ value: 'NPR', label: 'روپیه نپال - NPR' },
	{ value: 'NZD', label: 'دلار زلاندنو - NZD' },
	{ value: 'OMR', label: 'ریال عمان - OMR' },
	{ value: 'PAB', label: 'بالبوآ پاناما - PAB' },
	{ value: 'PEN', label: 'سول جدید پرو - PEN' },
	{ value: 'PGK', label: 'کینای پاپوا گینه نو - PGK' },
	{ value: 'PHP', label: 'پزو فیلیپین - PHP' },
	{ value: 'PKR', label: 'روپیه پاکستان - PKR' },
	{ value: 'PLN', label: 'زلوتی - PLN' },
	{ value: 'PYG', label: 'گوارانی پاراگوئه - PYG' },
	{ value: 'QAR', label: 'ریال قطر - QAR' },
	{ value: 'RON', label: 'لئوی رومانی - RON' },
	{ value: 'RSD', label: 'دینار صربستان - RSD' },
	{ value: 'RUB', label: 'روبل روسیه - RUB' },
	{ value: 'RWF', label: 'فرانک رواندا - RWF' },
	{ value: 'SAR', label: 'ریال سعودی - SAR' },
	{ value: 'SBD', label: 'دلار جزایر سلیمان - SBD' },
	{ value: 'SCR', label: 'روپیه سیشل - SCR' },
	{ value: 'SDG', label: 'پوند سودان - SDG' },
	{ value: 'SEK', label: 'کرون سوئد - SEK' },
	{ value: 'SGD', label: 'دلار سنگاپور - SGD' },
	{ value: 'SHP', label: 'پوند سنت هلن - SHP' },
	{ value: 'SKK', label: 'کرونای اسلواکی - SKK' },
	{ value: 'SLL', label: 'لئون سیرالئون - SLL' },
	{ value: 'SOS', label: 'شیلینگ سومالی - SOS' },
	{ value: 'SRD', label: 'دلار سورینام - SRD' },
	{ value: 'SSP', label: 'پوند سودان جنوبی - SSP' },
	{ value: 'STD', label: 'دبرا - STD' },
	{ value: 'SYP', label: 'پوند سوریه - SYP' },
	{ value: 'SZL', label: 'لیلانگنی سوازیلند - SZL' },
	{ value: 'THB', label: 'بات - THB' },
	{ value: 'TJS', label: 'سامانی - TJS' },
	{ value: 'TMT', label: 'منات ترکمنستان - TMT' },
	{ value: 'TND', label: 'دینار تونس - TND' },
	{ value: 'TOP', label: 'پاآنگای تنگو - TOP' },
	{ value: 'TRY', label: 'لیره جدید ترکیه - TRY' },
	{ value: 'TTD', label: 'دلار ترینیداد و توباگو - TTD' },
	{ value: 'TWD', label: 'دلار جدید تایوان - TWD' },
	{ value: 'TZS', label: 'شیلینگ تانزانیا - TZS' },
	{ value: 'UAH', label: 'گریونا - UAH' },
	{ value: 'UGX', label: 'شیلینگ اوگاندا - UGX' },
	{ value: 'USD', label: 'دلار آمریکا - USD' },
	{ value: 'USN', label: 'دلار - USN' },
	{ value: 'USS', label: 'دلار براساس ایزو ۴۲۱۷ - USS' },
	{ value: 'UYU', label: 'پزوی اروگوئه - UYU' },
	{ value: 'UZS', label: 'سم ازبکستان - UZS' },
	{ value: 'VEF', label: 'بولیوار ونزوئلا - VEF' },
	{ value: 'VND', label: 'دونگ ویتنام - VND' },
	{ value: 'VUV', label: 'واتوی وانواتو - VUV' },
	{ value: 'WST', label: 'طلای ساموآ - WST' },
	{ value: 'XAF', label: 'فرانک آفریقای مرکزی - XAF' },
	{ value: 'XAG', label: 'نقره (یک اونس) - XAG' },
	{ value: 'XAU', label: 'طلا (یک اونس) - XAU' },
	{ value: 'XBA', label: 'واحد پول مشترک اروپا (یورو) - XBA' },
	{ value: 'XBB', label: 'واحد پولی مشترک اروپا (یورو) - XBB' },
	{ value: 'XBC', label: 'واحد پولی مشترک اروپا (یورو) - XBC' },
	{ value: 'XBD', label: 'واحد پولی مشترک اروپا (یورو) - XBD' },
	{ value: 'XCD', label: 'دلار کارائیب شرقی - XCD' },
	{ value: 'XDR', label: 'قوانین و خصوصیات - XDR' },
	{ value: 'XFU', label: 'فرانک اتحادیه بین‌المللی راه‌آهن - XFU' },
	{ value: 'XOF', label: 'فرانک آفریقای غربی - XOF' },
	{ value: 'XPD', label: 'پالادیوم (معادل یک اونس) - XPD' },
	{ value: 'XPF', label: 'فرانک اقیانوسیه - XPF' },
	{ value: 'XPT', label: 'پلاتین (یک اونس) - XPT' },
	{ value: 'XTS', label: 'کدهای ذخیره شده برای اهداف آزمایشی - XTS' },
	{ value: 'XXX', label: 'بدون واحد - XXX' },
	{ value: 'YER', label: 'ریال یمن - YER' },
	{ value: 'ZAR', label: 'رند آفریقای جنوبی - ZAR' },
	{ value: 'ZMW', label: 'کواچای زامبیا - ZMW' },
	{ value: 'ZWR', label: 'دلار زیمبابوه - ZWR' }
]

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../../features/Loading/Loading';
import {
	showDrawer,
	formData,
	addCustomer,
	getCustomers,
	saveCustomer,
	deleteCustomer,
	removeCustomer,
} from '../../app/services/customers/Slice';
import {
	Button,
	Drawer,
	Form,
	Input,
	Empty,
	Popconfirm,
	Select,
	message,
} from 'antd';
import '../../styles/customers.scss';
import { ReactComponent as DeleteIcon } from '../../images/delete-icon.svg';
import { ReactComponent as EditIcon } from '../../images/edit-icon.svg';
import { ReactComponent as CloseIcon } from '../../images/close-icon.svg';
import { ReactComponent as CustomerIcon } from '../../images/customer-icon.svg';
import { ReactComponent as PlusIcon } from '../../images/plus-icon.svg';
import ConfirmModal from '../../features/ConfirmModal/ConfirmModal';
import NoticeBox from '../Docs/NoticeBox';

const { Option } = Select;

export default function Customers() {
	const dispatch = useDispatch();
	const [messageApi, contextHolder] = message.useMessage();
	const { customers, customerIsLoading, isOpen } = useSelector(
		(state) => state.customers
	);
	const getAllCustomers = async () => {
		const allCustomers = await dispatch(getCustomers());
	};
	useEffect(() => {
		// if (Object.keys(customers).length <= 0) getAllCustomers()
	}, []);
	console.log(customers);

	const removeHandle = async (customer_id) => {
		console.log('Removing handle');
		const deletedCustomer = await dispatch(deleteCustomer(customer_id));
		if (deletedCustomer.payload.status === 200) {
			dispatch(removeCustomer(customer_id));
			const allCustomers = await dispatch(getCustomers());
			messageApi.success(deletedCustomer.payload.response);
		} else {
			messageApi.error(deletedCustomer.payload.response);
		}
	};
	return (
		<div className="customersWrap">
			{contextHolder}
			<div className="dashboardTitle">
				<div>
					<h4>خریداران / مشتری ها</h4>
					<p>
						مشتری های اینجا تعریف می شوند و سپس می توانید آنها را در
						بخش "ارسال از طریق فرم" انتخاب کنید. کد ملی به همراه کد
						پستی ارسال خواهد شد اما کد اقتصادی میتواند به تنهایی
						ارسال شود. اگر یکی از موارد کدملی و کدپستی خالی باشند،
						سیستم کد اقتصادی را ارسال خواهد کرد.
					</p>
				</div>
				<Button
					className="MAAdd"
					icon={<PlusIcon />}
					onClick={() => {
						dispatch(
							showDrawer({
								open: !isOpen,
								edit: false,
								customerID: false,
							})
						);
					}}
				>
					افزودن مشتری
				</Button>
			</div>
			<NoticeBox
				title="خریداران / مشتری ها"
				descriptions="خریداران / مشتری ها چیست و چه اطلاعاتی در اختیار ما میگذارد"
				button="نمایش مقاله"
				video="https://moadianabzar.ir/wp-content/uploads/2024/01/خریداران-و-مشتریان.mp4"
				modalDescription={`<p> در بخش <strong> خریداران / مشتری ها </strong> ، در <strong> نرم افزار رابط مودیان </strong>ابزار شما میتوانید خریداران و یا مشتری ها را که میخواهید برای آن ها فاکتور مالیاتی رد بکنید را ثبت بکنید تا در زمان ارسال فاکتور های خود از طریق نرم افزار رابط مودیان ابزار دیگر نیاز به وارد کردن مجدد این اطلاعات نباشد.
</p>
<h2> ثبت و یا اضافه کردن خریداران / مشتری ها</h2>
<p>در قدم اول برای اضافه کردن خردار و یا مشتری جدید باید از طریق دکمه افزودن مشتری که در بالا سمت چپ این پنل وجود دارد اقدام کنید. تا بتوانید مشتری خود را در نرم افزار واسط مودیان ابزار ثبت بکنید. بعد از کلیک کردن روی دکمه افزودن مشتری یک فرم با 5 فیلد به شما نمایش داده میشود</p>
<h2> فیلد های الزامی جهت اضاف کردن فروشنده و یا شرکت در نرم افزار واسط مودیان ابزار</h2>
<ul>
    <li>
		نوع مشتری
		<ul>
			<li>حقیقی</li>
			<li>حقوقی</li>
			<li>مشارکت مدنی</li>
			<li>اتباع غیر ایرانی</li>
		</ul>
	</li>
    <li>کد نام و نام خانوادگی / شرکت</li>
    <li>کد ملی</li>
    <li>کد پستی</li>
    <li>شماره اقتصادی</li>
</ul>
<p> بعد از پر کردن فیلد های بالا با زدن دکمه ذخیره که در بالای فرم قرار دارد اطلاعات را ذخیره کنید تا اطلاعات مشتری ثبت شود تا در هنگام ارسال فاکتور با استفاده از ابزار ارسال از طریق فرم در قسمت "اطلاعات خریدار" بتوانید اطلاعات این مشتری را با انتخاب کردن نام آن به فاکتور اضافه کنید.</p>
<p> در قدم آخر شما در بخش خریداران / مشتری ها نرم افزار واسط مودیا ابزار میتوانید لیست خریداران / مشتری را که از قبل اضافه کردید را ببیند ، آن ها را حذف و یا ویرایش کنید.</p>
`}
			/>
			<br />
			{customerIsLoading ? (
				<Loading loading="true" />
			) : (
				<div
					className={
						typeof customers == 'object' &&
						Object.keys(customers).length > 0
							? 'dashboardMainContent customerContent'
							: 'dashboardMainContent customerContent isEmpty'
					}
				>
					{typeof customers == 'object' &&
					Object.keys(customers).length > 0 ? (
						Object.values(customers).map((customer, index) => (
							<div
								key={index}
								className="singleWidget"
								id={customer.customer_id}
							>
								<div className="singleWidgetRight">
									<CustomerIcon />
								</div>
								<div className="singleWidgetLeft">
									<div className="singleWidgetTitle">
										{customer.fullname}{' '}
										<span>
											(
											{customer.customer_type === '1'
												? 'حقیقی'
												: customer.customer_type === '2'
												? 'حقوقی'
												: customer.customer_type === '3'
												? 'مشارکت مدنی'
												: customer.customer_type === '4'
												? 'اتباع غیر ایرانی'
												: customer.customer_type === '5'
												? 'مصرف کننده نهایی'
												: null}
											)
										</span>
									</div>
									<div className="singleWidgetSubtitle">
										{' کدملی: '}
										{customer.cod_meli}
									</div>
									<div className="singleWidgetSubtitle">
										{' کد‌پستی: '}
										{customer.postal_code}
									</div>
									<div className="singleWidgetSubtitle">
										{' شماره اقتصادی: '}
										{customer.cod_eqtesadi}
									</div>
									<Button
										className="MARemove"
										onClick={() => {
											ConfirmModal({
												id: customer.customer_id,
												handle: removeHandle,
											});
										}}
									>
										<DeleteIcon />
									</Button>
									<Button
										className="MAEdit"
										onClick={() => {
											dispatch(formData(customer));
											dispatch(
												showDrawer({
													open: !isOpen,
													edit: true,
													customerID:
														customer.customer_id,
												})
											);
										}}
									>
										<EditIcon />
									</Button>
								</div>
							</div>
						))
					) : (
						<Empty description={false} />
					)}
				</div>
			)}
			<CustomersDrawer messageApi={messageApi} />
		</div>
	);
}

export function CustomersDrawer(props) {
	const { messageApi } = props;
	const dispatch = useDispatch();
	const { isOpen, isEditing, customerID, customerIsLoading } = useSelector(
		(state) => state.customers
	);
	const [form] = Form.useForm();

	const onFormSubmit = async () => {
		const obj = form.getFieldsValue();
		form.validateFields()
			.then(async (values) => {
				// Set CustomerID for edit item
				if (customerID) {
					obj.customer_id = customerID;
				}

				const newFormData = await dispatch(formData(obj));
				const savedCustomer = await dispatch(saveCustomer(obj));
				const allCustomers = await dispatch(getCustomers());

				if (savedCustomer.payload.status === 200) {
					dispatch(
						addCustomer(allCustomers.payload.response.customers)
					);
					dispatch(
						showDrawer({
							open: !isOpen,
							edit: false,
							customerID: false,
						})
					);
					dispatch(formData({}));

					setTimeout(() => {
						form.resetFields();
					}, 500);
					messageApi.success(savedCustomer.payload.response);
				} else {
					messageApi.error(savedCustomer.payload.response);
				}
			})
			.catch((error) => {
				console.log('Form has validation errors:', error);
			});
	};

	const handleFormChange = () => {
		dispatch(formData(form.getFieldsValue()));
	};

	return (
		<Drawer
			title=" "
			placement="left"
			maskClosable={false}
			closable={false}
			open={isOpen}
			key="left"
			extra={
				<React.Fragment>
					<Button
						className="MAButton MASecondry MAClose"
						type="primary"
						disabled={customerIsLoading}
						onClick={() => {
							dispatch(
								showDrawer({
									open: !isOpen,
									edit: false,
									customerID: false,
								})
							);
							dispatch(formData({}));

							setTimeout(() => {
								form.resetFields();
							}, 500);
						}}
					>
						<CloseIcon />
					</Button>
					<Button
						className="MAButton"
						type="primary"
						htmlType="submit"
						onClick={onFormSubmit}
						disabled={customerIsLoading}
					>
						{isEditing ? 'بروز رسانی' : 'ذخیره'}
					</Button>
				</React.Fragment>
			}
		>
			{customerIsLoading ? (
				<Loading loading="true" />
			) : (
				<DrawerFields form={form} onChange={handleFormChange} />
			)}
		</Drawer>
	);
}

export function DrawerFields(props) {
	const { form, onChange } = props;
	const { currentFormData, isEditing } = useSelector(
		(state) => state.customers
	);

	form.setFieldsValue(currentFormData);

	return (
		<Form
			name="dynamic_form_nest_item"
			form={form}
			layout="vertical"
			onChange={onChange}
		>
			<Form.Item
				hasFeedback
				name="customer_type"
				rules={[
					{
						required: true,
						message: 'فیلد اجباری است',
					},
				]}
			>
				<Select size="large" placeholder="نوع مشتری">
					<Option value="1">حقیقی</Option>
					<Option value="2">حقوقی</Option>
					<Option value="3">مشارکت مدنی</Option>
					<Option value="4">اتباع غیر ایرانی</Option>
					<Option value="5">مصرف کننده نهایی</Option>
				</Select>
			</Form.Item>
			<Form.Item
				hasFeedback
				name="fullname"
				rules={[
					{
						required: true,
						message: 'فیلد اجباری است',
					},
				]}
			>
				<Input size="large" placeholder="نام و نام خانوادگی / شرکت" />
			</Form.Item>

			<Form.Item
				hasFeedback
				name="cod_meli"
				rules={[
					{
						validator: async (_, cod_meli) => {
							if (!cod_meli && 10 > cod_meli.length) {
								return Promise.reject(
									new Error('مقدار این فیلد باید 10 رقم باشد')
								);
							}
						},
						message: 'مقدار این فیلد باید 10 رقم باشد',
					},
				]}
			>
				<Input size="large" placeholder="کد ملی" />
			</Form.Item>
			<Form.Item
				hasFeedback
				name="postal_code"
				rules={[
					{
						validator: async (_, postal_code) => {
							if (!postal_code || 10 > postal_code.length) {
								return Promise.reject(
									new Error('مقدار این فیلد باید 10 رقم باشد')
								);
							}
						},
						message: 'مقدار این فیلد باید 10 رقم باشد',
					},
				]}
			>
				<Input size="large" placeholder="کد پستی" />
			</Form.Item>
			<Form.Item
				hasFeedback
				name="cod_eqtesadi"
				rules={[
					{
						validator: async (_, cod_eqtesadi) => {
							if (
								!cod_eqtesadi ||
								10 > cod_eqtesadi.length ||
								cod_eqtesadi.length > 14
							) {
								return Promise.reject(
									new Error(
										'مقدار این فیلد باید 10 یا 14 رقم باشد'
									)
								);
							}
						},
						message: 'مقدار این فیلد باید 10 یا 14 رقم باشد',
					},
				]}
			>
				<Input size="large" placeholder="شماره اقتصادی" />
			</Form.Item>
		</Form>
	);
}

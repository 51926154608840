import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import settingsService from './Service'

const initialState = {
	settings: null,
	settingIsLoading: false,
	isSuccess: false
}

export const getSetting = createAsyncThunk('settings/getSetting', async (thunkAPI) => {
	try {
		return await settingsService.getSetting()
	} catch (error) {
		const message =
			(error.response && error.response.data && error.response.data.message) ||
			error.message ||
			error.toString()
		return thunkAPI.rejectWithValue(message)
	}
})

export const saveSetting = createAsyncThunk(
	'settings/saveSetting',
	async (setting, thunkAPI) => {
		try {
			return await settingsService.saveSetting(setting)
		} catch (error) {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString()
			return thunkAPI.rejectWithValue(message)
		}
	}
)

export const settingsSlice = createSlice({
	name: 'settings',
	initialState,
	reducers: {
		updateSetting: (state, action) => {
			state.settings = action.payload
		},
		allSettings: (state, action) => {
			state.settings = JSON.parse(action.payload.settings)
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getSetting.pending, (state) => {
				state.settingIsLoading = true
			})
			.addCase(getSetting.fulfilled, (state, action) => {
				state.settingIsLoading = false

				state.settings =
					action.payload.status === 200
						? JSON.parse(action.payload.response.settings)
						: null
			})
			.addCase(getSetting.rejected, (state, action) => {
				console.log(action.payload)
				state.settingIsLoading = false
			})
			.addCase(saveSetting.pending, (state) => {
				state.settingIsLoading = true
			})
			.addCase(saveSetting.fulfilled, (state, action) => {
				state.settingIsLoading = false
			})
			.addCase(saveSetting.rejected, (state, action) => {
				state.settingIsLoading = false
			})
	}
})

export const { updateSetting, allSettings } = settingsSlice.actions

export default settingsSlice.reducer

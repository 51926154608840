import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import allDataService from './Service'

const initialState = {
	allData: null,
	allDataIsLoading: false
}

export const getAllData = createAsyncThunk('allData/getAllData', async (thunkAPI) => {
	try {
		return await allDataService.getAllData()
	} catch (error) {
		const message =
			(error.response && error.response.data && error.response.data.message) ||
			error.message ||
			error.toString()
		return thunkAPI.rejectWithValue(message)
	}
})

export const allDataSlice = createSlice({
	name: 'allData',
	initialState,
	reducers: {
		setLoadingTrue: (state, action) => {
			state.allDataIsLoading = true
		},
		setLoadingFalse: (state, action) => {
			state.allDataIsLoading = false
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAllData.pending, (state) => {
				state.allDataIsLoading = true
			})
			.addCase(getAllData.fulfilled, (state, action) => {})
			.addCase(getAllData.rejected, (state, action) => {})
	}
})

export const { setLoadingTrue, setLoadingFalse } = allDataSlice.actions

export default allDataSlice.reducer

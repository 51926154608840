import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { persistor, store } from './app/store';
import { PersistGate } from 'redux-persist/integration/react';
import WebFont from 'webfontloader';
import { ConfigProvider, theme } from 'antd';
import { HashRouter } from 'react-router-dom';

const container = document.getElementById('root');
const root = createRoot(container);
WebFont.load({
	google: {
		families: [
			'Vazirmatn:100,200,300,400,500,600,700,800,900',
			'sans-serif',
		],
	},
});
root.render(
	<HashRouter>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ConfigProvider
					theme={{
						token: {
							fontFamily: 'Vazirmatn',
						},
						algorithm: window.matchMedia(
							'(prefers-color-scheme: dark)'
						).matches
							? theme.darkAlgorithm
							: theme.defaultAlgorithm,
					}}
				>
					<App />
				</ConfigProvider>
			</PersistGate>
		</Provider>
	</HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

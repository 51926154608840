import axios from 'axios'
import URL from '../config.json'

const restURL = URL.homeURL + URL.restURL
const API_URL = restURL + `${URL.billURL}`
const Bills_URL = restURL + `${URL.billsURL}`
const Inquiry_URL = restURL + `${URL.inquiryURL}`
const sendTwoStepFormCode_URL = restURL + `${URL.sendTwoStepFormCode}`

const sendBill = async (formData) => {
	console.log(formData)
	const response = await axios.post(API_URL, { data: formData })
	console.log(response.data)
	return response.data
}

const getBills = async (params) => {
	const response = await axios.get(Bills_URL, { params })
	return response.data.response
}

const getTotalBills = async (params) => {
	const response = await axios.post(Bills_URL, params)
	console.log(response.data)
	return response.data
}

const getInquiry = async (params) => {
	const response = await axios.get(Inquiry_URL, { params })
	console.log(response.data)
	return response.data
}
const getBill = async (params) => {
	console.log(params)
	const response = await axios.get(API_URL, { params })
	console.log(response.data)
	return response.data
}

const cancelBill = async (params) => {
	const response = await axios.delete(API_URL, { data: params })
	console.log(response.data)
	return response.data
}

const sendTwoStepFormCode = async (data) => {
	const response = await axios.post(sendTwoStepFormCode_URL, data)
	return response.data
}

const billsService = {
	sendBill,
	getBills,
	getInquiry,
	getTotalBills,
	cancelBill,
	getBill,
	sendTwoStepFormCode
}

export default billsService

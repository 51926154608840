import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import companiesService from './Service'

const initialState = {
	companyIsLoading: false,
	isSuccess: false,
	isOpen: false,
	isEditing: false,
	companyID: false,
	currentFormData: {},
	companies: [],
	licenses: [],
	message: []
}

export const getCompanies = createAsyncThunk(
	'companies/getCompanies',
	async (thunkAPI) => {
		try {
			return await companiesService.getCompanies()
		} catch (error) {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString()
			return thunkAPI.rejectWithValue(message)
		}
	}
)

export const getLicenses = createAsyncThunk(
	'companies/getLicenses',
	async (email, thunkAPI) => {
		try {
			return await companiesService.getLicenses(email)
		} catch (error) {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString()
			return thunkAPI.rejectWithValue(message)
		}
	}
)

export const saveCompany = createAsyncThunk(
	'companies/saveCompany',
	async (object, thunkAPI) => {
		try {
			return await companiesService.saveCompany(object)
		} catch (error) {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString()
			return thunkAPI.rejectWithValue(message)
		}
	}
)

export const deleteCompany = createAsyncThunk(
	'companies/deleteCompany',
	async (company_id, thunkAPI) => {
		try {
			return await companiesService.deleteCompany(company_id)
		} catch (error) {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString()
			return thunkAPI.rejectWithValue(message)
		}
	}
)

export const companiesSlice = createSlice({
	name: 'companies',
	initialState,
	reducers: {
		showDrawer: (state, action) => {
			const { open, edit, companyID } = action.payload
			state.isOpen = open
			state.isEditing = edit
			state.companyID = companyID
		},
		formData: (state, action) => {
			state.currentFormData = action.payload
		},
		addCompany: (state, action) => {
			state.companies = JSON.parse(action.payload)
		},
		removeCompany: (state, action) => {
			state.companies = Object.values(state.companies).filter(
				(company) => company.company_id !== action.payload
			)
		},
		allCompanies: (state, action) => {
			state.companies = JSON.parse(action.payload.companies)
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getCompanies.pending, (state) => {
				state.companyIsLoading = true
				state.isSuccess = false
			})
			.addCase(getCompanies.fulfilled, (state, action) => {
				state.companyIsLoading = false
				state.companies =
					action.payload.status === 200
						? JSON.parse(action.payload.response.companies)
						: []
				state.message = action.payload
				state.isSuccess = true
			})
			.addCase(getCompanies.rejected, (state, action) => {
				state.companyIsLoading = false
				state.isError = true
			})
			.addCase(getLicenses.pending, (state) => {
				state.companyIsLoading = true
				state.isSuccess = false
			})
			.addCase(getLicenses.fulfilled, (state, action) => {
				state.companyIsLoading = false
				state.licenses =
					action.payload.status === 200
						? JSON.parse(action.payload.response.licenses)
						: []
				state.message = action.payload
				state.isSuccess = true
			})
			.addCase(getLicenses.rejected, (state, action) => {
				state.companyIsLoading = false
				state.isError = true
			})
			.addCase(saveCompany.pending, (state) => {
				state.companyIsLoading = true
				state.isSuccess = false
			})
			.addCase(saveCompany.fulfilled, (state, action) => {
				state.companyIsLoading = false
				state.message = action.payload
				state.isSuccess = true
			})
			.addCase(saveCompany.rejected, (state, action) => {
				state.companyIsLoading = false
				state.isError = true
			})
			.addCase(deleteCompany.pending, (state) => {
				state.companyIsLoading = true
				state.isSuccess = false
			})
			.addCase(deleteCompany.fulfilled, (state, action) => {
				state.companyIsLoading = false
				state.message = action.payload
				state.isSuccess = true
			})
			.addCase(deleteCompany.rejected, (state, action) => {
				state.companyIsLoading = false
				state.isError = true
			})
	}
})

export const {
	showDrawer,
	formData,
	addCompany,
	removeCompany,
	editCompany,
	allCompanies
} = companiesSlice.actions

export default companiesSlice.reducer

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import userLicenses from './Services'

const initialState = {
	licenses: [],
	isLoading: false,
	isSuccess: false
}

export const getUserLicenses = createAsyncThunk(
	'licenses/getUserLicenses',
	async (data, thunkAPI) => {
		try {
			return await userLicenses.getUserLicenses(data)
		} catch (error) {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString()
			return thunkAPI.rejectWithValue(message)
		}
	}
)

export const licensesSlice = createSlice({
	name: 'licenses',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getUserLicenses.pending, (state) => {
				state.isLoading = true
				state.isSuccess = false
			})
			.addCase(getUserLicenses.fulfilled, (state, action) => {
				state.isLoading = false
				state.licenses =
					action.payload.status === 200
						? JSON.parse(action.payload.response.licenses)
						: []
				state.isSuccess = true
			})
			.addCase(getUserLicenses.rejected, (state, action) => {
				state.isLoading = false
				state.isError = true
			})
	}
})

export default licensesSlice.reducer

import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Layout from './features/Layout/Layout'
import DashboardLayout from './features/DashboardLayout/DashboardLayout'
import Login from './pages/Login/Login'
import Dashboard from './pages/Dashboard/Dashboard'
import Companies from './pages/Companies/Companies'
import Customers from './pages/Customers/Customers'
import Products from './pages/Products/Products'
import Users from './pages/Users/Users'
import axios from 'axios'
import Licenses from './pages/Licenses/Licenses'
import Payments from './pages/Payments/Payments'
import SendWithForm from './pages/SendWithForm/SendWithForm'
import Sent from './pages/Sent/Sent'
import SentSandbox from './pages/SentSandbox/SentSandbox'
import Settings from './pages/Settings/Settings'
import Single from './pages/Single/Single'

function App() {
	const { user, token } = useSelector((state) => state.user)
	// Set token for all request
	if (token) {
		axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
	}
	axios.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8'

	return (
		<Layout>
			{user && token ? (
				<DashboardLayout>
					<Routes basename={'/panel'}>
						<Route exact path='/' element={<Dashboard />} />
						<Route path='/companies' element={<Companies />} />
						<Route path='/products' element={<Products />} />
						<Route path='/users' element={<Users />} />
						<Route path='/customers' element={<Customers />} />
						<Route path='/payments' element={<Payments />} />
						<Route path='/licenses' element={<Licenses />} />
						<Route path='/sendWithForm' element={<SendWithForm />} />
						<Route path='/sent' element={<Sent />} />
						<Route path='/sentSandbox' element={<SentSandbox />} />
						<Route path='/settings' element={<Settings />} />
						<Route path='/single' element={<Single />} />
					</Routes>
				</DashboardLayout>
			) : (
				<Login />
			)}
		</Layout>
	)
}

export default App
